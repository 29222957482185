var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-edition-navigation" },
    [
      _c("Section", { attrs: { spacing: "equidistant" } }, [
        _c("div", { staticClass: "wrapper" }, [
          _vm.previous_edition
            ? _c(
                "div",
                { staticClass: "edition" },
                [
                  _c(
                    "b-clickable",
                    {
                      ref: "previous",
                      attrs: {
                        "on-click": _vm.onClick,
                        href: _vm.previous_edition.url,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "edition-slide" },
                        [
                          _c(
                            "div",
                            { staticClass: "arrow" },
                            [
                              _c("ArrowButton", {
                                attrs: {
                                  color: "black",
                                  "arrow-direction": "flip",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("Definition", {
                            attrs: {
                              filled: "",
                              "text-size": "bc2",
                              title: _vm.previous_edition.year,
                              description: _vm.previous_edition.title,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.next_edition
            ? _c(
                "div",
                { staticClass: "edition" },
                [
                  _c(
                    "b-clickable",
                    {
                      ref: "next",
                      attrs: {
                        "on-click": _vm.onClick,
                        href: _vm.next_edition.url,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "edition-slide" },
                        [
                          _c(
                            "div",
                            { staticClass: "arrow" },
                            [_c("ArrowButton", { attrs: { color: "black" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c("Definition", {
                            attrs: {
                              filled: "",
                              "text-size": "bc2",
                              layout: "mobile-reversed",
                              title: _vm.next_edition.year,
                              description: _vm.next_edition.title,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }