import Section from '../../components/containers/section';
import PostCover from '../../components/covers/post-cover';
import ArrowMobileIcon from './assets/arrow-mobile-embedded.svg';
import ArrowDesktopIcon from './assets/arrow-desktop-embedded.svg';
import dateFormat from '../../mixins/date-format';

export default {
  mixins: [dateFormat],
  components: {
    PostCover,
    Section,
    ArrowMobileIcon,
    ArrowDesktopIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
    }
  },
  mounted() {
    this.$refs.arrow.onClick = this.onClick;
  }
};
