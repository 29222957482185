import ArrowDown from './assets/arrowthin-embedded.svg';

export default {
  components: {
    ArrowDown,
  },
  props: {
    icon: {
      type: String,
    },
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
