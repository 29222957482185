var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "c-loading-cover" }, [
      _c("div", { staticClass: "content" }, [
        _c("span", { staticClass: "image" }),
        _vm._v(" "),
        _c("span", { staticClass: "title" }),
        _vm._v(" "),
        _c("span", { staticClass: "date" }),
        _vm._v(" "),
        _c("span", { staticClass: "description" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }