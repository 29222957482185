import CloseIcon from './assets/close-embedded.svg';
import CloseIconMobile from './assets/close-mobile-embedded.svg';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
  components: {
    CloseIcon,
    CloseIconMobile,
  },
  props: {
    size: {
      type: Object,
      default: () => ({
        xxs: 12,
      }),
    },
    wrapperType: {
      type: String,
    },
    windowSize: {
      type: String,
      default: 'fullscreen',
    },
    show: {
      type: Boolean,
      default: true,
    },
    closeLabel: {
      type: String,
      required: true,
    },
    disableScroll: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.close.onClick = this.onClose;
    if (this.show) this.onOpenModal();
  },
  computed: {
    hasContent() {
      return !!this.$slots.content;
    },
    hasPillarHeading() {
      return !!this.$slots['pillar-heading'];
    },
    hasPillarList() {
      return !!this.$slots['pillars-list'];
    },
    hasTypesHeading() {
      return !!this.$slots['types-heading'];
    },
    hasTypesList() {
      return !!this.$slots['types-list'];
    },
    hasSeasonList() {
      return !!this.$slots['season-list'];
    },
    hasSeasonHeading() {
      return !!this.$slots['season-heading'];
    },
    hasFirstCTA() {
      return !!this.$slots['first-cta'];
    },
    hasSecondCTA() {
      return !!this.$slots['second-cta'];
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.onOpenModal();
      } else {
        this.onCloseModal();
      }
    }
  },

  methods: {
    onOpenModal() {
      if (typeof window !== 'undefined') {
        if (this.disableScroll) disableBodyScroll(this.$refs.scrollable);
        document.addEventListener('focusin', this.focusTrap);
      }
    },

    onCloseModal() {
      if (typeof window !== 'undefined') {
        if (this.disableScroll) enableBodyScroll(this.$refs.scrollable);
        document.removeEventListener('focusin', this.focusTrap);
      }
    },

    focusTrap(e) {
      if (!this.$el.contains(e.target)) {
        this.$refs.close.shadowRoot.querySelector('button').focus();
      }
    },
  },

  destroyed() {
    this.onCloseModal();
  }
};
