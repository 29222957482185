export default {
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
    }
  },
};
