import StatePage from './state-manager';
import Loader from '../../progress/loader';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
  components: {
    Loader,
  },
  props: {
    isNavVisible: {
      type: Boolean,
      default: false,
    },
    isHeaderVisible: {
      type: Boolean,
      default: true,
    },
    isHeaderHovered: {
      type: Boolean,
      default: false,
    },
    isScrolled: {
      type: Boolean,
      default: false,
    },
    isDropdownActive: {
      type: Boolean,
      default: false,
    },
    toggleNav: {
      type: Function,
      default: () => { },
    },
    toggleHeader: {
      type: Function,
      default: () => { },
    },
    lightHeader: {
      type: Boolean,
      default: false,
    },
    mouseOverHeader: {
      type: Function,
      default: () => { },
    },
    loading: {
      type: Boolean,
    },
    loaderLabel: {
      type: String,
    },
    skipToContentLabel: {
      type: String,
    }
  },
  data() {
    return {
      width: 0,
    }
  },
  created() {
    if (typeof window !== "undefined") {
      this.width = window.innerWidth
      window.addEventListener("resize", this.resizeHandler)
    }
  },
  computed: {
    hasHero() {
      return !!this.$slots.hero;
    },
    hasDate() {
      return !!this.$slots.date;
    },
    needsLightHeader() {
      return this.lightHeader;
    },
    navBackground() {
      if (this.isNavVisible && this.width < 1020) {
        return 'dark'
      }

      if (!this.needsLightHeader && !this.isScrolled && !this.isDropdownActive) {
        return null
      }
    },
    navLabel() {
      return this.isNavVisible ? 'Close menu' : 'Open menu'
    }
  },
  methods: {
    headerHandler() {
      const { top } = document.body.getBoundingClientRect();
      const { pageYOffset } = window;

      this.toggleHeader(top, pageYOffset);
    },
    resizeHandler({ target }) {
      this.width = target.innerWidth
    }
  },
  mounted() {
    window.addEventListener('scroll', this.headerHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.headerHandler);
    window.removeEventListener("resize", this.resizeHandler)
  },
  watch: {
    isNavVisible() {
      if (this.isNavVisible) {
        disableBodyScroll(this.$refs.nav);
      } else {
        enableBodyScroll(this.$refs.nav);
      }
    }
  }
};

export { StatePage };
