<template>
  <Error
    :message="error.page_not_found.title"
    :subtitle="error.page_not_found.subtitle"
  />
</template>
<script>
import { Error } from '@yoc/ui'
export default {
  name: 'error-404',
  components: {
    Error,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
