var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-hamburger" }, [
    _vm.closeCTA
      ? _c("span", { staticClass: "close-cta" }, [
          _vm._v("\n    " + _vm._s(_vm.closeCTA) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "label" }, [
      _vm._v("\n    " + _vm._s(_vm.hiddenLabel) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "lines" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }