import Vue from 'vue'
import VueGtag from "vue-gtag";

export default async (ctx, inject) => {
  const { app, $areCookiesAccepted } = ctx
  const cookieConsent = $areCookiesAccepted()
  // render only on client side
  if (typeof window === 'undefined') {
    return
  }
  // render only when cookie consent is given
  if (!cookieConsent) {
    return
  }

  const settings = app.store.getters['settings/settingsByLocale']('en')

  // render only when id is set
  if (!settings?.google_analytics_id) {
    return
  }

  const { google_analytics_id } = settings

  if (google_analytics_id === '') {
    return
  }

  const options = {
    id: google_analytics_id,
  }

  Vue.use(VueGtag, {
    appName: 'YoC Website',
    pageTrackerScreenviewEnabled: true,
    config: options
  }, 
  app.router)
}