import Section from '../../components/containers/section';
import CardCover from '../../components/covers/card-cover';

export default {
  components: {
    Section,
    CardCover,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    items: {
      type: Array,
      required: true
    },
    onClick: {
      type: Function,
    },
    cellsPerRow: {
      type: Number,
      default: 3,
    }
  },
  computed: {
    cellSize() {
      return {
        xxs: 12,
        md: 6,
        lg: 12 / this.cellsPerRow,
      }
    }
  }
};
