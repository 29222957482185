var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-text-slider" },
    [
      _c(
        "Section",
        {
          attrs: {
            pretitle: _vm.pretitle,
            "wrapper-type": "full",
            "with-border-top": "",
            spacing: "lg",
          },
        },
        [
          _vm.description
            ? _c("b-wrapper", [
                _c("p", { staticClass: "description" }, [
                  _vm._v("\n        " + _vm._s(_vm.description) + "\n      "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-slider",
            {
              ref: "slider",
              attrs: {
                "cell-align": "center",
                "free-scroll": "false",
                contain: "false",
                infinite: "true",
                arrows: "",
                "arrows-color": "black",
                "right-to-left": _vm.rightToLeft,
              },
            },
            _vm._l(_vm.slides, function (slide, idx) {
              return _c(
                "b-cell",
                _vm._b(
                  { key: `${slide.title}-${idx}` },
                  "b-cell",
                  _vm.bpOptions(slide.show_flag),
                  false
                ),
                [
                  _c("DefinitionSlide", {
                    attrs: {
                      title: slide.title,
                      description: slide.description,
                      "show-flag": slide.show_flag,
                      filled: idx === _vm.activeIndex,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }