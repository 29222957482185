export default {
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    image: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    },
    href: {
      type: String,
      default: null,
    },
    caption: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
    this.$refs.image.onClick = this.onClick;
  },
};
