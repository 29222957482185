import StateToggleFilters from './state-manager';
import ExpandIcon from './assets/expand-icon-embedded.svg';

export default {
  components: {
    ExpandIcon,
  },
  props: {
    showFilters: {
      type: Boolean,
      default: false,
    },
    hideFiltersText: {
      type: String,
      default: 'Hide filters',
    },
    showFiltersText: {
      type: String,
      default: 'Filters',
    },
    onToggleClick: {
      type: Function,
      default: () => {},
    },
    filterCount: {
      type: Number,
    },
  },
  computed: {
    filtersButtonText() {
      return this.showFilters ? this.hideFiltersText : this.showFiltersText;
    },
  },
}

export { StateToggleFilters }
