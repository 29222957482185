var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-download" },
    [
      _c("b-clickable", { ref: "clickable", attrs: { href: _vm.href } }, [
        _c("span", { staticClass: "download-wrapper" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v("\n        " + _vm._s(_vm.label) + "\n      "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "info" }, [
            _vm.details
              ? _c("span", { staticClass: "details" }, [
                  _vm._v("\n          " + _vm._s(_vm.details) + "\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "icon" }, [_c("ArrowIcon")], 1),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }