import AF from './assets/af-embedded.svg';
import AE from './assets/ae-embedded.svg';
import BD from './assets/bd-embedded.svg';
import BH from './assets/bh-embedded.svg';
import BT from './assets/bt-embedded.svg';
import DZ from './assets/dz-embedded.svg';
import EA from './assets/ae-embedded.svg';
import EG from './assets/eg-embedded.svg';
import IN from './assets/in-embedded.svg';
import IQ from './assets/iq-embedded.svg';
import IR from './assets/ir-embedded.svg';
import JO from './assets/jo-embedded.svg';
import KW from './assets/kw-embedded.svg';
import LB from './assets/lb-embedded.svg';
import LK from './assets/lk-embedded.svg';
import LY from './assets/ly-embedded.svg';
import MA from './assets/ma-embedded.svg';
import MV from './assets/mv-embedded.svg';
import NP from './assets/np-embedded.svg';
import OM from './assets/om-embedded.svg';
import PK from './assets/pk-embedded.svg';
import PSE from './assets/pse-embedded.svg';
import SA from './assets/sa-embedded.svg';
import SD from './assets/sd-embedded.svg';
import TN from './assets/tn-embedded.svg';
import TR from './assets/tr-embedded.svg';
import YE from './assets/ye-embedded.svg';



export default {
  components: {
    AF,
    AE,
    BD,
    BH,
    BT,
    DZ,
    EA,
    EG,
    IN,
    IQ,
    IR,
    JO,
    KW,
    LB,
    LK,
    LY,
    MA,
    MV,
    NP,
    OM,
    PK,
    PSE,
    SA,
    SD,
    TN,
    TR,
    YE,
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    filled: {
      type: Boolean,
    },
    showFlag: {
      type: Boolean,
    },
    textSize: {
      type: String,
    },
    layout: {
      type: String,
    },
  },
  methods: {
    getFlagIcon(description) {
      if (description) {
        if (description === 'af') {
          return AF
        }
        if (description === 'dz') {
          return DZ
        }
        if (description === 'bd') {
          return BD
        }
        if (description === 'bh') {
          return BH
        }
        if (description === 'bt') {
          return BT
        }
        if (description === 'eg') {
          return EG
        }
        if (description === 'in') {
          return IN
        }
        if (description === 'iq') {
          return IQ
        }
        if (description === 'ir') {
          return IR
        }
        if (description === 'jo') {
          return JO
        }
        if (description === 'kw') {
          return KW
        }
        if (description === 'lb') {
          return LB
        }
        if (description === 'ly') {
          return LY
        }
        if (description === 'mv') {
          return MV
        }
        if (description === 'ma') {
          return MA
        }
        if (description === 'np') {
          return NP
        }
        if (description === 'om') {
          return OM
        }
        if (description === 'pk') {
          return PK
        }
        if (description === 'pse') {
          return PSE
        }
        if (description === 'sa') {
          return SA
        }
        if (description === 'sd') {
          return SD
        }
        if (description === 'lk') {
          return LK
        }
        if (description === 'tr') {
          return TR
        }
        if (description === 'tn') {
          return TN
        }
        if (description === 'ae') {
          return AE
        }
        if (description === 'ye') {
          return YE
        }
      }
    }
  }
};
