export default {
  props: {
    name: {
      type: String,
      required: true
    },
    occupation: {
      type: String,
    },
    label: {
      type: String,
    },
    email: {
      type: String,
    },
    src: {
      type: String,
    },
    alt: {
      type: String,
    },
    onClick: {
      type: Function,
    }
  },
  mounted() {
    if (this.$refs.clickable) this.$refs.clickable.onClick = this.onClick;
    if (this.$refs.image) this.$refs.image.onClick = this.onClick;
  },
};
