var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-input-text",
      attrs: { "data-is-error": _vm.error || !!_vm.errorMessage },
    },
    [
      _c("label", { staticClass: "label", attrs: { for: _vm.id } }, [
        _c("span", { staticClass: "text" }, [
          _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
        ]),
        _vm._v(" "),
        _vm.errorMessage
          ? _c("span", { staticClass: "error-message" }, [
              _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "input",
        attrs: { type: _vm.type, id: _vm.id, placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: { change: (e) => _vm.onChange && _vm.onChange(e.target.value, e) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }