export default {
  data() {
    return {
      observer: null,
      inViewport: false,
      revealed: true,
    };
  },

  props: {
    threshold: {
      type: Array,
    },
    root: {
      type: typeof HTMLElement !== 'undefined' ? HTMLElement : Object,
    },
    rootMargin: {
      type: String,
      default: () => '0px 0px -20% 0px',
    },
    onEnter: {
      type: Function,
    },
    onLeave: {
      type: Function,
    },
    onReveal: {
      type: Function,
    },
    useRevealAttributes: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasUniqueSlot() {
      return this.$slots.default
        && this.$slots.default.length === 1
        && this.$slots.default[0].elm
        && this.$slots.default[0].elm instanceof HTMLElement;
    }
  },

  methods: {
    onIntersection(entries) {
      if (!entries[0].isIntersecting) {
        this.onIntersectionLeave([entries[0]]);
      } else {
        this.onIntersectionEnter([entries[0]]);
      }
    },

    onIntersectionReveal(e) {
      this.revealed = true;

      if (this.onReveal) {
        this.onReveal(e);
      }
    },

    onIntersectionEnter(e) {
      this.inViewport = true;

      if (!this.revealed) {
        this.onIntersectionReveal(e);
      }

      if (this.onEnter) {
        this.onEnter(e);
      }
    },

    onIntersectionLeave(e) {
      this.inViewport = false;

      if (this.onLeave) {
        this.onLeave(e);
      }
    }
  },
  mounted() {
    // is client and exist IO
    if (typeof window !== 'undefined' && window.IntersectionObserver) {
      this.observer = new IntersectionObserver(this.onIntersection, {
        threshold: this.threshold,
        root: this.root,
        rootMargin: this.rootMargin
      });

      if (this.hasUniqueSlot) {
        this.observer.observe(this.$slots.default[0].elm);
      }

      // init revealed state
      this.revealed = false;
    }
  },

  destroyed() {
    // if client and exists IO and is defined
    if (typeof window !== 'undefined' && window.IntersectionObserver && this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  },

  render() {
    return this.$slots.default ? this.$slots.default[0] : null;
  },
};
