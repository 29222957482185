import LogoIconMD from './assets/logo-md-embedded.svg';
import LogoIconMDMobile from './assets/logo-md-mobile-embedded.svg';
import LogoIconLG from './assets/logo-lg-embedded.svg';
import LogoIconLGMobile from './assets/logo-lg-mobile-embedded.svg';

import LogoIconMDRTL from './assets/logo-md-rtl-embedded.svg';
import LogoIconMDMobileRTL from './assets/logo-md-rtl-mobile-embedded.svg';
import LogoIconLGRTL from './assets/logo-lg-rtl-embedded.svg';
import LogoIconLGMobileRTL from './assets/logo-lg-rtl-mobile-embedded.svg';


// mapped logos to this structure.
const icons = [
  {
    size: 'md',
    icons: {
      mobile: {
        ltr: LogoIconMDMobile,
        rtl: LogoIconMDMobileRTL
      },
      desktop: {
        ltr: LogoIconMD,
        rtl: LogoIconMDRTL
      },
    }
  },
  {
    size: 'lg',
    icons: {
      mobile: {
        ltr: LogoIconLGMobile,
        rtl: LogoIconLGMobileRTL
      },
      desktop: {
        ltr: LogoIconLG,
        rtl: LogoIconLGRTL
      },
    }
  }
];

export default {
  props: {
    size: {
      type: String,
      required: true
    },
    highlight: {
      type: String,
    },
    highlightWhenNarrow: {
      type: Boolean,
    }
  },
  components: {
    LogoIconMD,
    LogoIconMDMobile,
    LogoIconLG,
    LogoIconLGMobile
  },
  methods: {
    isIcon(breakpoint, direction) {
      const currentIconSize = icons.find(icon => icon.size === this.size);

      return currentIconSize?.icons[breakpoint][direction] || LogoIconMD;
    }
  }
};
