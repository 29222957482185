var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-pagination", attrs: { "data-condensed": _vm.condensed } },
    [
      _c("div", [
        _c(
          "ul",
          { staticClass: "buttons" },
          [
            _c(
              "li",
              {
                staticClass: "button prev",
                attrs: {
                  "aria-label": "Previous",
                  "data-arrow-active": !_vm.isAtFirst,
                  "aria-hidden": _vm.isAtFirst,
                  focusable: !_vm.isAtFirst,
                },
              },
              [
                _c(
                  "b-clickable",
                  {
                    ref: "clickablePrev",
                    attrs: {
                      href: _vm.href,
                      "on-click": _vm.onPrevClick,
                      type: "button",
                      disabled: _vm.isAtFirst,
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "container" },
                      [
                        _c("span", { staticClass: "hidden-label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.ariaPrev) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("ArrowIcon", {
                          staticClass: "arrow",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            !!_vm.condensed
              ? [
                  _c("li", { staticClass: "condensed" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.condensedContent) +
                        "\n        "
                    ),
                  ]),
                ]
              : _vm._l(_vm.pages, function (page, index) {
                  return _c("li", { key: index, staticClass: "button" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.href,
                          type: "button",
                          tabindex: "0",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return (() => _vm.onClick(index + 1)).apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "container",
                            attrs: { "data-active": _vm.isActive(page) },
                          },
                          [_c("span", [_vm._v(_vm._s(page))])]
                        ),
                      ]
                    ),
                  ])
                }),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "button next",
                attrs: {
                  "aria-label": "Next",
                  "data-arrow-active": !_vm.isAtLast,
                  "aria-hidden": _vm.isAtLast,
                  focusable: !_vm.isAtLast,
                },
              },
              [
                _c(
                  "b-clickable",
                  {
                    ref: "clickableNext",
                    attrs: {
                      href: _vm.href,
                      "on-click": _vm.onNextClick,
                      disabled: _vm.isAtLast,
                      type: "button",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "container" },
                      [
                        _c("span", { staticClass: "hidden-label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.ariaNext) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("ArrowIcon", {
                          staticClass: "arrow",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }