export const actions = {
  async nuxtServerInit({ commit }) {
    const { locales } = this.$i18n

    const data = await Promise.all(
      locales.map(async ({ code, iso }) => {
        let settings
        try {
          settings = await this.$strapi.find('settings', { _locale: code })
        } catch (error) {
          console.log(`Was not able to load settings for ${code}`)
          settings = false
        }

        return settings
      })
    )

    commit('settings/update', data)
  },
}
