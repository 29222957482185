<template>
  <StatePage>
    <template
      v-slot="{
        isHeaderVisible,
        isScrolled,
        toggleHeader,
        isHeaderHovered,
        mouseOverHeader,
      }"
    >
      <Page
      :is-header-visible="isHeaderVisible"
      :is-nav-visible="isNavVisible"
      :is-scrolled="isScrolled"
      :toggle-nav="toggleNav"
      :toggle-header="toggleHeader"
      :is-header-hovered="isHeaderHovered"
      :mouse-over-header="mouseOverHeader"
      :light-header="!isHome() || dropdown"
      :loading="isLoading"
      :loader-label="label"
      :skip-to-content-label="skipLabel"
      >
        <template #marquee>
          <Marquee :items="marqueeItems" />
        </template>
        <template #brand>
          <nuxt-link to="/home">
            <Logo
              size="md"
              :highlight="color"
              :highlight-when-narrow="isHome()"
            >
              Years of Culture
            </Logo>
          </nuxt-link>
        </template>
        <template #nav>
          <Menu :on-click="onMenuClick" :links="settings.top_navigation" :see-all="settings.see_all" @dropdown="isDropdownActive" />
        </template>
        <template #lang>
          <nuxt-link
            :to="switchLocalePath(locale.code)"
            v-for="(locale, index) in locales"
            :key="index"
          >
            <LangSwitch>
              {{ locale.name }}
            </LangSwitch>
          </nuxt-link>
        </template>
        <template #hamburger>
          <Hamburger />
        </template>
        <div>
          <Nuxt />
        </div>
        <template #footer>
          <Footer
            :copyright="settings.copyright"
            :logo-label="settings.logo_label"
            :social-buttons="settings.social_icons"
            :links="settings.footer_navigation"
            :on-click="onMenuClick"
          />
        </template>
        <template #cookies v-if="shouldDisplayCookies">
          <Cookies :title="settings.cookies.message">
            <template #cta>
              <Button :on-click="onCookiesAccepted">
                {{ settings.cookies.button_label }}
              </Button>
            </template>
          </Cookies>
        </template>
      </Page>
    </template>
  </StatePage>
</template>

<script>
import { mapGetters } from 'vuex'
import helpers from '@/helpers'
import Page, { StatePage } from '@yoc/ui/src/components/layout/page'
import Menu from '@yoc/ui/src/components/nav/menu'
import Time, { StateTime } from '@yoc/ui/src/components/info/time'
import Logo from '@yoc/ui/src/components/brand/logo'
import Cookies from '@yoc/ui/src/components/info/cookies'
import Footer from '@yoc/ui/src/modules/footer'
import Hamburger from '@yoc/ui/src/components/nav/hamburger'
import LangSwitch from '@yoc/ui/src/components/cta/lang-switch'
import Button from '@yoc/ui/src/components/cta/button'
import Marquee from '@yoc/ui/src/modules/marquee'

export default {
  components: {
    StatePage,
    Page,
    Menu,
    Time,
    StateTime,
    Logo,
    Footer,
    Cookies,
    Hamburger,
    LangSwitch,
    Button,
    Marquee
  },
  data() {
    return {
      shouldDisplayCookies: !this.$areCookiesAccepted(),
      isNavVisible: false,
      dropdown: false,
      isLoading: true,
      width: 0,
    }
  },
  mounted() {
    this.isLoading = false
  },
  computed: {
    ...mapGetters({
      settingsByLocale: 'settings/settingsByLocale',
      getMarqueeItems: 'settings/getMarqueeItems'
    }),
    settings() {
      const { locale } = this.$i18n
      const settings = this.settingsByLocale(locale)
      return settings
    },
    marqueeItems() {
      const { locale } = this.$i18n
      return this.getMarqueeItems(locale)
    },
    locales() {
      const { locales, locale } = this.$i18n
      return locales.filter((item) => item.code !== locale)
    },
    color() {
      const { locale } = this.$i18n
      return helpers.isHome(this.$route, locale) ? '' : 'dusk'
    },
    label: function() {
      return this.settings?.loading_text
    },
    skipLabel: function() {
      return this.settings?.accessibility?.skip_content_label
    },
  },
  methods: {
    onCookiesAccepted() {
      this.$onAcceptCookies()
    },
    onMenuClick(href) {
      if (href.indexOf('http') >= 0) {
        window.open(href, '_blank')
      } else {
        this.$router.push(href)
      }
    },
    isHome() {
      const { locale } = this.$i18n
      return helpers.isHome(this.$route, locale)
    },
    isDropdownActive(event) {
      this.dropdown = event
    },
    toggleNav() {
      this.isNavVisible = !this.isNavVisible
    },
  },
  watch: {
    $route() {
      this.isNavVisible = false
    },
  }
}
</script>
