export default {
  props: {
    closeCTA: {
      type: String,
      default: 'close',
    },
    hiddenLabel: {
      type: String,
      default: 'Menu'
    }
  },
};
