export { default as ContainersIntersection } from '../../../ui/src/containers/intersection/index.vue'
export { default as ModulesAboutUs } from '../../../ui/src/modules/about-us/index.vue'
export { default as ModulesAgenda } from '../../../ui/src/modules/agenda/index.vue'
export { default as ModulesChallenges } from '../../../ui/src/modules/challenges/index.vue'
export { default as ModulesColumns } from '../../../ui/src/modules/columns/index.vue'
export { default as ModulesContactGrid } from '../../../ui/src/modules/contact-grid/index.vue'
export { default as ModulesEditionNavigation } from '../../../ui/src/modules/edition-navigation/index.vue'
export { default as ModulesEditionSelector } from '../../../ui/src/modules/edition-selector/index.vue'
export { default as ModulesEditionsList } from '../../../ui/src/modules/editions-list/index.vue'
export { default as ModulesError } from '../../../ui/src/modules/error/index.vue'
export { default as ModulesEventDetails } from '../../../ui/src/modules/event-details/index.vue'
export { default as ModulesEventStats } from '../../../ui/src/modules/event-stats/index.vue'
export { default as ModulesEventsSlider } from '../../../ui/src/modules/events-slider/index.vue'
export { default as ModulesFeaturedEvent } from '../../../ui/src/modules/featured-event/index.vue'
export { default as ModulesFeaturedItems } from '../../../ui/src/modules/featured-items/index.vue'
export { default as ModulesFeaturedPost } from '../../../ui/src/modules/featured-post/index.vue'
export { default as ModulesFilteredEvents } from '../../../ui/src/modules/filtered-events/index.vue'
export { default as ModulesFooter } from '../../../ui/src/modules/footer/index.vue'
export { default as ModulesHooksMenu } from '../../../ui/src/modules/hooks-menu/index.vue'
export { default as ModulesImageGrid } from '../../../ui/src/modules/image-grid/index.vue'
export { default as ModulesImageGridModal } from '../../../ui/src/modules/image-grid-modal/index.vue'
export { default as ModulesImagesSlider } from '../../../ui/src/modules/images-slider/index.vue'
export { default as ModulesItemDetails } from '../../../ui/src/modules/item-details/index.vue'
export { default as ModulesLongText } from '../../../ui/src/modules/long-text/index.vue'
export { default as ModulesMainHero } from '../../../ui/src/modules/main-hero/index.vue'
export { default as ModulesMarquee } from '../../../ui/src/modules/marquee/index.vue'
export { default as ModulesNewsGrid } from '../../../ui/src/modules/news-grid/index.vue'
export { default as ModulesOngoingEvents } from '../../../ui/src/modules/ongoing-events/index.vue'
export { default as ModulesOrganizations } from '../../../ui/src/modules/organizations/index.vue'
export { default as ModulesPageHero } from '../../../ui/src/modules/page-hero/index.vue'
export { default as ModulesPartners } from '../../../ui/src/modules/partners/index.vue'
export { default as ModulesPillarsList } from '../../../ui/src/modules/pillars-list/index.vue'
export { default as ModulesPostContent } from '../../../ui/src/modules/post-content/index.vue'
export { default as ModulesPostHero } from '../../../ui/src/modules/post-hero/index.vue'
export { default as ModulesPostsMosaic } from '../../../ui/src/modules/posts-mosaic/index.vue'
export { default as ModulesPostsSlider } from '../../../ui/src/modules/posts-slider/index.vue'
export { default as ModulesPromo } from '../../../ui/src/modules/promo/index.vue'
export { default as ModulesResourcesList } from '../../../ui/src/modules/resources-list/index.vue'
export { default as ModulesRichText } from '../../../ui/src/modules/rich-text/index.vue'
export { default as ModulesStoriesGrid } from '../../../ui/src/modules/stories-grid/index.vue'
export { default as ModulesTenAnniversary } from '../../../ui/src/modules/ten-anniversary/index.vue'
export { default as ModulesTextColumns } from '../../../ui/src/modules/text-columns/index.vue'
export { default as ModulesTextSlider } from '../../../ui/src/modules/text-slider/index.vue'
export { default as ModulesVideoGrid } from '../../../ui/src/modules/video-grid/index.vue'
export { default as ComponentsArticleRichText } from '../../../ui/src/components/article/rich-text/index.vue'
export { default as ComponentsBrandLogo } from '../../../ui/src/components/brand/logo/index.vue'
export { default as ComponentsContainersModal } from '../../../ui/src/components/containers/modal/index.vue'
export { default as ComponentsContainersSection } from '../../../ui/src/components/containers/section/index.vue'
export { default as ComponentsContainersStickyElement } from '../../../ui/src/components/containers/sticky-element/index.vue'
export { default as ComponentsCoversAgendaCover } from '../../../ui/src/components/covers/agenda-cover/index.vue'
export { default as ComponentsCoversCardCover } from '../../../ui/src/components/covers/card-cover/index.vue'
export { default as ComponentsCoversChallengeCover } from '../../../ui/src/components/covers/challenge-cover/index.vue'
export { default as ComponentsCoversContactCover } from '../../../ui/src/components/covers/contact-cover/index.vue'
export { default as ComponentsCoversEventCardCover } from '../../../ui/src/components/covers/event-card-cover/index.vue'
export { default as ComponentsCoversLoadingCover } from '../../../ui/src/components/covers/loading-cover/index.vue'
export { default as ComponentsCoversPostCover } from '../../../ui/src/components/covers/post-cover/index.vue'
export { default as ComponentsCoversSponsorCover } from '../../../ui/src/components/covers/sponsor-cover/index.vue'
export { default as ComponentsCtaArrowButton } from '../../../ui/src/components/cta/arrow-button/index.vue'
export { default as ComponentsCtaButton } from '../../../ui/src/components/cta/button/index.vue'
export { default as ComponentsCtaDownload } from '../../../ui/src/components/cta/download/index.vue'
export { default as ComponentsCtaEventAction } from '../../../ui/src/components/cta/event-action/index.vue'
export { default as ComponentsCtaIconButton } from '../../../ui/src/components/cta/icon-button/index.vue'
export { default as ComponentsCtaIconMessage } from '../../../ui/src/components/cta/icon-message/index.vue'
export { default as ComponentsCtaLangSwitch } from '../../../ui/src/components/cta/lang-switch/index.vue'
export { default as ComponentsCtaRoundedButton } from '../../../ui/src/components/cta/rounded-button/index.vue'
export { default as ComponentsCtaSocialButton } from '../../../ui/src/components/cta/social-button/index.vue'
export { default as ComponentsFieldsInputCheckbox } from '../../../ui/src/components/fields/input-checkbox/index.vue'
export { default as ComponentsFieldsInputDropdown } from '../../../ui/src/components/fields/input-dropdown/index.vue'
export { default as ComponentsFieldsInputFilter } from '../../../ui/src/components/fields/input-filter/index.vue'
export { default as ComponentsFieldsInputSearch } from '../../../ui/src/components/fields/input-search/index.vue'
export { default as ComponentsFieldsInputText } from '../../../ui/src/components/fields/input-text/index.vue'
export { default as ComponentsFieldsSelectDropdown } from '../../../ui/src/components/fields/select-dropdown/index.vue'
export { default as ComponentsInfoCookies } from '../../../ui/src/components/info/cookies/index.vue'
export { default as ComponentsInfoDefinitionSlide } from '../../../ui/src/components/info/definition-slide/index.vue'
export { default as ComponentsInfoDotLabel } from '../../../ui/src/components/info/dot-label/index.vue'
export { default as ComponentsInfoEmptyMessage } from '../../../ui/src/components/info/empty-message/index.vue'
export { default as ComponentsInfoEntityDetails } from '../../../ui/src/components/info/entity-details/index.vue'
export { default as ComponentsInfoListInfo } from '../../../ui/src/components/info/list-info/index.vue'
export { default as ComponentsInfoLocationTag } from '../../../ui/src/components/info/location-tag/index.vue'
export { default as ComponentsInfoPillarIntro } from '../../../ui/src/components/info/pillar-intro/index.vue'
export { default as ComponentsInfoPretitle } from '../../../ui/src/components/info/pretitle/index.vue'
export { default as ComponentsInfoSimpleTitle } from '../../../ui/src/components/info/simple-title/index.vue'
export { default as ComponentsInfoSpeakerIntro } from '../../../ui/src/components/info/speaker-intro/index.vue'
export { default as ComponentsInfoSponsorTag } from '../../../ui/src/components/info/sponsor-tag/index.vue'
export { default as ComponentsInfoStats } from '../../../ui/src/components/info/stats/index.vue'
export { default as ComponentsInfoTag } from '../../../ui/src/components/info/tag/index.vue'
export { default as ComponentsInfoTime } from '../../../ui/src/components/info/time/index.vue'
export { default as ComponentsLayoutPage } from '../../../ui/src/components/layout/page/index.vue'
export { default as ComponentsMediaImage } from '../../../ui/src/components/media/image/index.vue'
export { default as ComponentsMediaVideo } from '../../../ui/src/components/media/video/index.vue'
export { default as ComponentsMediaYoutube } from '../../../ui/src/components/media/youtube/index.vue'
export { default as ComponentsNavDropdown } from '../../../ui/src/components/nav/dropdown/index.vue'
export { default as ComponentsNavHamburger } from '../../../ui/src/components/nav/hamburger/index.vue'
export { default as ComponentsNavMenu } from '../../../ui/src/components/nav/menu/index.vue'
export { default as ComponentsNavPagination } from '../../../ui/src/components/nav/pagination/index.vue'
export { default as ComponentsNavSimpleMenu } from '../../../ui/src/components/nav/simple-menu/index.vue'
export { default as ComponentsNavToggleFilters } from '../../../ui/src/components/nav/toggle-filters/index.vue'
export { default as ComponentsNavUserDropdown } from '../../../ui/src/components/nav/user-dropdown/index.vue'
export { default as ComponentsProgressLoader } from '../../../ui/src/components/progress/loader/index.vue'
export { default as ComponentsSeoHiddenHeading } from '../../../ui/src/components/seo/hidden-heading/index.vue'
export { default as ComponentsVisualAnimatedGradient } from '../../../ui/src/components/visual/animated-gradient/index.vue'
export { default as ComponentsVisualAnimatedValue } from '../../../ui/src/components/visual/animated-value/index.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
