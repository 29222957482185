var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-image-grid-modal" },
    [
      _c(
        "Section",
        { attrs: { title: _vm.title, pretitle: _vm.pretitle, text: _vm.text } },
        [
          _c(
            "b-grid",
            _vm._l(_vm.items, function (item, id) {
              return _c(
                "b-cell",
                _vm._b(
                  { key: `${item.title}-${id}` },
                  "b-cell",
                  _vm.cellSize,
                  false
                ),
                [
                  _c(
                    "div",
                    { staticClass: "image-cell" },
                    [
                      _c("CardCover", {
                        attrs: {
                          image: item.image.url,
                          title: item.title,
                          "on-click": item.content
                            ? () => _vm.onClick(item)
                            : null,
                          alt: item.image.alternativeText,
                          caption: item.image.caption,
                          "image-ratio": _vm.imagesRatio,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-container" },
            [
              _c("Modal", {
                attrs: {
                  "close-label": "Close",
                  "wrapper-type": "full",
                  "window-size": "md",
                  show: _vm.showModal,
                  "on-close": () => _vm.onCloseModal(),
                },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "header" }, [
                          _c("figure", [
                            _c(
                              "div",
                              {
                                staticClass: "image",
                                attrs: { "data-ratio": _vm.imagesRatio },
                              },
                              [
                                _vm.modalContent.heading.image.url
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.modalContent.heading.image.url,
                                        alt: _vm.modalContent.heading.image
                                          .alternativeText,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.modalContent.heading.image.caption
                              ? _c(
                                  "figcaption",
                                  { staticClass: "figcaption" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.modalContent.heading.image.caption
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.modalContent.heading.title) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.modalContent.heading.description
                            ? _c("div", { staticClass: "description" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.modalContent.heading.description
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("RichText", {
                          attrs: {
                            content: _vm.modalContent.content,
                            type: "modalContent",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }