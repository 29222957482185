import SoundMutedIcon from './assets/sound-muted-embedded.svg';
import SoundActivatedIcon from './assets/sound-activated-embedded.svg';
import Intersection from '../../../containers/intersection';

export default {
  components: {
    SoundMutedIcon,
    SoundActivatedIcon,
    Intersection,
  },
  data: () => ({
    isVideoMuted: true,
    observer: null,
  }),
  props: {
    src: {
      type: String,
    },
    caption: {
      type: String,
    },
    alt: {
      type: String,
    },
    settings: {
      type: Object,
    },
    aspect_ratio: {
      type: String,
    },
    use_sound_button: {
      type: Boolean,
    },
  },
  computed: {
    soundIcon() {
      return this.isVideoMuted ? SoundMutedIcon : SoundActivatedIcon;
    },
  },
  methods: {
    toggleVideoSound() {
      this.isVideoMuted = !this.isVideoMuted
    },
    muteSound() {
      if (this.use_sound_button) {
        this.isVideoMuted = true;
      }
    },
    onElementObserved(entries) {
      for (const element of entries) {
        if (element.isIntersecting) {
          this.$refs.video.load()
          this.$refs.video.play()
          this.observer.unobserve(this.$refs.video)
        }
      }
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(
      this.onElementObserved,
      {
        threshold: 0.1,
      }
    );
    this.observer.observe(this.$refs.video)
    this.isVideoMuted = this.settings && this.settings.muted || false;

    if (this.$refs.sound) {
      this.$refs.sound.onClick = this.toggleVideoSound;
    }
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
};
