var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-long-text" },
    [
      _c(
        "Section",
        { attrs: { title: _vm.title } },
        [
          _c(
            "b-grid",
            [
              _c(
                "b-cell",
                { attrs: { xxs: "12", lg: "8" } },
                [
                  _vm.date
                    ? _c("p", { staticClass: "date" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getDate(_vm.date, { has_date: true })) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("RichText", { attrs: { content: _vm.content } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }