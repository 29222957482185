import Section from '../../components/containers/section';
import PillarIntro from '../../components/info/pillar-intro';

export default {
  components: {
    Section,
    PillarIntro,
  },
  data: () => ({
    longestPillar: undefined,
    fontSizeMultiplier: 0.38,
    lineHeightMultiplier: 1,
    letterSpacingMultiplier: 0.05,
    minSize: 0,
    maxSize: 200,
  }),
  props: {
    pretitle: {
      type: String,
    },
    pillars: {
      type: Array,
      required: true,
    },
    enableLinks: {
      type: Boolean,
      default: true,
    },
    settings: {
      type: Object,
    },
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    }
  },
  methods: {
    onMouseEnter(pillar) {
      this.$el.style.setProperty('--active-color', pillar.color);
    },
    resetPillar() {
      this.$el.style.setProperty('--active-color', null);
    },
    getPillarHref(pillar) {
      if (this.href) {
        return this.href
      }

      if (
        this.enableLinks
        && this.settings
        && this.settings.filters
        && this.settings.filters.page
      ) {
        return `${this.settings.filters.page}/pillars/${pillar.slug}`;
      }

      return ""
    },
    getLongestPillarLength() {
      return this.pillars
        .map(({ title }) => title)
        .reduce((a, b) => a.length > b.length ? a : b)
        .length
    },
    fitText() {
      const fontSize = this.$refs.wrapp.clientWidth / (this.fontSizeMultiplier * this.longestPillar);
      const paddedFontSize = Math.min(Math.max(fontSize, this.minSize), this.maxSize);

      this.$refs.pillar.forEach((pillar) => {
        const $pillar = pillar.$el.querySelector('.title');
        $pillar.style.fontSize = paddedFontSize + 'px';
        $pillar.style.lineHeight = paddedFontSize * this.lineHeightMultiplier + 'px';
        $pillar.style.letterSpacing = -paddedFontSize * this.letterSpacingMultiplier + 'px';
      });
    }
  },

  mounted() {
    if (typeof window !== 'undefined') {
      this.longestPillar = this.getLongestPillarLength();
      setTimeout(() => this.fitText(), 200);
      window.addEventListener('resize', this.fitText);
    }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.fitText);
    }
  },
};
