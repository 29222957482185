import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm';

export default async (ctx, inject) => {
  const { app, $areCookiesAccepted } = ctx
  const cookieConsent = $areCookiesAccepted()
  // render only on client side
  if (typeof window === 'undefined') {
    return
  }
  // render only when cookie consent is given
  if (!cookieConsent) {
    return
  }

  const settings = app.store.getters['settings/settingsByLocale']('en')

  // render only when id is set
  if (!settings?.google_gtm_id) {
    return
  }

  const { google_gtm_id } = settings

  if (google_gtm_id === '') {
    return
  }

  Vue.use(VueGtm, {
    id: google_gtm_id,
    defer: false,
    compatibility: false,
    enabled: cookieConsent, 
    debug: true,
    loadScript: true,
    vueRouter: app.router,
  });

}
