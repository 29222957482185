var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-video-grid" },
    [
      _c(
        "Section",
        {
          attrs: {
            "with-border-top": "",
            pretitle: _vm.pretitle,
            title: _vm.title,
            text: _vm.text,
          },
        },
        [
          _c(
            "b-grid",
            _vm._l(_vm.items, function (video, index) {
              return _c(
                "b-cell",
                _vm._b(
                  { key: `video-${index}` },
                  "b-cell",
                  _vm.getCellSize(index),
                  false
                ),
                [
                  _c("Youtube", {
                    attrs: {
                      aspect_ratio: "16/9",
                      src: video.src,
                      title: video.title,
                      thumbnail: video.thumbnail && video.thumbnail.url,
                      alt: video.thumbnail && video.thumbnail.alternativeText,
                      settings: _vm.settings,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }