import FiltersIcon from './assets/filters-embedded.svg';

export default {
  components: {
    FiltersIcon,
  },
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    },
    label: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
