export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function
    }
  },
};
