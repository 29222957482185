// redirect for old events urls
const axios = require('axios')

module.exports = async function (req, res, next) {
  const {
    data: { events },
  } = await axios.get(`${process.env.API_ENDPOINT}/events?_locale=all`)

  const oldUrls = events.map(event => ({
    from: event.locale == 'en' ? `/events/${event.slug}` : `/${event.locale}/events/${event.slug}`,
    to: event.url
  }))

  const redirect = oldUrls.find(e => e.from === req.url)
  if (redirect) {
    res.writeHead(301, { Location: redirect.to })
    res.end()
  } else {
    next()
  }
}
