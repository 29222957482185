var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { staticClass: "c-animated-value" }, [
    _c("span", { staticClass: "label" }, [_vm._v(_vm._s(this.currentValue))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }