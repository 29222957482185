var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-list-info" }, [
    _c("p", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
    ]),
    _vm._v(" "),
    _vm.text
      ? _c("p", { staticClass: "text" }, [
          _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }