import ImageComponent from '../../components/media/image'
import LocationTag from '../../components/info/location-tag'
import Tag from '../../components/info/tag'
import LeftArrow from './assets/left-arrow-embedded.svg'
import RightArrow from './assets/right-arrow-embedded.svg'
import ZigZag from './assets/image-1-embedded.svg'

export default {
  components: {
    ImageComponent,
    LocationTag,
    Tag,
    LeftArrow,
    RightArrow,
    ZigZag
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    manual: {
      type: Object
    }
  },
  data() {
    return {
      events: this.getItems(),
      currentItem: 0,
      start: null,
      end: null,
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    getItems() {
      if(this.manual){
        return this.manual.events;
      }
      return this.items;
    },
    nextItem() {
      if (this.currentItem + 1 == this.events.length) {
        this.currentItem = 0
      } else {
        this.currentItem++
      }
    },
    prevItem() {
      if (this.currentItem == 0) {
        this.currentItem = this.events.length - 1
      } else {
        this.currentItem--
      }
    },
    touchStart(event) {
      this.start = event.changedTouches[0]
    },
    touchEnd(event) {
      let end = event.changedTouches[0]

      let horizontalDifference = this.start.screenX - end.screenX

      if (horizontalDifference >= 50) {
        this.nextItem()
      }

      if (horizontalDifference <= -50) {
        this.prevItem()
      }
    }
  }
}