import StickyElement from '../../components/containers/sticky-element';
import SelectDropdown from '../../components/fields/select-dropdown';
import Intersection from '../../containers/intersection';

export default {
  components: {
    StickyElement,
    SelectDropdown,
    Intersection,
  },
  data: () => ({
    active: null,
    isInView: true,
  }),
  props: {
    title: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    description: {
      type: String,
    },
    items: {
      type: Array,
    },
    media: {
      type: Object,
    }
  },
  computed: {
    mappedItems() {
      return this.items.map((item) => ({
        id: item.value,
        title: item.label,
      }));
    }
  },
  methods: {
    onEnter() {
      this.isInView = true;
    },
    onLeave() {
      this.isInView = false;
    },
    scrollIntoView(id) {
      const element = document.querySelector(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    onActiveMenuItem(e) {
      const active = this.mappedItems.find((item) => item.id === `#${e.detail}`);
      if (active) {
        this.active = active;
      }
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('activeMenuItem', this.onActiveMenuItem);
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('activeMenuItem', this.onActiveMenuItem);
     }
  }
};
