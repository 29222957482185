import Section from '../../components/containers/section';
import Stats from '../../components/info/stats';

const createIterator = (arr) => {
  var index = 0;
  return {
    next: function() {
      return index < arr.length ?
        {value: arr[index++], done: false} :
        {done: true};
    }
  }
}

export default {
  data() {
    return {
      iterator: null
    }
  },
  components: {
    Section,
    Stats,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    pretitle: {
      type: String,
    },
    stats: {
      type: Array
    },
    revealed: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    revealed(newVal) {
      if (newVal) {
        this.showAnimation();
      }
    }
  },
  methods: {
    showAnimation() {
      this.iterator.next().value.play();
    },

    onFinish() {
      const currentStat = this.iterator.next();

      if (currentStat.done === false) {
        currentStat.value.play();
      } else {
        this.iterator = null;
      }
    },

  },
  mounted() {
    this.$nextTick(() => {
      const refsArray = this.stats?.map( (stat, index) => this.$refs[`stats${index}`][0]);
      this.iterator = createIterator(refsArray);

      if(this.revealed) {
        this.showAnimation();
      }
    })
  }
};
