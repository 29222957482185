import Button from '../../components/cta/button';
import Youtube from '../../components/media/youtube';
import Section from "../../components/containers/section";

export default {
  components: {
    Button,
    Section,
    Youtube,
  },
  props: {
    pretitle: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String,
    },
    items: {
      type: Array,
      required: true
    },
    onClick: {
      type: Function,
    },
    settings: {
      // Can be any of this https://developers.google.com/youtube/player_parameters
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getCellSize(index) {
      if (this.items.length <= 1) {
        return { xxs: 12 }
      }

      return {
        xxs: 12,
        lg: index > 1 ? 4 : 6,
      }
    }
  }
};
