import ArrowIcon from './assets/arrow-embedded.svg';

export default {
  components: {
    ArrowIcon
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    href: {
      type: String,
    },
    ariaPrev: {
      type: String,
      default: 'prev',
    },
    ariaNext: {
      type: String,
      default: 'next',
    },
    onClick: {
      type: Function,
    },
    onPrevClick: {
      type: Function,
    },
    onNextClick: {
      type: Function,
    },
    condensed: {
      type: Boolean
    }
  },
  computed: {
    isAtLast() {
      return this.currentPage === this.total
    },

    isAtFirst() {
      return this.currentPage === 1
    },

    pages() {
      const range = [];
      for (let i = 1; i <= this.total; i++) {
        range.push(i)
      }
      return range
    },

    condensedContent() {
      return `${this.currentPage}/${this.total}`;
    },
  },
  methods: {
    isActive(index) {
      return this.currentPage === index
    }
  },

  mounted() {
    this.$refs.clickablePrev.onClick = this.onPrevClick || this.onClick;
    this.$refs.clickableNext.onClick = this.onNextClick || this.onClick;
  },
};
