var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-toggle-filters",
      attrs: { "data-is-open": _vm.showFilters },
    },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "toggle-wrapper", on: { click: _vm.onToggleClick } },
          [
            _c("ExpandIcon"),
            _vm._v(" "),
            _c("p", { staticClass: "text" }, [
              _vm._v("\n        " + _vm._s(_vm.filtersButtonText) + "\n      "),
            ]),
            _vm._v(" "),
            _vm.filterCount
              ? _c("span", { staticClass: "filter-count" }, [
                  _vm._v("\n        " + _vm._s(_vm.filterCount) + "\n      "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "filters-wrapper" }, [_vm._t("filters")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }