export default function({ route, redirect, i18n }) {
  const { locale, defaultLocale } = i18n;

  // Case it's home (adjusting for URLs that may include 'home')
  if (route.fullPath.includes('home')) {
    if (locale === defaultLocale) {
      return redirect(301, '/');
    }
    return redirect(301, `/${locale}`);
  }

  // Remove trailing slash from the route if present
  if (route.fullPath !== '/' && route.fullPath.endsWith('/')) {
    const newPath = route.fullPath.slice(0, -1);
    return redirect(301, newPath);
  }

}