import Section from "../../components/containers/section";
import SelectDropdown from "../../components/fields/select-dropdown";
import StickyElement from "../../components/containers/sticky-element";

export default {
  components: {
    Section,
    StickyElement,
    SelectDropdown
  },
  props: {
    pretitle: {
      type: String
    },
    title: {
      type: String
    },
    editions: {
      type: Array
    },
    selected: {
      type: Object
    },
    is_fixed: {
      type: Boolean,
      default: true
    },
    onClick: {
      type: Function
    }
  },
  methods: {
    getEditionWithTitle(edition) {
      return {
        ...edition,
        title: `${edition?.title} ${edition?.year}`
      }
    }
  },
  computed: {
    mappedEditions() {
      return this.editions.map((edition) => this.getEditionWithTitle(edition));
    },
    mappedSelected() {
      return this.selected ? this.getEditionWithTitle(this.selected) : null;
    }
  }
};
