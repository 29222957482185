import ArrowIcon from './assets/arrow-embedded.svg';
export default {
  components: {
    ArrowIcon
  },
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
    },
    href: {
      type: String,
      default: null,
    },
    onClick: {
      type: Function,
      default: null,
    },
    withBorder: {
      type: Boolean,
    },
    spacedOut: {
      type: Boolean,
    }
  },
  computed: {
    hasTags() {
      return !!this.$slots.tags;
    },
  }
};
