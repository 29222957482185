export default {
  data() {
    return {
      rightToLeft: false,
      cellAlign: 'left'
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      const dir = document.documentElement.getAttribute('dir');
      this.rightToLeft = (dir && dir === 'rtl');
      this.cellAlign = (this.rightToLeft)? 'right' : 'left';
    }
  }
}