import Section from '../../components/containers/section';
import Download from '../../components/cta/download';

export default {
  components: {
    Section,
    Download,
  },
  props: {
    pretitle: {
      type: String,
    },
    title: {
      type: String,
    },
    resources: {
      type: Array,
    },
    onClick: {
      type: Function,
    },
  },
  computed: {
    hasHeading() {
      return !!this.pretitle || !!this.title;
    }
  }
};
