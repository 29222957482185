// rounded default
import ArrowIcon from './assets/arrow-embedded.svg';
import ArrowIconMobile from './assets/arrow-mobile-embedded.svg';

// vertical
import ArrowIconVertical from './assets/arrow-vertical-embedded.svg';
import ArrowIconVerticalMobile from './assets/arrow-vertical-mobile-embedded.svg';

// big
import ArrowIconBig from './assets/arrow-big-embedded.svg';
import ArrowIconBigMobile from './assets/arrow-big-mobile-embedded.svg';

export default {
  components: {
    ArrowIcon,
    ArrowIconMobile,
    ArrowIconVertical,
    ArrowIconVerticalMobile,
    ArrowIconBig,
    ArrowIconBigMobile,
  },
  props: {
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    },
    arrowType: {
      type: String,
      default: 'rounded',
    },
    label: {
      type: String,
    },
    arrowDirection: {
      type: String,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  methods: {
    iconDesktop() {
      switch (this.arrowType){
        case 'vertical':
          return ArrowIconVertical;
        case 'big':
          return ArrowIconBig;
        case 'rounded':
        default:
          return ArrowIcon;
      }
    },

    iconMobile() {
      switch (this.arrowType){
        case 'vertical':
          return ArrowIconVerticalMobile;
        case 'big':
          return ArrowIconBigMobile;
        case 'rounded':
        default:
          return ArrowIconMobile;
      }
    }
  },

  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
