import Section from '../../components/containers/section';
import CardCover from '../../components/covers/card-cover';
import Tag from '../../components/info/tag';
import Button from '../../components/cta/button';
import rightToLeft from '../../mixins/right-to-left';
import dateFormat from '../../mixins/date-format';

export default {
  mixins: [rightToLeft, dateFormat],
  components: {
    Section,
    CardCover,
    Tag,
    Button,
  },
  props: {
    title: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    background: {
      type: String,
    },
    events: {
      type: Array,
      required: true
    },
    button: {
      type: Object,
    },
    onClick: {
      type: Function,
    },
  },
};
