var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-loader",
      attrs: {
        "data-is-changing-label": _vm.changingLabel,
        "data-is-loading": _vm.loading,
      },
    },
    [
      _c("div", { staticClass: "loader-wrapper" }, [
        _c("p", { staticClass: "label" }, [
          _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "prev-label" }, [
          _vm._v("\n      " + _vm._s(_vm.prevLabel) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "next-label" }, [
          _vm._v("\n      " + _vm._s(_vm.nextLabel) + "\n    "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }