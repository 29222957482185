var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-rounded-button" },
    [
      _c(
        "b-clickable",
        { ref: "clickable", attrs: { type: "button", href: _vm.href } },
        [
          _vm.icon
            ? _c(
                "span",
                [
                  _vm.icon === "arrow-down"
                    ? _c("ArrowDown", { staticClass: "arrow-down" })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }