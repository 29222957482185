export default {
  methods: {
    getDate(date, item) {
      if (!date || !item?.has_date) return undefined;

      const current = new Date(date);

      // Leaving this commented for now. Dates will keep the same format in both languages as design request.

      // if (item?.locale === 'en') {
      //   const newDate = `${current.getDate()}.${("0" + (current.getMonth() + 1)).slice(-2)}.${current.getFullYear()}`;
      //   return newDate;
      // }

      const newDate = current.toISOString().split('T')[0];
      return newDate.replace(/-/g, '/');
    },
  },
}
