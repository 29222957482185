var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-posts-slider" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            text: _vm.text,
            "wrapper-type": "full",
            "with-border-top": "",
          },
          scopedSlots: _vm._u(
            [
              _vm.button
                ? {
                    key: "cta",
                    fn: function () {
                      return [
                        _c(
                          "Button",
                          {
                            attrs: {
                              href: _vm.button.href,
                              "on-click": _vm.onClick,
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.button.label) +
                                "\n      "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm.posts && _vm.posts.length > 0
            ? _c(
                "b-slider",
                {
                  attrs: {
                    arrows: "",
                    "right-to-left": _vm.rightToLeft,
                    "cell-align": _vm.cellAlign,
                  },
                },
                _vm._l(_vm.posts, function (post) {
                  return _c(
                    "b-cell",
                    _vm._b({ key: post.id }, "b-cell", _vm.postCellSize, false),
                    [
                      _c("CardCover", {
                        attrs: {
                          title: post.title,
                          description: post.description,
                          href: post.url,
                          images: post.featured_image && post.featured_image,
                          alt:
                            post.featured_image &&
                            post.featured_image.alternativeText,
                          caption:
                            post.featured_image && post.featured_image.caption,
                          date: _vm.getDate(post.published_at, {
                            has_date: true,
                          }),
                          "on-click": post.url ? _vm.onClick : null,
                        },
                        scopedSlots: _vm._u(
                          [
                            post.post_categories &&
                            post.post_categories.length > 0
                              ? {
                                  key: "tags",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "tags" },
                                        _vm._l(
                                          post.post_categories,
                                          function (category) {
                                            return _c(
                                              "Tag",
                                              {
                                                key: category.title,
                                                attrs: {
                                                  color: category.color,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(category.title) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm.empty && _vm.empty.title
            ? _c(
                "div",
                { staticClass: "empty" },
                [
                  _c(
                    "EmptyMessage",
                    _vm._b({}, "EmptyMessage", _vm.empty, false)
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }