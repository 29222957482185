import MeetIcon from './assets/meet-embedded.svg';
import WatchIcon from './assets/watch-embedded.svg';
import AgendaIcon from './assets/agenda-embedded.svg';
import ArrowsIcon from './assets/arrows-embedded.svg';
import ArrowUpIcon from './assets/arrow-up-embedded.svg';
import ArrowDownIcon from './assets/arrow-down-embedded.svg';

const iconMap = {
  meet: MeetIcon,
  watch: WatchIcon,
  agenda: AgendaIcon,
  arrows: ArrowsIcon,
  arrowUp: ArrowUpIcon,
  arrowDown: ArrowDownIcon,
};

export default {
  components: {
    MeetIcon,
    WatchIcon,
    AgendaIcon,
    ArrowsIcon,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
    },
    size: {
      type: String,
    },
    icon: {
      type: String,
    },
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    },
    disabled: {
      type: Boolean,
    },
    textSize: {
      type: String,
      default: 'ac2',
    },
  },
  computed: {
    iconElement() {
      return this.icon && iconMap[this.icon];
    }
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
