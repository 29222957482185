var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-post-cover", attrs: { "data-clickable": !!_vm.href } },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "figure",
          [
            _c(
              "b-clickable",
              {
                ref: "image",
                attrs: {
                  href: _vm.href,
                  disabled: !_vm.href,
                  "on-click": _vm.onClick,
                },
              },
              [
                _c("div", { staticClass: "image" }, [
                  _c("img", { attrs: { src: _vm.image, alt: _vm.alt } }),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.caption
              ? _c("figcaption", { staticClass: "figcaption" }, [
                  _vm._v("\n        " + _vm._s(_vm.caption) + "\n      "),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-wrapper" },
          [
            _c(
              "b-clickable",
              {
                ref: "clickable",
                attrs: {
                  href: _vm.href,
                  disabled: !_vm.href,
                  "on-click": _vm.onClick,
                },
              },
              [
                _c("p", { staticClass: "title" }, [
                  _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.date
              ? _c("p", { staticClass: "date" }, [
                  _vm._v("\n        " + _vm._s(_vm.date) + "\n      "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }