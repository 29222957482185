export default {
  props: {
    color: {
      type: String,
    },
    time: {
      type: Boolean
    },
    type: {
      type: Boolean
    },
    noBorder: {
      type: Boolean
    }
  },
};
