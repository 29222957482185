const middleware = {}

middleware['rename'] = require('../middleware/rename.js')
middleware['rename'] = middleware['rename'].default || middleware['rename']

middleware['serverMiddleware'] = require('../middleware/serverMiddleware.js')
middleware['serverMiddleware'] = middleware['serverMiddleware'].default || middleware['serverMiddleware']

middleware['sitemap'] = require('../middleware/sitemap.js')
middleware['sitemap'] = middleware['sitemap'].default || middleware['sitemap']

export default middleware
