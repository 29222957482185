var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-simple-title", attrs: { "data-color": _vm.color } },
    [
      _c("p", {
        staticClass: "text",
        domProps: { innerHTML: _vm._s(_vm.text) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }