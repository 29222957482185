var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-entity-details", attrs: { "data-type": _vm.type } },
    [
      _c(
        "b-grid",
        [
          _c("b-cell", { attrs: { xxs: "12", lg: "6" } }, [
            _c("div", { staticClass: "content-cell" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
              ]),
              _vm._v(" "),
              _vm.subtitle
                ? _c("p", { staticClass: "subtitle" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.subtitle) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.text
                ? _c("p", {
                    staticClass: "description",
                    domProps: { innerHTML: _vm._s(_vm.text) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasCta
                ? _c("div", { staticClass: "buttons" }, [_vm._t("cta")], 2)
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm.image
            ? _c(
                "b-cell",
                {
                  staticClass: "image-cell",
                  attrs: { xxs: "12", lg: "5", "offset-lg": "1" },
                },
                [
                  _c("div", { staticClass: "image" }, [
                    _c("img", { attrs: { src: _vm.image } }),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-grid",
        [
          _c("b-cell", { attrs: { xxs: "12", lg: "6" } }, [
            _vm.text
              ? _c("p", {
                  staticClass: "mobile-description",
                  domProps: { innerHTML: _vm._s(_vm.text) },
                })
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }