var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "m-featured-event",
      attrs: { "data-clickable": !!(_vm.onClick && _vm.href) },
    },
    [
      _c(
        "Section",
        {
          attrs: {
            pretitle: _vm.pretitle,
            date: _vm.getDate(_vm.date, { has_date: _vm.has_date }),
            title: _vm.title,
            text: _vm.text,
            "with-border-top": "",
          },
          scopedSlots: _vm._u(
            [
              _vm.pillars && _vm.pillars.length > 0
                ? {
                    key: "tags",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "tags" },
                          _vm._l(_vm.pillars, function (pillar) {
                            return _c(
                              "span",
                              { key: pillar.title },
                              [
                                _c("Tag", { attrs: { color: pillar.color } }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(pillar.title) +
                                      "\n          "
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm.onClick && _vm.href
                ? {
                    key: "cta",
                    fn: function () {
                      return [
                        _c(
                          "ArrowButton",
                          {
                            attrs: {
                              "arrow-type": "big",
                              href: _vm.href,
                              "on-click": _vm.onClick,
                            },
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.title) + "\n      "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm.featured_image
            ? _c(
                "div",
                { staticClass: "media" },
                [
                  _vm.isImage
                    ? _c(
                        "b-clickable",
                        { ref: "clickableImage", attrs: { href: _vm.href } },
                        [
                          _c("div", { staticClass: "clickable" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(" " + _vm._s(_vm.href)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "image-wrapper" },
                              [
                                _c("div", { staticClass: "overlay" }),
                                _vm._v(" "),
                                _c("ImageComponent", {
                                  attrs: {
                                    images: _vm.featured_image,
                                    "lazy-load": true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm.isVideo
                    ? _c("Video", {
                        attrs: {
                          src: _vm.featured_image.url,
                          caption: _vm.featured_image.caption,
                          alt: _vm.featured_image.alternativeText,
                          use_sound_button: _vm.has_sound,
                          aspect_ratio: "16/9",
                          settings: {
                            playsinline: "true",
                            loop: true,
                            autoplay: true,
                            muted: true,
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }