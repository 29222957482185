var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "picture",
    [
      _vm._l(Object.keys(_vm.formats), function (key) {
        return _c("source", {
          key: key,
          attrs: {
            media: `(max-width: ${_vm.formats[key].width}px)`,
            srcset: _vm.formats[key].url,
          },
        })
      }),
      _vm._v(" "),
      _c("img", {
        class: _vm.className,
        attrs: {
          src: _vm.defaultUrl,
          loading: _vm.lazyLoad && "lazy",
          alt: _vm.imageAlt,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }