const cookieName = 'yoc_cookies_accept'

export default (context, inject) => {
  const onAcceptCookies = () => {
    context.$cookies.set(cookieName, true, {
      path: '/',
      maxAge: 3650 * 12 * 60 * 12,
    })

    location.reload()
  }

  const areCookiesAccepted = () => {
    return !!context.$cookies.get(cookieName)
  }

  inject('onAcceptCookies', onAcceptCookies)
  inject('areCookiesAccepted', areCookiesAccepted)
}
