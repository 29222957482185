var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-definition-slide",
      attrs: {
        "data-show-flag": _vm.showFlag,
        "data-filled": _vm.filled,
        "data-text-size": _vm.textSize,
        "data-layout": _vm.layout,
      },
    },
    [
      _c("p", { staticClass: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n\n    "),
        _c(
          "span",
          { staticClass: "highlight", attrs: { "aria-hidden": "true" } },
          [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
        ),
      ]),
      _vm._v(" "),
      _vm.description
        ? _c("p", { staticClass: "description" }, [
            _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.description
        ? _c(
            "span",
            { staticClass: "icon" },
            [
              _c(_vm.getFlagIcon(_vm.description), {
                tag: "component",
                attrs: { "aria-hidden": "true", focusable: "false" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }