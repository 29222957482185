export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
    },
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
