var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-edition-selector" },
    [
      _c("Section", {
        attrs: {
          title: _vm.title,
          pretitle: _vm.pretitle,
          "sticky-header": _vm.is_fixed,
          spacing: "none",
        },
        scopedSlots: _vm._u([
          {
            key: "dropdown",
            fn: function () {
              return [
                _c("SelectDropdown", {
                  attrs: {
                    options: _vm.mappedEditions,
                    "select-option": _vm.onClick,
                    selected: _vm.mappedSelected,
                    label: _vm.title,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }