export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
    label: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
    },
  },
};
