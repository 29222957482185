import Section from '../../components/containers/section';
import Button from '../../components/cta/button';
import DotLabel from '../../components/info/dot-label';
import SponsorCover from '../../components/covers/sponsor-cover';

export default {
  components: {
    Section,
    Button,
    DotLabel,
    SponsorCover
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    pretitle: {
      type: String,
    },
    featuredText: {
      type: String,
    },
    description: {
      type: String,
    },
    sponsors: {
      type: Array
    },
    button: {
      type: Object
    },
    background: {
      type: String,
      default: null
    },
    onClick: {
      type: Function,
    }
  },
};
