import Logo from '../../components/brand/logo';
import SimpleMenu from '../../components/nav/simple-menu';
import SocialButton from '../../components/cta/social-button';

export default {
  components: {
    Logo,
    SimpleMenu,
    SocialButton
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
    },
    logoLabel: {
      type: String,
    },
    copyright: {
      type: String,
    },
    copyrightHighlighted: {
      type: String,
    },
    socialButtons: {
      type: Array,
    },
  }
};
