import Section from '../../components/containers/section';
import CardCover from '../../components/covers/card-cover';
import Button from '../../components/cta/button';
import Tag from '../../components/info/tag';
import EmptyMessage from '../../components/info/empty-message';
import rightToLeft from '../../mixins/right-to-left';
import dateFormat from '../../mixins/date-format';

export default {
  mixins: [rightToLeft, dateFormat],
  components: {
    Section,
    CardCover,
    Button,
    Tag,
    EmptyMessage,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    posts: {
      type: Array,
    },
    button: {
      type: Object,
    },
    onClick: {
      type: Function,
    },
    featured: {
      type: Boolean,
    },
    empty: {
      type: Object,
    },
  },
  computed: {
    postCellSize() {
      return {
        xxs: 11,
        md: 5,
        xl: this.featured ? 6 : 4,
      }
    },
  },
  methods: {
    shouldRenderTags(post) {
      return (post.pillars && post.pillars.length > 0) || (post.event_types && post.event_types.length > 0);
    }
  }
};
