import CardCover from "../../components/covers/card-cover";
import Section from "../../components/containers/section";

export default {
  components: {
    Section,
    CardCover,
  },
  props: {
    pretitle: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String,
    },
    items: {
      type: Array,
      required: true
    },
    onClick: {
      type: Function,
    }
  },
  methods: {
    getCellSize(index) {
      return {
        xxs: 12,
        lg: index === 0 ? 12 : 6,
      }
    },
    getImageRatio(index) {
      return index === 0 ? '16/9' : '1/1';
    }
  }
};
