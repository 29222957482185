import PeopleIcon from './assets/people-embedded.svg';
import InstitutionsIcon from './assets/institutions-embedded.svg';
import EventsIcon from './assets/events-embedded.svg';
import CulturalIcon from './assets/cultural-embedded.svg';

import AnimatedValue from '../../visual/animated-value';

const scaleNumber = (num) => Math.min(Math.max(parseInt(num), 0), 100) * 1e-2;

export default {
  data() {
    return {
      animatedElement: null,
      circleElement: null,
    }
  },
  components: {
    PeopleIcon,
    InstitutionsIcon,
    EventsIcon,
    CulturalIcon,
    AnimatedValue
  },

  props: {
    percent: {
      type: Number,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
    },
    icon: {
      type: String,
    },
    paused: {
      type: Boolean,
      default: false
    },
    onFinish: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    iconSvg() {
      let iconComponent = null;
      switch (this.icon) {
        case 'people':
          iconComponent = PeopleIcon;
          break;
        case 'institutions':
          iconComponent = InstitutionsIcon;
          break;
        case 'events':
          iconComponent = EventsIcon;
          break;
        case 'cultural':
          iconComponent = CulturalIcon;
          break;
        default:
          break;
      }

      return iconComponent;
    }
  },

  methods: {
    play() {
      if (this.reducedMotion() === false) {
        this.animatedElement.play();
        this.circleElement.play();
        this.animatedValueElement.play();
      }
    },

    reducedMotion() {
      if (typeof window !== undefined) {
        return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
      }

      return false;
    }
  },

  mounted() {
    const opacityAnimation = [
      {opacity: 0},
      {opacity: 1}
    ];

    const circleAnimation = [
      {transform: `scale(0.1)` },
      {transform: `scale(${ scaleNumber(this.percent) })` }
    ];

    const animationTiming = {
      fill: 'forwards',
      easing: 'ease-out',
      duration: 800
    }

    this.$nextTick(() => {
      if (this.reducedMotion() === false ) {
        // FADING
        this.animatedElement = this.$el.animate(
          opacityAnimation,
          animationTiming
        );

        // CIRCLE IN
        this.circleElement = this.$refs.circle.animate(
          circleAnimation,
          animationTiming
        );

        this.animatedElement.onfinish = this.onFinish;
        this.animatedValueElement = this.$refs.animatedvalue;

        if(this.paused) {
          this.circleElement.pause();
          this.animatedElement.pause();
        } else {
          this.play();
        }
      }
    });
  }
};
