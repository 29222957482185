var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-stats", attrs: { "data-color": _vm.color } },
    [
      _c("div", { staticClass: "graph" }, [
        _c("div", { ref: "circle", staticClass: "inner-circle" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stats-value" },
        [
          _c("AnimatedValue", {
            ref: "animatedvalue",
            attrs: { from: "0", to: _vm.value, duration: "1500", paused: "" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "stats-details" }, [
        _c("span", { staticClass: "stats-label" }, [
          _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
        ]),
        _vm._v(" "),
        !!_vm.iconSvg
          ? _c(
              "span",
              { staticClass: "stats-icon" },
              [
                _c(_vm.iconSvg, {
                  tag: "component",
                  attrs: { "aria-hidden": "true", focusable: "false" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }