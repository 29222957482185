export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
  },
};
