import ArrowButton from "../../components/cta/arrow-button";
import RichText from "../../components/article/rich-text";
import ImageComponent from "../../components/media/image";

export default {
  components: {
    ArrowButton,
    RichText,
    ImageComponent
  },
  data: () => ({
    scrollTo: null
  }),
  props: {
    title: {
      type: String
    },
    pretitle: {
      type: String
    },
    text: {
      type: String
    },
    subtitle: {
      type: String
    },
    background_type: {
      type: String
    },
    media: {
      type: Object
    },
    show_arrow: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Object
    }
  },
  mounted() {
    import("scroll-to").then(res => (this.scrollTo = res.default));
  },
  methods: {
    scrollToContent() {
      if (this.scrollTo !== null) {
        this.scrollTo(0, this.$el.clientHeight);
      }
    }
  },
  computed: {
    hasMedia() {
      return this.media;
    },
    isImage() {
      return this.media.mime && this.media.mime.startsWith("image");
    },
    background() {
      return this.halfBackground ? null : "dark";
    },
    halfBackground() {
      return this.background_type === "half";
    }
  }
};
