var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-hooks-menu", attrs: { "data-is-visible": _vm.isInView } },
    [
      _c(
        "div",
        { staticClass: "sticky-menu" },
        [
          _c(
            "b-wrapper",
            [
              _c("StickyElement", [
                _c(
                  "div",
                  { staticClass: "select" },
                  [
                    _c("SelectDropdown", {
                      attrs: {
                        color: "black",
                        options: _vm.mappedItems,
                        "select-option": _vm.scrollIntoView,
                        selected: _vm.active,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Intersection",
        {
          attrs: {
            "root-margin": "-20% 0px 0px 0px",
            "on-enter": _vm.onEnter,
            "on-leave": _vm.onLeave,
          },
        },
        [
          _c("div", { staticClass: "hero-content" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("b-wrapper", [
                  _c("div", { staticClass: "heading" }, [
                    _vm.pretitle
                      ? _c("p", { staticClass: "pretitle" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.pretitle) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h2", { staticClass: "title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.title) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.description) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ol",
                    { staticClass: "footer" },
                    _vm._l(_vm.mappedItems, function (item) {
                      return _c("li", { key: item.id }, [
                        _c(
                          "a",
                          {
                            attrs: { href: item.id },
                            on: {
                              click: (e) => {
                                e.preventDefault()
                                _vm.scrollIntoView(item.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.title) +
                                "\n              "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.media
              ? _c(
                  "div",
                  { staticClass: "media" },
                  [
                    _c("b-wrapper", { attrs: { type: "desktop-full" } }, [
                      _c("div", { staticClass: "media-wrapper" }, [
                        _c("div", { staticClass: "overlay" }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: _vm.media.url,
                            alt: _vm.media.alternativeText,
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }