export const state = () => ({
  selectedEdition: {},
})

export const actions = {
  updateEdition({ commit }, data) {
    commit('updateEdition', data)
  },
}

export const mutations = {
  updateEdition(state, data) {
    state.selectedEdition = data
  },
}

export const getters = {
  getSelectedEdition: (state) => {
    return state.selectedEdition
  },
}
