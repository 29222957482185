var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-image-grid" },
    [
      _c(
        "Section",
        { attrs: { title: _vm.title, pretitle: _vm.pretitle, text: _vm.text } },
        [
          _c(
            "b-grid",
            _vm._l(_vm.items, function (item, id) {
              return _c(
                "b-cell",
                _vm._b(
                  { key: `${item.title}-${id}` },
                  "b-cell",
                  _vm.cellSize,
                  false
                ),
                [
                  _c(
                    "div",
                    { staticClass: "image-cell" },
                    [
                      _c("CardCover", {
                        attrs: {
                          href: item.href,
                          images: item.featured_image,
                          title: item.title,
                          "on-click": item.href ? _vm.onClick : null,
                          alt: item.featured_image?.alternativeText,
                          description: item.description,
                          caption: item.featured_image?.caption,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }