export default {
  props: {
    options: {
      type: Array,
    }
  },
  data() {
    return {
      statedOptions: [],
      stateSelected: null,
    }
  },
  created() {
    this.statedOptions = this.options
  },
  methods: {
    selectOption(id) {
      this.stateSelected = this.options.find(option => option.id === id);
    },
  },
  render() {
    return this.$scopedSlots.default({
      statedOptions: this.statedOptions,
      selectOption: this.selectOption,
      stateSelected: this.stateSelected,
    });
  },
};
