export const state = () => []

export const actions = {}

export const mutations = {
  update(state, data) {
    state.push(...data)
  },
}

export const getters = {
  settingsByLocale: (state) => (locale) => {
    return state.find((item) => item.locale == locale)
  },
  getSeo: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings.seo
  },
  getBackLabel: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings?.back_url_label ? settings.back_url_label : 'Back'
  },
  getEditions: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings.editions
  },
  getMarqueeItems: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings.marquee?.marquee_items || []
  },
  getLatestEvents: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings.events
  },
  getPastEditions: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings.past_editions
  },
  getBlogFilters: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings.blog_filters
  },
  getBlogBackLabel: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings?.blog_literals ? settings.blog_literals.back_label : 'Back'
  },
  getBlogBackLink: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings?.blog_literals ? settings.blog_literals.back_link : false
  },
  getBlogTitle: (state) => (locale) => {
    const settings = state.find((item) => item.locale == locale)
    return settings?.blog_literals
      ? settings.blog_literals.title
      : 'Latest stories'
  },
  getBlogSortLabel: (state) => (locale, order) => {
    const settings = state.find((item) => item.locale == locale)
    return settings?.blog_literals
      ? settings.blog_literals[`sort_button_${order}`]
      : 'Most recent'
  },
}
