var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-animated-gradient" }, [
    _c("div", { ref: "canvas", staticClass: "canvas" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }