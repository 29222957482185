var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-challenges" },
    [
      _c(
        "Section",
        { attrs: { pretitle: _vm.pretitle, title: _vm.title } },
        [
          _c(
            "b-grid",
            _vm._l(_vm.currentItems, function (item, index) {
              return _c(
                "b-cell",
                { key: item.id, attrs: { xxs: "12", md: index <= 1 ? 6 : 4 } },
                [
                  _c(
                    "div",
                    { staticClass: "challenge-cell" },
                    [
                      _c("ChallengeCover", {
                        attrs: {
                          href: _vm.isFinalized(item) ? undefined : item.href,
                          image: item.featured_image,
                          caption: item.featured_image.caption,
                          title: item.title,
                          subtitle: _vm.formatChallengeStatusString(item),
                          description: item.description,
                          "on-click": _vm.isFinalized(item)
                            ? undefined
                            : _vm.onClick,
                        },
                        scopedSlots: _vm._u(
                          [
                            !!_vm.buttonText && !_vm.isFinalized(item)
                              ? {
                                  key: "cta",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "cta" },
                                        [
                                          _c(
                                            "Button",
                                            {
                                              attrs: {
                                                "on-click": _vm.isFinalized(
                                                  item
                                                )
                                                  ? undefined
                                                  : _vm.onClick,
                                                href: item.href,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.buttonText) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.items && _vm.paginationLimit && _vm.paginationTotal > 1
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("Pagination", {
                    attrs: {
                      "aria-prev": "prev",
                      "aria-next": "next",
                      "current-page": _vm.currentPage + 1,
                      total: _vm.paginationTotal,
                      "on-click": (i) => (this.currentPage = i - 1),
                      "on-prev-click": () =>
                        (this.currentPage = this.currentPage - 1),
                      "on-next-click": () =>
                        (this.currentPage = this.currentPage + 1),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }