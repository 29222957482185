export const state = () => ({
  selectedSeason: {},
  pillars: [],
  types: [],
  page_pointer: 0,
})

export const actions = {
  updateSeason({ commit }, data) {
    commit('updateSeason', data)
  },
  updateTypes({ commit }, data) {
    const { add, id } = data
    if (add) {
      commit('addType', id)
    } else {
      commit('removeType', id)
    }
  },
  updatePillars({ commit }, data) {
    const { add, id } = data
    if (add) {
      commit('addPillar', id)
    } else {
      commit('removePillar', id)
    }
  },
  clearFilters({ commit }) {
    commit('clearFilters')
  },
  pageUp({ commit }, data) {
    const { per_page } = data
    commit('pageUp', per_page)
  },
  pageDown({ commit }, data) {
    const { per_page } = data
    commit('pageDown', per_page)
  },
  pageJump({ commit }, data) {
    const { per_page } = data
    commit('pageJump', per_page)
  },
}

export const mutations = {
  updateSeason(state, season) {
    state.selectedSeason = season
  },
  addType(state, id) {
    state.types.push(id)
  },
  removeType(state, id) {
    state.types = state.types.filter((item) => item !== id)
  },
  addPillar(state, id) {
    state.pillars.push(id)
  },
  removePillar(state, id) {
    state.pillars = state.pillars.filter((item) => item !== id)
  },
  clearFilters(state) {
    state.pillars = []
    state.types = []
  },
  pageUp(state, per_page) {
    state.page_pointer += per_page
  },
  pageDown(state, per_page) {
    if (state.page_pointer > 0) {
      state.page_pointer -= per_page
    }
  },
  pageJump(state, per_page) {
    state.page_pointer = per_page
  },
}

export const getters = {
  getSeason: (state) => {
    return state.selectedSeason
  },
  getSelectedTypes: (state) => {
    return state.types
  },
  getSelectedPillars: (state) => {
    return state.pillars
  },
  getAppliedFiltersTotal: (state) => {
    return state.pillars.length + state.types.length
  },
  getPagePointer: (state) => {
    return state.page_pointer
  },
}
