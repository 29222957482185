export default {
  props: {
    title: {
      type: String,
    },
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
