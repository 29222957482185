var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-main-hero", attrs: { "data-background": "dark" } },
    [
      _c("b-wrapper", [
        _c("div", { staticClass: "content-section" }, [
          _c("div", { staticClass: "info-wrapper" }, [
            _vm.title
              ? _c("h1", { staticClass: "title" }, [
                  _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            this.titleContent
              ? _c("div", {
                  staticClass: "title",
                  domProps: { innerHTML: _vm._s(this.titleContent) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "main-cta" },
              [
                _c(
                  "Button",
                  {
                    attrs: {
                      href: _vm.button.href,
                      "on-click": _vm.onClick,
                      color: "transparent",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.button.label) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "hero-arrow-down" },
              [
                _c(
                  "ArrowButton",
                  {
                    attrs: {
                      "arrow-type": "vertical",
                      "on-click": () => {
                        _vm.scrollToContent()
                      },
                    },
                  },
                  [_vm._v("\n            Scroll down\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "media-content" }, [_c("AnimatedGradient")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }