import StateTime from './state-manager';

export default {
  props: {
    date: {
      type: String
    },
    time: {
      type: String
    },
    color: {
      type: String
    },
    highlightWhenNarrow: {
      type: Boolean,
    }
  }
};

export { StateTime };
