import Section from '../../components/containers/section';
import PillarIntro from '../../components/info/pillar-intro';

export default {
  components: {
    Section,
    PillarIntro,
  },
  data: () => ({
    fontSizeMultiplier: 0.43,
    lineHeightMultiplier: 1,
    letterSpacingMultiplier: 0.05,
    longestPillar: undefined,
    minSize: 0,
    maxSize: 200,
  }),
  props: {
    pretitle: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    hide_pre: {
      type: Boolean,
    },
    editions: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
    },
  },
  methods: {
    getTitle(edition) {
      const title = this.hide_pre ? edition.title : edition.partner;
      return `${title} · ${edition.year}`
    },
    onMouseEnter(edition) {
      this.$el.style.setProperty('--active-color', edition?.color);
    },
    resetPillar() {
      this.$el.style.setProperty('--active-color', null);
    },
    getLongestPillarLength() {
      return this.editions
        .map((edition) => this.getTitle(edition))
        .reduce((a, b) => a.length > b.length ? a : b)
        .length
    },
    fitText() {
      const fontSize =  this.$refs.wrapp.clientWidth / (this.fontSizeMultiplier * this.longestPillar);
      const paddedFontSize = Math.min(Math.max(fontSize, this.minSize), this.maxSize);

      this.$refs.pillar.forEach((pillar) => {
        const $pillar = pillar.$el.querySelector('.title');
        $pillar.style.fontSize = paddedFontSize + 'px';
        $pillar.style.lineHeight = paddedFontSize * this.lineHeightMultiplier + 'px';
        $pillar.style.letterSpacing = -paddedFontSize * this.letterSpacingMultiplier + 'px';
      });
    }
  },

  mounted() {
    if (typeof window !== 'undefined') {
      this.longestPillar = this.getLongestPillarLength();
      setTimeout(() => this.fitText(), 200);
      window.addEventListener('resize', this.fitText);
    }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.fitText);
    }
  },
};
