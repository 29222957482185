var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-section",
      attrs: {
        "data-spacing": _vm.spacing,
        "data-background": _vm.background,
        "data-with-border-top": _vm.withBorderTop,
        "data-pretitle-color": _vm.pretitleColor,
        "data-pretitle-align": _vm.pretitleAlign,
        "data-heading-align": _vm.headingAlign,
        "data-is-sticky": _vm.stickyHeader,
      },
    },
    [
      _c(
        "div",
        { staticClass: "heading" },
        [
          _c("b-wrapper", [
            _c("div", { staticClass: "heading-wrapper" }, [
              _vm.pretitle || _vm.hasTags
                ? _c("div", { staticClass: "pretitle-wrapper" }, [
                    _vm.pretitle
                      ? _c("p", { staticClass: "pretitle" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.pretitle) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasTags
                      ? _c("div", { staticClass: "tags" }, [_vm._t("tags")], 2)
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.date
                ? _c("p", { staticClass: "date" }, [
                    _vm._v("\n          " + _vm._s(_vm.date) + "\n        "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.subtitle
                ? _c("p", { staticClass: "subtitle" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.subtitle) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldRenderHeading
                ? _c("div", { staticClass: "header-container" }, [
                    _c(
                      "div",
                      { staticClass: "header-texts" },
                      [
                        _vm.stickyHeader
                          ? _c("StickyElement", [
                              _vm.title || _vm.hasDropdown
                                ? _c(
                                    "div",
                                    { staticClass: "title-container" },
                                    [
                                      _vm.title
                                        ? _c(
                                            _vm.headline,
                                            {
                                              tag: "component",
                                              staticClass: "title",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.title) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasDropdown
                                        ? _c(
                                            "span",
                                            { staticClass: "dropdown" },
                                            [_vm._t("dropdown")],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm.title || _vm.hasDropdown
                          ? _c(
                              "div",
                              { staticClass: "title-container" },
                              [
                                _vm.title
                                  ? _c(
                                      _vm.headline,
                                      {
                                        tag: "component",
                                        staticClass: "title",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.title) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasDropdown
                                  ? _c(
                                      "span",
                                      { staticClass: "dropdown" },
                                      [_vm._t("dropdown")],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.text
                          ? _c("p", { staticClass: "text" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.text) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasCta
                      ? _c("div", { staticClass: "cta" }, [_vm._t("cta")], 2)
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("b-wrapper", { attrs: { type: _vm.wrapperType } }, [
            _c(
              "div",
              { staticClass: "content-wrapper" },
              [_vm._t("default")],
              2
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.withBorderTop
        ? _c("div", { staticClass: "hr" }, [
            _c("span", { staticClass: "border" }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }