var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "filteredEvents",
      staticClass: "m-filtered-events",
      attrs: { "data-open": _vm.showModal },
    },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            "heading-align": "flex",
          },
          scopedSlots: _vm._u(
            [
              _vm.filters &&
              _vm.filters.seasons &&
              _vm.filters.seasons.length > 0 &&
              _vm.seasonsDropdown
                ? {
                    key: "dropdown",
                    fn: function () {
                      return [
                        _vm.filters.seasons
                          ? _c("SelectDropdown", {
                              attrs: {
                                options: _vm.filters.seasons,
                                "select-option": _vm.onSelectSeason,
                                selected: _vm.selectedSeason,
                                label: _vm.accessibilitySeasonLabel,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm.filters &&
              _vm.filters.pillars &&
              _vm.filters.pillars.length > 0 &&
              _vm.filterButton
                ? {
                    key: "cta",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "icon-filter-wrapper",
                            on: { click: _vm.onModalOpen },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "filters-button" },
                              [
                                _c("FilterIcon"),
                                _vm._v(" "),
                                !!_vm.appliedLength
                                  ? _c(
                                      "span",
                                      { staticClass: "filter-count" },
                                      [_vm._v(_vm._s(_vm.appliedLength))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "filter-button-label" },
                                  [_vm._v(_vm._s(_vm.filterButtonLabel))]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm.filters && _vm.filters.pillars && _vm.filters.pillars.length > 0
            ? _c("Modal", {
                staticClass: "filters-modal",
                attrs: {
                  "close-label": _vm.closeModalLabel,
                  "wrapper-type": "header",
                  show: _vm.showModal,
                  "on-close": _vm.onModalClose,
                  "aria-live": "polite",
                },
                scopedSlots: _vm._u(
                  [
                    _vm.filters.title
                      ? {
                          key: "title",
                          fn: function () {
                            return [
                              _c("SimpleTitle", {
                                attrs: {
                                  text: _vm.filters.title,
                                  color: "white",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    _vm.filters.season_label
                      ? {
                          key: "season-heading",
                          fn: function () {
                            return [
                              _c("SimpleTitle", {
                                attrs: {
                                  text: _vm.filters.season_label,
                                  color: "white",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "season-list",
                      fn: function () {
                        return [
                          _c(
                            "ul",
                            { staticClass: "filter-list" },
                            _vm._l(_vm.filters.seasons, function (season) {
                              return _c(
                                "li",
                                {
                                  key: season.id,
                                  staticClass: "filter-element",
                                },
                                [
                                  _c("InputFilter", {
                                    attrs: {
                                      type: "radio",
                                      name: "season",
                                      label: `${season.title} ${season.details}`,
                                      id: `season-${season.id}`,
                                      "on-change": _vm.onFilterChange,
                                      checked:
                                        season.id == _vm.currentSeason.id,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.filters.pillars_filter_by_label
                      ? {
                          key: "pillar-heading",
                          fn: function () {
                            return [
                              _c("SimpleTitle", {
                                attrs: {
                                  text: _vm.filters.pillars_filter_by_label.toUpperCase(),
                                  color: "white",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "pillars-list",
                      fn: function () {
                        return [
                          _c(
                            "ul",
                            { staticClass: "filter-list" },
                            _vm._l(
                              _vm.filters.pillars,
                              function (pillarFilter) {
                                return _c(
                                  "li",
                                  {
                                    key: pillarFilter.id,
                                    staticClass: "filter-element",
                                  },
                                  [
                                    _c("InputFilter", {
                                      attrs: {
                                        label: pillarFilter.title,
                                        id: `pillar-${pillarFilter.id}`,
                                        "on-change": _vm.onFilterChange,
                                        checked: _vm.isSelected(
                                          pillarFilter.id,
                                          _vm.selectedPillarsFilters
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "types-heading",
                      fn: function () {
                        return [
                          _c("SimpleTitle", {
                            attrs: {
                              text: _vm.filters.types_filter_by_label.toUpperCase(),
                              color: "white",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.filters &&
                    _vm.filters.event_types &&
                    _vm.filters.event_types.length > 0
                      ? {
                          key: "types-list",
                          fn: function () {
                            return [
                              _c(
                                "ul",
                                { staticClass: "filter-list" },
                                _vm._l(
                                  _vm.filters.event_types,
                                  function (typeFilter) {
                                    return _c(
                                      "li",
                                      {
                                        key: typeFilter.id,
                                        staticClass: "filter-element",
                                      },
                                      [
                                        _c("InputFilter", {
                                          attrs: {
                                            label: typeFilter.title,
                                            id: `type-${typeFilter.id}`,
                                            "on-change": _vm.onFilterChange,
                                            checked: _vm.isSelected(
                                              typeFilter.id,
                                              _vm.selectedTypesFilters
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "first-cta",
                      fn: function () {
                        return [
                          _c(
                            "Button",
                            {
                              attrs: {
                                "on-click": _vm.onResetFilters,
                                disabled: _vm.buttonDisabled,
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.filters.reset_button_label) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "second-cta",
                      fn: function () {
                        return [
                          _c(
                            "Button",
                            {
                              attrs: {
                                "on-click": _vm.onApplySubmit,
                                type: "submit",
                                color: "sunrise",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.filters.apply_button_label +
                                      " " +
                                      "(" +
                                      _vm.appliedLength +
                                      ")"
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.items && _vm.items.events && _vm.items.events.length > 0
            ? _c(
                "div",
                { staticClass: "items-wrapper" },
                _vm._l(_vm.items.events, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "grid-item" },
                    [
                      _c("EventCardCover", {
                        attrs: {
                          href: _vm.clickable ? item.url : null,
                          images: item.featured_image,
                          date: _vm.getDate(item.start_date, item),
                          title: item.title,
                          alt: item.featured_image
                            ? item.featured_image.alternativeText
                            : "",
                          caption: item.featured_image
                            ? item.featured_image.caption
                            : "",
                          "on-click":
                            _vm.clickable & item.url ? _vm.onClick : null,
                        },
                        scopedSlots: _vm._u(
                          [
                            item.location && item.location.location
                              ? {
                                  key: "location",
                                  fn: function () {
                                    return [
                                      _c("LocationTag", {
                                        attrs: {
                                          location: item.location.location,
                                          href: item.location.map_url,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                            item.pillars || item.event_types
                              ? {
                                  key: "tags",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "tags" },
                                        [
                                          item.pillars
                                            ? _vm._l(
                                                item.pillars,
                                                function (pillar, idx) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: `pillar-${idx}`,
                                                      staticClass: "tag",
                                                    },
                                                    [
                                                      _c(
                                                        "Tag",
                                                        {
                                                          attrs: {
                                                            color: pillar.color,
                                                            "no-border": true,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                pillar.title
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.event_types
                                            ? _vm._l(
                                                item.event_types,
                                                function (type, idx) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: `type-${idx}`,
                                                      staticClass: "tag",
                                                    },
                                                    [
                                                      _c(
                                                        "Tag",
                                                        {
                                                          attrs: { type: true },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                type.title
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : !_vm.isLoading && _vm.empty
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("EmptyMessage", {
                    attrs: { title: _vm.empty.title, text: _vm.empty.text },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.items &&
          _vm.items.pagination &&
          _vm.items.pagination.last_page >= 2
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("Pagination", {
                    attrs: {
                      "current-page": _vm.items.pagination.current_page
                        ? _vm.items.pagination.current_page
                        : 1,
                      total: _vm.items.pagination.last_page,
                      href: _vm.pageHref,
                      "on-click": (index) => _vm.onPaginationClick(index),
                      "on-prev-click": () => _vm.onPaginationClick("prev"),
                      "on-next-click": () => _vm.onPaginationClick("next"),
                      "aria-prev": _vm.ariaPrev,
                      "aria-next": _vm.ariaNext,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }