var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-button",
      attrs: {
        "data-color": _vm.color,
        "data-size": _vm.size,
        "data-disabled": _vm.disabled,
        "data-text-size": _vm.textSize,
        "data-type": _vm.type,
      },
    },
    [
      _c(
        "b-clickable",
        { ref: "clickable", attrs: { type: _vm.type, href: _vm.href } },
        [
          _c(
            "span",
            { staticClass: "container" },
            [
              _vm.type == "link"
                ? [_c("span", { staticClass: "text" }, [_vm._t("default")], 2)]
                : [_vm._t("default")],
              _vm._v(" "),
              _vm.icon && _vm.iconElement
                ? _c(
                    "span",
                    { staticClass: "icon" },
                    [
                      _c(_vm.iconElement, {
                        tag: "component",
                        attrs: { role: "presentation" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }