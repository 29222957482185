var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-stories-grid" },
    [
      _c(
        "Section",
        {
          attrs: {
            pretitle: _vm.pretitle,
            title: _vm.title,
            text: _vm.text,
            "with-border-top": "",
          },
        },
        [
          _c(
            "b-grid",
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "b-cell",
                _vm._b(
                  { key: item.id },
                  "b-cell",
                  _vm.getCellSize(index),
                  false
                ),
                [
                  _c(
                    "div",
                    { staticClass: "story-cell" },
                    [
                      _c("CardCover", {
                        attrs: {
                          images: item.featured_image && item.featured_image,
                          alt:
                            item.featured_image &&
                            item.featured_image.alternativeText,
                          title: item.title,
                          description: item.description,
                          href: item.file && item.file.url,
                          "on-click": item.file && item.file.url && _vm.onClick,
                          "image-ratio": _vm.getImageRatio(index),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }