<template>
  <div class="nuxt-error">
    <component :is="errorPage" :error="settings" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import error404 from '@/components/error/404.vue'
import error500 from '@/components/error/500.vue'

export default {
  name: 'nuxt-error',
  layout: 'default',
  components: {},
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      settingsByLocale: 'settings/settingsByLocale',
    }),
    settings() {
      const { locale } = this.$i18n
      const settings = this.settingsByLocale(locale)
      return settings
    },
    errorPage() {
      if (this.error.statusCode === 404) {
        return error404
      }
      // catch everything else
      return error500
    },
  },
}
</script>
