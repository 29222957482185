import Section from '../../components/containers/section';
import SimpleTitle from '../../components/info/simple-title';
import EventCardCover from '../../components/covers/event-card-cover';
import InputFilter from '../../components/fields/input-filter';
import FilterIcon from '../../components/cta/icon-button/assets/filters-embedded.svg';
import Button from '../../components/cta/button';
import Modal from '../../components/containers/modal';
import SelectDropdown from '../../components/fields/select-dropdown';
import LocationTag from '../../components/info/location-tag';
import Tag from '../../components/info/tag';
import Pagination from '../../components/nav/pagination';
import EmptyMessage from '../../components/info/empty-message';
import dateFormat from '../../mixins/date-format';

export default {
  mixins: [dateFormat],
  components: {
    Section,
    EventCardCover,
    LocationTag,
    Tag,
    SimpleTitle,
    InputFilter,
    Button,
    FilterIcon,
    Modal,
    SelectDropdown,
    Pagination,
    EmptyMessage,
  },
  props: {
    items: {
      type: Object,
    },
    seasons: {
      type: Array,
    },
    seasonsDropdown: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
    },
    filterButton: {
      type: Boolean,
      default: true
    },
    filterButtonLabel: {
      type: String,
      default: "Filters"
    },
    filteredButtonLabel: {
      type: String,
      default: "Filtered Elements"
    },
    pretitle: {
      type: String,
    },
    title: {
      type: String,
    },
    accessibilitySeasonLabel: {
      type: String,
    },
    perPage: {
      type: Number,
      default: 11,
    },
    onFilterChange: {
      type: Function,
    },
    onPaginationClick: {
      type: Function,
    },
    onClick: {
      type: Function,
    },
    onResetFilters: {
      type: Function,
    },
    onApplyFilters: {
      type: Function,
    },
    buttonDisabled: {
      type: Boolean
    },
    appliedLength: {
      type: Number,
      default: 0
    },
    openModalLabel: {
      type: String,
    },
    closeModalLabel: {
      type: String,
    },
    pillarsFilterLabel: {
      type: String,
    },
    typesFilterLabel: {
      type: String,
    },
    itemsToShow: {
      type: Number,
      default: 11,
    },
    pageHref: {
      type: String,
    },
    options: {
      type: Array,
    },
    onSelectSeason: {
      type: Function,
    },
    selectedSeason: {
      type: Object,
    },
    checked: {
      type: Boolean,
    },
    total: {
      type: Number,
    },
    selectedPillarsFilters: {
      type: Array,
    },
    selectedTypesFilters: {
      type: Array,
    },
    ariaPrev: {
      type: String,
    },
    ariaNext: {
      type: String,
    },
    numFeaturedEvents: {
      type: Number,
      default: 2,
    },
    empty: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    display_featured: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
      currentSeason: this.selectedSeason
    }
  },
  methods: {
    async onModalClose() {
      this.showModal = false;
      await this.$nextTick();
    },
    async onModalOpen() {
      this.showModal = true;
      await this.$nextTick();
    },
    async onApplySubmit() {
      this.onApplyFilters()
      this.showModal = false;
    },
    featuredItem(id) {
      if (!this.display_featured) return false;
      const featuredItems = this.items.events.slice(0, this.numFeaturedEvents);
      return featuredItems.find(option => option.id === id);
    },
    gridSize(index) {
      if (index == 2) return 8
      if (index == 3) return 4
      if (index == 6) return 12
      return 6
    },
    isSelected(id, selected) {
      return !!selected.find(item => Number(item) === Number(id));
    },
    isSeasonSelected(id, selected) {
      return Number(selected.id) === Number(id)
    },
    scrollPaginationIntoView() {
      this.$refs.filteredEvents?.scrollIntoView(false);
    }
  }
};
