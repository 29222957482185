import Section from '../../components/containers/section';
import Pretitle from '../../components/info/pretitle';
import Pagination from '../../components/nav/pagination';

export default {
  components: {
    Section,
    Pretitle,
    Pagination,
  },

  data() {
    return {
      currentPage: 1,
      timerId: null,
      desktopModule: 45,
      mobileModule: 15,
    }
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    pretitle: {
      type: String,
    },
    ariaPrev: {
      type: String,
    },
    ariaNext: {
      type: String,
    },
    organizations: {
      type: Array
    }
  },

  computed: {
    currentStartCount() {
      // calc counter value
      return ((this.currentPage - 1) * this.moduleValue()) + 1;
    }
  },

  methods: {
    // returns visibility for index element based on current page
    isCurrentVisible(index) {
      const minor = this.currentStartCount - 1;
      const major = minor + this.moduleValue();

      return (index >= minor) && (index < major);
    },

    updateNumbers() {
      // debounced update
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.$forceUpdate();
        this.currentPage = 1;
      }, 250);
    },

    totalPages() {
      // calculate the page totals based on items per page
      return Math.ceil(this.organizations.length / this.moduleValue());
    },

    isDesktop() {
      if (typeof window !== 'undefined') {
        return window.matchMedia('(min-width: 768px)').matches;
      }

      return false;
    },

    moduleValue() {
      // return the number of items per page based on breakpoint
      return this.isDesktop()? this.desktopModule : this.mobileModule;
    },

    onNextClick() {
      if (this.currentPage < this.totalPages()) {
        this.currentPage += 1;
        this.$forceUpdate();
      }
    },

    onPrevClick() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.$forceUpdate();
      }
    }
  },

  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.updateNumbers);
    }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateNumbers);
    }
  },
};
