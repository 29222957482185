var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-location-tag" },
    [
      _c(
        _vm.href ? "a" : "div",
        {
          tag: "component",
          staticClass: "location-tag",
          attrs: { href: _vm.href, target: "_blank" },
        },
        [
          _c("LocationIcon"),
          _vm._v(" "),
          _c("span", { staticClass: "location-tag-text" }, [
            _vm._v("\n      " + _vm._s(_vm.location) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.details
        ? _c("div", { staticClass: "location-tag-details" }, [
            _vm._v("\n    " + _vm._s(_vm.details) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }