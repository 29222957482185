var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-tag",
      attrs: {
        "data-time": _vm.time,
        "data-type": _vm.type,
        "data-no-border": _vm.noBorder,
      },
    },
    [_c("p", [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }