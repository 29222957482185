var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "m-resources-list",
      attrs: { "data-has-heading": _vm.hasHeading },
    },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            "with-border-top": "",
          },
        },
        [
          _c(
            "ul",
            { staticClass: "resources" },
            _vm._l(_vm.resources, function (resource) {
              return _c(
                "li",
                { key: resource.id, staticClass: "resource" },
                [
                  _c("Download", {
                    attrs: {
                      label: resource.name,
                      size: resource.size,
                      href: resource.url,
                      extension: resource.ext,
                      "on-click": _vm.onClick,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }