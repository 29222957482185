import YoutubeIcon from './assets/youtube-embedded.svg';
import LinkedInIcon from './assets/linkedin-embedded.svg';
import TwitterIcon from './assets/twitter-embedded.svg';
import FacebookIcon from './assets/facebook-embedded.svg';
import InstagramIcon from './assets/instagram-embedded.svg';

export default {
  components: {
    YoutubeIcon,
    LinkedInIcon,
    TwitterIcon,
    FacebookIcon,
    InstagramIcon
  },
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
    href: {
      type: String,
    },
    onClick: {
      type: Function,
    },
    label: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
