var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "cookies-overlay" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-cookies",
        attrs: { role: "dialog", "aria-labelledby": "title" },
      },
      [
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cta", attrs: { "aria-label": "Close dialog" } },
            [_vm._t("cta")],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }