import ArrowIcon from './assets/arrow-embedded.svg';

export default {
  components: {
    ArrowIcon,
  },
  props: {
    label: {
      type: String,
    },
    href: {
      type: String,
    },
    size: {
      type: Number,
    },
    extension: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },
  computed: {
    details() {
      return `${this.formatExtension(this.extension)} - ${this.formatBytes(this.size)}`;
    },
  },
  methods: {
    formatBytes(size, decimals = 0) {
      if (size == 0) return undefined;

      const sizesMap = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(size) / Math.log(1024));

      return parseFloat((size / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizesMap[i];
    },
    formatExtension(extension) {
      if (!extension) return undefined;

      return extension.slice(1).toUpperCase();
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  },
};
