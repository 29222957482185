import Section from '../../components/containers/section';
import CardCover from '../../components/covers/card-cover';
import rightToLeft from '../../mixins/right-to-left';

export default {
  mixins: [rightToLeft],
  components: {
    Section,
    CardCover,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    items: {
      type: Array,
      required: true
    },
    onClick: {
      type: Function,
    }
  },
};
