import ImageComponent from "../../media/image";

export default {
  components: {
    ImageComponent
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    src: {
      type: String
    },
    image: {
      type: Object
    },
    alt: {
      type: String
    },
    caption: {
      type: String
    },
    description: {
      type: String
    },
    href: {
      type: String,
      default: null
    },
    onClick: {
      type: Function
    }
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
    this.$refs.image.onClick = this.onClick;
  },
  computed: {
    hasCta() {
      return !!this.$slots.cta;
    }
  }
};
