var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-time",
      attrs: {
        "data-color": _vm.color,
        "data-highlight-when-narrow": _vm.highlightWhenNarrow,
      },
    },
    [
      _c("span", { staticClass: "date-label" }, [
        _vm._v(_vm._s(_vm.date && _vm.date.replace(",", ""))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "time-label" }, [_vm._v(_vm._s(_vm.time))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }