var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-select-dropdown",
      attrs: { "data-opened": _vm.opened, "data-color": _vm.color },
      on: {
        "!blur": function ($event) {
          return _vm.handleBlur.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "button",
        {
          ref: "button",
          staticClass: "default-option",
          attrs: {
            "aria-haspopup": "listbox",
            "aria-expanded": _vm.opened.toString(),
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return (() => _vm.onToggle && _vm.onToggle()).apply(
                null,
                arguments
              )
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, [
                  "Up",
                  "ArrowUp",
                ]) &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.showOptions.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { staticClass: "dropdown-content" }, [
            _c("span", { staticClass: "header-content" }, [
              _c("span", { staticClass: "header-text" }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "header-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.selected
                        ? _vm.selected.title
                        : _vm.defaultOption.title
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "arrow" },
                [
                  _c("Arrow", {
                    staticClass: "arrow-desktop",
                    attrs: { "aria-hidden": "true", focusable: "false" },
                  }),
                  _vm._v(" "),
                  _c("ArrowMobile", {
                    staticClass: "arrow-mobile",
                    attrs: { "aria-hidden": "true", focusable: "false" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("label", { attrs: { id: "label" } }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      !!_vm.options
        ? _c(
            "ul",
            {
              ref: "options",
              staticClass: "options",
              attrs: {
                "data-opened": _vm.opened,
                role: "listbox",
                "aria-labelledby": "label",
              },
            },
            _vm._l(_vm.options, function (option) {
              return _c(
                "li",
                {
                  key: option.id || option.url,
                  staticClass: "option",
                  attrs: {
                    role: "option",
                    "data-selected":
                      _vm.selected && option.id === _vm.selected.id,
                  },
                },
                [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.selectOption(option.id, option)
                          _vm.reset()
                        },
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.selectOption(option.id)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp",
                              ]) &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown",
                              ])
                            )
                              return null
                            $event.preventDefault()
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              ) &&
                              _vm._k($event.keyCode, "esc", 27, $event.key, [
                                "Esc",
                                "Escape",
                              ])
                            )
                              return null
                            $event.preventDefault()
                            return _vm.reset.apply(null, arguments)
                          },
                        ],
                      },
                    },
                    [
                      _c("span", { staticClass: "option-content" }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(option.title) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        option.details
                          ? _c("span", { staticClass: "title-details" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(option.details) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }