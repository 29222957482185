import ImageComponent from "../../media/image"
import ArrowDown from "./assets/arrow-down-embedded.svg"

export default {
  components: {
    ArrowDown,
    ImageComponent
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    seeAll: {
      type: String,
      default: 'See all'
    }
  },
  data() {
    return {
      hover: false,
      image: this.item.drop_down[0].featured_image ? this.item.drop_down[0].featured_image : {},
      nextImage: "",
      mobile: false,
    }
  },
  methods: {
    getCurrentImage(index) {
      this.image = this.item.drop_down[index].featured_image ? this.item.drop_down[index].featured_image : {}
    },
    displayContent(mobile) {
      this.hover = true
      if (mobile) {
        if (this.$refs.overlay.style.maxHeight) {
          this.$refs.overlay.style.maxHeight = null;
        } else {
          this.$refs.overlay.style.maxHeight = this.$refs.overlay.scrollHeight + "px";
        }
      } else {
        this.$emit('dropdown', true)
        if (window && window.innerWidth >= 1020) {
          this.$refs.overlay.style.visibility = "visible"
          this.$refs.overlay.style.opacity = "1"
          this.$refs.content.style.visibility = "visible";
          this.$refs.content.style.opacity = "1";
        }
      }
    },
    hideContent() {
      this.hover = false
      this.$emit('dropdown', false)
      setTimeout(() => {
        if (this.hover) {
          return
        }
        this.$refs.overlay.style = {}
        this.$refs.content.style = {}
        this.image = this.item.drop_down[0].featured_image ? this.item.drop_down[0].featured_image : {};
      }, 300);
    },
  }
}