var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-dot-label", attrs: { "data-color": _vm.color } },
    [
      _c("span", { staticClass: "dot" }),
      _vm._v(" "),
      _c("span", { staticClass: "label" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }