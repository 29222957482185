const axios = require('axios')

export async function generateRoutes() {
  /**
   * Append pages collection to sitemap
   */
  const { data: pages } = await axios.get(
    `${process.env.API_ENDPOINT}/pages?_locale=all`
  )

  const pagesUrls = pages.map((page) => {
    if (page.locale !== 'en') {
      if(page.slug === 'home'){
        return {
          name: `${page.slug}___${page.locale}`,
          url: `/${page.locale}`,
          path: `/${page.locale}`,
          chunkName: `pages/${page.slug}`,
        }
      }

      return {
        name: `${page.slug}___${page.locale}`,
        url: `/${page.locale}/${page.slug}`,
        path: `/${page.locale}/${page.slug}`,
        chunkName: `pages/${page.slug}`,
      }
    }

    if(page.slug === 'home'){
      return {
        name: `${page.slug}___${page.locale}`,
        url: `/`,
        path: `/`,
        chunkName: `pages/${page.slug}`,
      }
    }

    return {
      name: `${page.slug}___${page.locale}`,
      url: `/${page.slug}`,
      path: `/${page.slug}`,
      chunkName: `pages/${page.slug}`,
    }
  })
  /**
   *    Append events collection to sitemap
   */
  const {
    data: { events },
  } = await axios.get(`${process.env.API_ENDPOINT}/events?_locale=all&_limit=5000`)

  const eventsUrls = events.map((event) => {
    return {
      name: `${event.slug}___${event.locale}`,
      url: event.url,
      path: `/${event.slug}`,
      chunkName: `events/${event.slug}`,
    }
  })
  /**
   * Append past editions collection to sitemap
   */
  const { data: past } = await axios.get(
    `${process.env.API_ENDPOINT}/past-editions?_locale=all`
  )

  const pastEditionsUrls = past.map((item) => {
    if (item.locale !== 'en') {
      return {
        name: `${item.slug}___${item.locale}`,
        url: `/${item.locale}/past-editions/${item.slug}`,
        path: `/${item.locale}/${item.slug}`,
        chunkName: `past-editions/${item.slug}`,
      }
    }
    return {
      name: `${item.slug}___${item.locale}`,
      url: `/past-editions/${item.slug}`,
      path: `/${item.slug}`,
      chunkName: `past-editions/${item.slug}`,
    }
  })
  /**
   * Append posts collection to sitemap
   */
  const { data: posts } = await axios.get(
    `${process.env.API_ENDPOINT}/posts?_locale=all`
  )

  const postsUrls = posts.map((item) => {
    if (item.locale !== 'en') {
      return {
        name: `${item.slug}___${item.locale}`,
        url: `/${item.locale}/posts/${item.slug}`,
        path: `/${item.locale}/${item.slug}`,
        chunkName: `posts/${item.slug}`,
      }
    }
    return {
      name: `${item.slug}___${item.locale}`,
      url: `/posts/${item.slug}`,
      path: `/${item.slug}`,
      chunkName: `posts/${item.slug}`,
    }
  })

  // return [...pagesUrls, ...eventsUrls, ...pastEditionsUrls, ...postsUrls]
  return { staticRoutes: [...pagesUrls, ...pastEditionsUrls], events: [...eventsUrls], posts: [...postsUrls] }
}
