var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-agenda" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            date: _vm.date,
            "with-border-top": "",
            "wrapper-type": "full",
            "heading-align": "flex",
          },
          scopedSlots: _vm._u(
            [
              _vm.label
                ? {
                    key: "cta",
                    fn: function () {
                      return [
                        _c("IconMessage", {
                          attrs: { text: _vm.label, icon: "drag" },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm.hasDayContents
            ? _c(
                "b-slider",
                {
                  ref: "slider",
                  attrs: {
                    "use-grid": "false",
                    "right-to-left": _vm.rightToLeft,
                    "cell-align": _vm.cellAlign,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "agenda-slide" },
                    _vm._l(_vm.days, function (day, index) {
                      return _c(
                        "div",
                        { key: `day-${index}`, staticClass: "agenda-slot" },
                        [
                          _c("AgendaCover", {
                            attrs: {
                              "day-title": day.title,
                              background: _vm.defineBackground(index),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.agendaSlots, function (agendaSlot, index) {
                    return _c(
                      "div",
                      {
                        key: `agenda-slide-${index}`,
                        staticClass: "agenda-slide",
                      },
                      _vm._l(agendaSlot, function (slot, indexSlot) {
                        return _c(
                          "div",
                          {
                            key: `slot-${indexSlot}`,
                            staticClass: "agenda-slot",
                          },
                          [
                            _c(
                              "AgendaCover",
                              _vm._b(
                                {
                                  attrs: {
                                    background: _vm.defineBackground(indexSlot),
                                  },
                                },
                                "AgendaCover",
                                _vm.agendaCoverProps(slot),
                                false
                              )
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }