export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    onChange: {
      type: Function
    },
    type: {
      type: String,
      default: 'checkbox'
    }
  },
};
