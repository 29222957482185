const injectMeta = ({ seo: meta, $route, $store, $i18n }) => {
  // if not page meta, get it from the settings page
  if (!meta) {
    const { locale } = $i18n
    meta = $store.getters['settings/getSeo'](locale)
  }

  if (!meta) {
    return {}
  }

  return {
    title: meta?.title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: meta?.description,
      },
      {
        hid: 'image',
        name: 'image',
        content: meta?.image?.formats.small.url,
      },
      {
        hid: 'author',
        name: 'author',
        content: meta?.author,
      },
      // OG Facebook
      {
        hid: 'og:title',
        name: 'og:title',
        content: meta?.title,
      },
      {
        hid: 'og:type',
        name: 'og:type',
        content: meta?.content_type,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: meta?.image?.formats.small.url,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: meta?.description,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: $route.path,
      },
      // Twitter
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: meta?.title,
      },
      {
        hid: 'twitter:type',
        name: 'twitter:type',
        content: meta?.content_type,
      },
      {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: meta?.image?.formats.small.url,
      },
      {
        hid: 'twitter:description',
        property: 'twitter:description',
        content: meta?.description,
      },
      {
        hid: 'twitter:url',
        property: 'twitter:url',
        content: $route.path,
      },
      {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: `summary_large_image`,
      },
    ],
  }
}

export default injectMeta
