export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    }
  },
};
