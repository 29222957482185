var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-footer", attrs: { "data-background": "dark" } },
    [
      _c(
        "div",
        { staticClass: "navigation-bar" },
        [
          _c("b-wrapper", [
            _c(
              "div",
              { staticClass: "navigation-bar-wrapper" },
              [
                _c("Logo", { attrs: { size: "lg" } }, [
                  _vm._v("\n          " + _vm._s(_vm.logoLabel) + "\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "menu" },
                  [
                    _c("SimpleMenu", {
                      attrs: {
                        links: _vm.links,
                        "on-click": _vm.onClick,
                        "spaced-out": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bottom-wrapper" }, [
                  _vm.socialButtons
                    ? _c(
                        "div",
                        { staticClass: "social-buttons" },
                        _vm._l(_vm.socialButtons, function (socialButton) {
                          return _c(
                            "div",
                            {
                              key: socialButton.text,
                              staticClass: "social-button",
                            },
                            [
                              _c(
                                "SocialButton",
                                _vm._b(
                                  { attrs: { "on-click": _vm.onClick } },
                                  "SocialButton",
                                  socialButton,
                                  false
                                )
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.copyright
                    ? _c("div", { staticClass: "copyright" }, [
                        _c("div", { staticClass: "copyright-wrapper" }, [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.copyright) + " "),
                          ]),
                          _vm._v(" "),
                          _vm.copyrightHighlighted
                            ? _c("span", { staticClass: "highlighted" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.copyrightHighlighted) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }