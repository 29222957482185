var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Error", {
    attrs: {
      message: _vm.error.page_not_found.title,
      subtitle: _vm.error.page_not_found.subtitle,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }