var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-partners", attrs: { "data-background": _vm.background } },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            spacing: "lg",
            "with-border-top": "",
          },
        },
        [
          _c("div", { staticClass: "section-contents" }, [
            !!_vm.description || !!_vm.featuredText
              ? _c(
                  "div",
                  { staticClass: "section-description" },
                  [
                    _c(
                      "b-grid",
                      [
                        _c("b-cell", { attrs: { xxs: "12", md: "5" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.description) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-cell", { attrs: { xxs: "12", md: "7" } }, [
                          _c(
                            "div",
                            { staticClass: "content-cta" },
                            [
                              !!_vm.featuredText
                                ? _c(
                                    "DotLabel",
                                    { attrs: { color: "sunrise" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.featuredText) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : !!_vm.button && _vm.button.type === "regular"
                                ? _c(
                                    "Button",
                                    {
                                      attrs: {
                                        href: _vm.button.href,
                                        "on-click": _vm.onClick,
                                        size: "large",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.button.label) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!_vm.sponsors
              ? _c(
                  "div",
                  { staticClass: "sponsors-list" },
                  _vm._l(_vm.sponsors, function (sponsor, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "sponsor-block" },
                      [
                        _c("SponsorCover", {
                          attrs: {
                            href: "",
                            image: sponsor.featured_image,
                            featured: sponsor.featured,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }