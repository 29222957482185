import CardCover from '../../components/covers/card-cover';
import RichText from "../../components/article/rich-text";
import Modal from '../../components/containers/modal';
import Section from '../../components/containers/section';

export default {
  components: {
    CardCover,
    RichText,
    Modal,
    Section,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    items: {
      type: Array,
      required: true
    },
    cellsPerRow: {
      type: Number,
      default: 3,
    },
    imagesRatio: {
      type: String,
      default: '16/9',
    }
  },
  data: () => ({
    showModal: false,
    modalContent: {
      heading: {
        title: '',
        description: '',
        image: {
          url: null,
          alternativeText: '',
          caption: '',
        },
      },
      content: null,
    },
  }),
  computed: {
    cellSize() {
      return {
        xxs: 12,
        md: 6,
        lg: 12 / this.cellsPerRow,
      }
    }
  },
  methods: {
    onCloseModal() {
      this.showModal = false;
    },

    onClick(item) {
      this.showModal = true;
      this.modalContent = {
        heading: {
          title: item.title,
          description: item.description,
          image: item.image,
        },
        content: item.content,
      };
    },
  },
};
