import Section from '../../components/containers/section';
import ContactCover from '../../components/covers/contact-cover';
import rightToLeft from '../../mixins/right-to-left';

export default {
  mixins: [rightToLeft],
  components: {
    Section,
    ContactCover,
  },
  props: {
    title: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    items: {
      type: Array,
      required: true
    },
    onClick: {
      type: Function,
    },
    cellsPerRow: {
      type: Number,
      default: 2,
    },
    type: {
      type: String,
      default: 'grid',
    },
  },
  computed: {
    wrapperType() {
      return this.type === 'slider' ? "full" : "default";
    },
    cellWrapper() {
      return this.type === 'slider' ? 'b-slider' : 'b-grid';
    },
    wrapperProps() {
      return this.type === 'slider' ? {
        arrows: true,
        'right-to-left': this.rightToLeft,
        'cell-align': this.cellAlign,
      } : {};
    },
    cellSize() {
      return {
        xxs: 12,
        md: 6,
        lg: 12 / this.cellsPerRow,
      }
    }
  }
};
