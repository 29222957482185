var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-event-stats" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            "with-border-top": "",
          },
        },
        [
          _c("div", { staticClass: "section-contents" }, [
            _c(
              "div",
              { staticClass: "stats-group" },
              [
                _c(
                  "b-grid",
                  _vm._l(_vm.stats, function (stat, index) {
                    return _c(
                      "b-cell",
                      { key: index, attrs: { xxs: "12", md: "3" } },
                      [
                        _c(
                          "Stats",
                          _vm._b(
                            {
                              ref: "stats" + index,
                              refInFor: true,
                              attrs: { "on-finish": _vm.onFinish, paused: "" },
                            },
                            "Stats",
                            stat,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }