var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      attrs: {
        "data-in-viewport": _vm.inViewport,
        "data-not-revealed": _vm.useRevealAttributes && !_vm.revealed,
        "data-revealed": _vm.useRevealAttributes && _vm.revealed,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }