export default {
  props: {
    selected: {
      type: Boolean,
    },
    featured: {
      type: Boolean,
    },
    image: {
      type: Object,
    },
    href: {
      type: String,
      default: null,
    },
    onClick: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
  }
}
