import CardCover from "../../components/covers/card-cover";
import LoadingCover from "../../components/covers/loading-cover";
import Section from "../../components/containers/section";
import Tag from "../../components/info/tag";
import Intersection from "../../containers/intersection";
import Button from "../../components/cta/button";
import IconButton from "../../components/cta/icon-button";
import dateFormat from "../../mixins/date-format";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Modal from "../../components/containers/modal";
import SimpleTitle from "../../components/info/simple-title";
import InputFilter from "../../components/fields/input-filter";
import FilterIcon from '../../components/cta/icon-button/assets/filters-embedded.svg';

export default {
  mixins: [dateFormat],
  components: {
    Section,
    CardCover,
    LoadingCover,
    Tag,
    Button,
    IconButton,
    Intersection,
    Modal,
    SimpleTitle,
    InputFilter,
    FilterIcon
  },
  props: {
    posts: {
      type: Array
    },
    onClick: {
      type: Function
    },
    scrollViewLimit: {
      type: Number,
      default: 9
    },
    loading: {
      type: Boolean
    },
    customScroll: {
      type: Boolean,
    },
    onLoadMore: {
      type: Function
    },
    headerTitle: {
      type: String
    },
    filters: {
      type: Object
    },
    sortButtonText: {
      type: String
    },
    sortButtonIcon: {
      type: String,
      default: "arrowUp"
    },
    onSort: {
      type: Function
    },
    onFilterChange: {
      type: Function
    },
    openModalLabel: {
      type: String
    },
    closeModalLabel: {
      type: String
    },
    onResetFilters: {
      type: Function
    },
    activeFilters: {
      type: Number
    },
    onSubmit: {
      type: Function
    },
    selectedCategoriesFilters: {
      type: Array,
      default: () => []
    },
    selectedEditionsFilters: {
      type: Array,
      default: () => []
    },
    appliedLength: {
      type: Number,
      default: 0
    },
    filtersTitle: {
      type: String
    },
  },
  data: () => ({
    columnsNumber: 3,
    columnOffset: 1, // used to fill first the middle column
    oddColumns: [],
    ready: false,
    showModal: false
  }),
  computed: {
    renderFilters() {
      return (
        this.filters &&
        ((this.filters.categories && this.filters.categories.length > 1) ||
          (this.filters.editions && this.filters.editions.length > 1))
      );
    },

    renderHeader() {
      return this.headerTitle || this.renderFilters || this.sortButtonText;
    },

    useScrollBehaviour() {
      return this.posts && this.posts.length >= this.scrollViewLimit && this.customScroll;
    },

    mosaicLoadingCells() {
      return this.loading ? 3 : 0;
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    getPostProps(post) {
      return {
        href: post.url,
        images: post.featured_image ? post.featured_image : {},
        alt: post.featured_image ? post.featured_image.alternativeText : '',
        caption: post.featured_image ? post.featured_image.caption : '',
        date: this.getDate(post.published_at, { has_date: true }),
        title: post.title,
        onClick: post.url ? this.onClick : null
      };
    },

    async onApplySubmit() {
      this.onSubmit();
      this.showModal = false;
      window.scrollTo({ top: 0 });
    },

    onApplySort() {
      if (this.onSort) this.onSort();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    isSelected(id, selected) {
      return !!selected.find(item => Number(item) === Number(id));
    },

    getPostsByColumn(columnIndex) {
      return (
        this.posts &&
        this.posts.filter(
          (_post, i) => (i + this.columnOffset) % this.columnsNumber === columnIndex
        )
      );
    },

    getOddColumns() {
      this.oddColumns = this.$refs.column.filter((_column, index) => index % 2 === 0);
    },

    onScroll() {
      this.calculateMosaicTranslate();
    },

    calculateMosaicTranslate() {
      const column = this.$refs.column[1];
      const columnTopDistance = column.getBoundingClientRect().top;

      // calculate when we reach the end of the module and make the fixed columns stay with the main one;
      const offLimitsValue = column.offsetHeight + columnTopDistance - window.innerHeight;
      const value = offLimitsValue > 0 ? columnTopDistance : columnTopDistance - 2 * offLimitsValue;

      // this is an equivalent of transformY, matrix3D is used for a smoother effect
      const transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-1 * value}, 0, 1)`;
      this.oddColumns.forEach(column => (column.style.transform = transform));
    }
  },
  mounted() {
    this.getOddColumns();
    window.scrollTo({ top: 0 });

    setTimeout(() => {
      this.calculateMosaicTranslate();
      this.ready = true;
    }, 800); // synced with the revealed animation

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.onScroll);
      window.addEventListener("resize", this.onScroll);
    }
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.onScroll);
      window.removeEventListener("resize", this.onScroll);
    }
  },

  watch: {
    posts() {
      if (typeof window !== "undefined") {
        this.calculateMosaicTranslate();
      }
    },
    loading() {
      if (this.loading) {
        if (typeof window !== "undefined") {
          disableBodyScroll(this.$el);
        }
      } else {
        if (typeof window !== "undefined") {
          enableBodyScroll(this.$el);
        }
      }
    }
  }
};
