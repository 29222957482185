export const state = () => ({
  categories: [],
  editions: [],
  page_pointer: 0,
  order: 'DESC',
})

export const actions = {
  updateCategories({ commit }, data) {
    const { add, id } = data
    if (add) {
      commit('addCategory', id)
    } else {
      commit('removeCategory', id)
    }
  },
  updateEditions({ commit }, data) {
    const { add, id } = data
    if (add) {
      commit('addEdition', id)
    } else {
      commit('removeEdition', id)
    }
  },
  clearFilters({ commit }) {
    commit('clearFilters')
  },
  pageUp({ commit }, data) {
    const { per_page } = data
    commit('pageUp', per_page)
  },
  pageDown({ commit }, data) {
    const { per_page } = data
    commit('pageDown', per_page)
  },
  pageJump({ commit }, data) {
    const { per_page } = data
    commit('pageJump', per_page)
  },
  switchOrder({ commit }) {
    commit('switchOrder')
  },
}

export const mutations = {
  addCategory(state, id) {
    state.categories.push(id)
  },
  removeCategory(state, id) {
    state.categories = state.categories.filter((item) => item !== id)
  },
  addEdition(state, id) {
    state.editions.push(id)
  },
  removeEdition(state, id) {
    state.editions = state.editions.filter((item) => item !== id)
  },
  clearFilters(state) {
    state.editions = []
    state.categories = []
    state.order = 'DESC'
  },
  pageUp(state, per_page) {
    state.page_pointer += per_page
  },
  pageDown(state, per_page) {
    if (state.page_pointer > 0) {
      state.page_pointer -= per_page
    }
  },
  pageJump(state, per_page) {
    state.page_pointer = per_page
  },
  switchOrder(state) {
    const { order } = state

    if (order == 'DESC') {
      state.order = 'ASC'
    }
    if (order == 'ASC') {
      state.order = 'DESC'
    }
  },
}

export const getters = {
  getOrder: (state) => {
    return state.order
  },
  getSelectedCategories: (state) => {
    return state.categories
  },
  getSelectedEditions: (state) => {
    return state.editions
  },
  getAppliedFiltersTotal: (state) => {
    return state.categories.length + state.editions.length
  },
  getPagePointer: (state) => {
    return state.page_pointer
  },
}
