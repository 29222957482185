var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-brand-logo",
      attrs: {
        "data-highlight": _vm.highlight,
        "data-size": _vm.size,
        "data-highlight-when-narrow": _vm.highlightWhenNarrow,
      },
    },
    [
      _c(
        "span",
        { staticClass: "icon" },
        [
          _c(_vm.isIcon("desktop", "ltr"), {
            tag: "component",
            staticClass: "icon-ltr",
            attrs: { "aria-hidden": "true", focusable: "false" },
          }),
          _vm._v(" "),
          _c(_vm.isIcon("desktop", "rtl"), {
            tag: "component",
            staticClass: "icon-rtl",
            attrs: { "aria-hidden": "true", focusable: "false" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "icon-mobile" },
        [
          _c(_vm.isIcon("mobile", "ltr"), {
            tag: "component",
            staticClass: "icon-ltr",
            attrs: { "aria-hidden": "true", focusable: "false" },
          }),
          _vm._v(" "),
          _c(_vm.isIcon("mobile", "rtl"), {
            tag: "component",
            staticClass: "icon-rtl",
            attrs: { "aria-hidden": "true", focusable: "false" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "text" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }