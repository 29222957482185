var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-ongoing-events" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            background: _vm.background,
            "wrapper-type": "full",
            "with-border-top": "",
          },
          scopedSlots: _vm._u([
            {
              key: "cta",
              fn: function () {
                return [
                  !!_vm.button && _vm.button.type === "regular"
                    ? _c(
                        "Button",
                        {
                          attrs: {
                            href: _vm.button.href,
                            "on-click": _vm.onClick,
                          },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.button.label) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm.items && _vm.items.length > 0
            ? _c(
                "b-slider",
                {
                  ref: "slider",
                  attrs: {
                    arrows: "",
                    "right-to-left": _vm.rightToLeft,
                    "cell-align": _vm.cellAlign,
                  },
                },
                _vm._l(_vm.items, function (slide, id) {
                  return _c(
                    "b-cell",
                    {
                      key: `${slide.title}-${id}`,
                      attrs: { xxs: "11", md: "6", lg: "8" },
                    },
                    [
                      _c("EventCardCover", {
                        attrs: {
                          "data-fit-container": "true",
                          href: slide.url,
                          images: slide.featured_image,
                          date: _vm.getDate(slide.start_date, slide),
                          title: slide.title,
                          alt: slide.featured_image?.alternativeText,
                          caption: slide.featured_image?.caption,
                          "on-click": slide.href ? _vm.onClick : null,
                        },
                        scopedSlots: _vm._u(
                          [
                            slide.location && slide.location.location
                              ? {
                                  key: "location",
                                  fn: function () {
                                    return [
                                      _c("LocationTag", {
                                        attrs: {
                                          location: slide.location.location,
                                          href: slide.location.map_url,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                            slide.pillars || slide.event_types
                              ? {
                                  key: "tags",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "tags" },
                                        [
                                          slide.pillars
                                            ? _vm._l(
                                                slide.pillars,
                                                function (pillar, idx) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: `pillar-${idx}`,
                                                      staticClass: "tag",
                                                    },
                                                    [
                                                      _c(
                                                        "Tag",
                                                        {
                                                          attrs: {
                                                            color: pillar.color,
                                                            "no-border": true,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                pillar.title
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          slide.event_types
                                            ? _vm._l(
                                                slide.event_types,
                                                function (type, idx) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: `type-${idx}`,
                                                      staticClass: "tag",
                                                    },
                                                    [
                                                      _c(
                                                        "Tag",
                                                        { attrs: { type: "" } },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                type.title
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm.empty
            ? _c(
                "div",
                { staticClass: "empty" },
                [
                  _c(
                    "b-wrapper",
                    [
                      _c("EmptyMessage", {
                        attrs: { title: _vm.empty.title, text: _vm.empty.text },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }