var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "m-posts-mosaic",
      attrs: {
        "data-use-scroll-behaviour": _vm.useScrollBehaviour,
        "data-loading": _vm.loading,
      },
    },
    [
      _vm.renderFilters
        ? _c(
            "div",
            {
              staticClass: "icon-filter-wrapper",
              on: { click: _vm.openModal },
            },
            [
              _c(
                "div",
                { staticClass: "filters-button" },
                [
                  _c("FilterIcon"),
                  _vm._v(" "),
                  !!_vm.appliedLength
                    ? _c("span", { staticClass: "filter-count" }, [
                        _vm._v(_vm._s(_vm.appliedLength)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "filter-button-label" }, [
                    _vm._v(_vm._s(_vm.openModalLabel)),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Section",
        { attrs: { spacing: _vm.useScrollBehaviour ? "none" : "default" } },
        [
          _vm.renderHeader
            ? _c(
                "div",
                { staticClass: "mosaic-header" },
                [
                  _c("b-wrapper", [
                    _c("div", { staticClass: "header-wrapper" }, [
                      _c("p", { staticClass: "header-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.headerTitle) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.renderFilters || _vm.sortButtonText
                        ? _c(
                            "div",
                            { staticClass: "controls" },
                            [
                              _vm.sortButtonText && _vm.onSort
                                ? _c(
                                    "Button",
                                    {
                                      attrs: {
                                        "on-click": _vm.onApplySort,
                                        color: "sunrise",
                                        icon: _vm.sortButtonIcon,
                                        "text-size": "b2",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.sortButtonText) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.renderFilters
            ? _c("Modal", {
                staticClass: "filters-modal",
                attrs: {
                  "wrapper-type": "header",
                  "aria-live": "polite",
                  "close-label": _vm.closeModalLabel,
                  "on-close": _vm.closeModal,
                  show: _vm.showModal,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.filtersTitle
                      ? {
                          key: "title",
                          fn: function () {
                            return [
                              _c("SimpleTitle", {
                                attrs: {
                                  text: _vm.filtersTitle,
                                  color: "white",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    _vm.filters.categories && _vm.filters.categories.length > 1
                      ? {
                          key: "pillar-heading",
                          fn: function () {
                            return [
                              _vm.filters.categories_title
                                ? _c("SimpleTitle", {
                                    attrs: {
                                      text: _vm.filters.categories_title,
                                      color: "white",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    _vm.filters.categories && _vm.filters.categories.length > 1
                      ? {
                          key: "pillars-list",
                          fn: function () {
                            return [
                              _c(
                                "ul",
                                { staticClass: "filter-list" },
                                _vm._l(
                                  _vm.filters.categories,
                                  function (category) {
                                    return _c(
                                      "li",
                                      {
                                        key: category.id,
                                        staticClass: "filter-element",
                                      },
                                      [
                                        _c("InputFilter", {
                                          attrs: {
                                            label: category.title,
                                            id: `category-${category.id}`,
                                            "on-change": _vm.onFilterChange,
                                            checked: _vm.isSelected(
                                              category.id,
                                              _vm.selectedCategoriesFilters
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    _vm.filters.editions && _vm.filters.editions.length > 1
                      ? {
                          key: "types-heading",
                          fn: function () {
                            return [
                              _vm.filters.editions_title
                                ? _c("SimpleTitle", {
                                    attrs: {
                                      text: _vm.filters.editions_title,
                                      color: "white",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    _vm.filters.editions && _vm.filters.editions.length > 1
                      ? {
                          key: "types-list",
                          fn: function () {
                            return [
                              _c(
                                "ul",
                                { staticClass: "filter-list" },
                                _vm._l(
                                  _vm.filters.editions,
                                  function (edition) {
                                    return _c(
                                      "li",
                                      {
                                        key: edition.id,
                                        staticClass: "filter-element",
                                      },
                                      [
                                        _c("InputFilter", {
                                          attrs: {
                                            label: edition.year,
                                            id: `edition-${edition.id}`,
                                            "on-change": _vm.onFilterChange,
                                            checked: _vm.isSelected(
                                              edition.id,
                                              _vm.selectedEditionsFilters
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "first-cta",
                      fn: function () {
                        return [
                          _c(
                            "Button",
                            { attrs: { "on-click": _vm.onResetFilters } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.filters.reset_button_label) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "second-cta",
                      fn: function () {
                        return [
                          _c(
                            "Button",
                            {
                              attrs: {
                                "on-click": _vm.onApplySubmit,
                                type: "submit",
                                color: "sunrise",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.filters.apply_button_label +
                                      " " +
                                      "(" +
                                      _vm.appliedLength +
                                      ")"
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scroll-mosaic" },
            _vm._l(_vm.columnsNumber, function (_column, columnIndex) {
              return _c(
                "div",
                {
                  key: columnIndex,
                  ref: "column",
                  refInFor: true,
                  staticClass: "column",
                },
                [
                  _vm._l(_vm.getPostsByColumn(columnIndex), function (post) {
                    return _c(
                      "div",
                      { key: post.id, staticClass: "post" },
                      [
                        _c(
                          "CardCover",
                          _vm._b(
                            {
                              scopedSlots: _vm._u(
                                [
                                  post.post_categories &&
                                  post.post_categories.length > 0
                                    ? {
                                        key: "tags",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "tags" },
                                              _vm._l(
                                                post.post_categories,
                                                function (category) {
                                                  return _c(
                                                    "Tag",
                                                    {
                                                      key: category.title,
                                                      attrs: {
                                                        color: category.color,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            category.title
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            },
                            "CardCover",
                            _vm.getPostProps(post),
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "loading" },
                    [_c("LoadingCover")],
                    1
                  ),
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mosaic" },
            [
              _c(
                "b-grid",
                [
                  _vm._l(_vm.posts, function (post) {
                    return _c(
                      "b-cell",
                      { key: post.id, attrs: { xxs: "12", sm: "6", lg: "4" } },
                      [
                        _c(
                          "CardCover",
                          _vm._b(
                            {
                              scopedSlots: _vm._u(
                                [
                                  post.post_categories &&
                                  post.post_categories.length > 0
                                    ? {
                                        key: "tags",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "tags" },
                                              [
                                                _vm._l(
                                                  post.post_categories,
                                                  function (pillar) {
                                                    return _c(
                                                      "Tag",
                                                      {
                                                        key: pillar.title,
                                                        attrs: {
                                                          color: pillar.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              pillar.title
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  post.event_types,
                                                  function (type) {
                                                    return _c(
                                                      "Tag",
                                                      { key: type.title },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(type.title) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            },
                            "CardCover",
                            _vm.getPostProps(post),
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.mosaicLoadingCells, function (cellIndex) {
                    return _c(
                      "b-cell",
                      {
                        key: `loading-cell-${cellIndex}`,
                        attrs: { xxs: "12", sm: "6", lg: "4" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "loading" },
                          [_c("LoadingCover")],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.ready
            ? _c(
                "Intersection",
                {
                  attrs: {
                    "root-margin": "0px 0px 0px 0px",
                    "on-enter": _vm.onLoadMore,
                  },
                },
                [_c("div", { staticClass: "trigger" })]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }