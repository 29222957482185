var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "m-post-hero" }, [
    _c(
      "div",
      { staticClass: "post-contents" },
      [
        _c(
          "b-wrapper",
          [
            _c(
              "b-grid",
              [
                _c(
                  "b-cell",
                  { attrs: { xxs: "12" } },
                  [
                    _vm.backlink_href
                      ? _c(
                          "b-clickable",
                          {
                            ref: "backLink",
                            attrs: {
                              href: _vm.backlink_href,
                              "on-click": _vm.onClick,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "backlink" },
                              [
                                _c("ArrowIcon", {
                                  staticClass: "arrow",
                                  attrs: {
                                    "aria-hidden": "true",
                                    focusable: "false",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "text" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.backlink) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-cell", { attrs: { xxs: "12", lg: "6" } }, [
                  _c("div", { staticClass: "post-heading" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.title) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.published_at
                      ? _c("p", { staticClass: "date" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getDate(_vm.published_at, {
                                  has_date: true,
                                })
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "post-description" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.excerpt) + "\n          "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "post-footer" },
      [
        _c(
          "b-wrapper",
          [
            _c(
              "b-grid",
              [
                _c("b-cell", { attrs: { xxs: "12", sm: "9" } }, [
                  _vm.renderTags
                    ? _c(
                        "div",
                        { staticClass: "post-tags" },
                        [
                          _vm._l(_vm.post_categories, function (category) {
                            return _c(
                              "Tag",
                              {
                                key: category.title,
                                attrs: { color: category.color },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(category.title) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.pillars, function (pillar) {
                            return _c(
                              "Tag",
                              {
                                key: pillar.title,
                                attrs: { color: pillar.color },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(pillar.title) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.featured_image
      ? _c(
          "div",
          { staticClass: "post-media" },
          [
            _c("b-wrapper", { attrs: { type: "desktop-full" } }, [
              _c(
                "div",
                { staticClass: "media-wrapper" },
                [
                  _c("div", { staticClass: "overlay" }),
                  _vm._v(" "),
                  _c("ImageComponent", {
                    attrs: { images: _vm.featured_image, "lazy-load": true },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "hero-arrow-down" },
      [
        _c(
          "b-wrapper",
          [
            _c(
              "ArrowButton",
              {
                attrs: {
                  "arrow-type": "vertical",
                  "on-click": _vm.scrollToContent,
                },
              },
              [_vm._v("\n        Scroll down\n      ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }