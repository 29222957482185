var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-social-button" },
    [
      _c(
        "b-clickable",
        { ref: "clickable", attrs: { type: "button", href: _vm.href } },
        [
          _c("span", { staticClass: "content" }, [
            _vm.icon
              ? _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _vm.icon === "youtube"
                      ? _c("YoutubeIcon", {
                          staticClass: "youtube",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.icon === "linkedin"
                      ? _c("LinkedInIcon", {
                          staticClass: "linkedin",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.icon === "twitter"
                      ? _c("TwitterIcon", {
                          staticClass: "twitter",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.icon === "facebook"
                      ? _c("FacebookIcon", {
                          staticClass: "facebook",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.icon === "instagram"
                      ? _c("InstagramIcon", {
                          staticClass: "instagram",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-label" }, [
              _vm._v("\n        " + _vm._s(_vm.label) + "\n      "),
            ]),
            _vm._v(" "),
            _vm.text
              ? _c("span", [
                  _c("p", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }