import Button from '../../components/cta/button';
import CardCover from "../../components/covers/card-cover";
import Pagination from '../../components/nav/pagination';
import Section from "../../components/containers/section";

export default {
  components: {
    Button,
    Section,
    Pagination,
    CardCover,
  },
  data: () => ({
    currentPage: 0,
  }),
  props: {
    pretitle: {
      type: String
    },
    title: {
      type: String
    },
    news: {
      type: Array,
      required: true
    },
    onClick: {
      type: Function
    },
    newsPerPage: {
      type: Number,
      default: 5,
    }
  },
  computed: {
    currentNews() {
      if (!this.newsPerPage) {
        return this.news;
      }
      return this.news.slice(this.newsPerPage * this.currentPage, this.newsPerPage * (this.currentPage + 1));
    },
    paginationTotal() {
      return Math.ceil(this.news.length / this.newsPerPage);
    }
  },
};
