var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-sponsor-cover",
      attrs: { "data-selected": _vm.selected, "data-featured": _vm.featured },
    },
    [
      _c("b-clickable", { ref: "clickable", attrs: { href: _vm.href } }, [
        _c("div", { staticClass: "media-content" }, [
          _c("div", { staticClass: "image-wrapper" }, [
            _c("img", {
              staticClass: "image",
              attrs: { src: _vm.image.url, alt: _vm.image.alternativeText },
            }),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }