var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-events-slider" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            background: _vm.background,
            "wrapper-type": "full",
            "with-border-top": "",
          },
          scopedSlots: _vm._u([
            {
              key: "cta",
              fn: function () {
                return [
                  !!_vm.button && _vm.button.type === "regular"
                    ? _c(
                        "Button",
                        {
                          attrs: {
                            href: _vm.button.href,
                            onclick: _vm.onClick,
                          },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.button.label) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "b-slider",
            {
              ref: "slider",
              attrs: {
                arrows: "",
                "right-to-left": _vm.rightToLeft,
                "cell-align": _vm.cellAlign,
              },
            },
            _vm._l(_vm.events, function (item, id) {
              return _c(
                "b-cell",
                { key: `${item.title}-${id}`, attrs: { xxs: "11", md: "5" } },
                [
                  _c(
                    "CardCover",
                    {
                      attrs: {
                        href: item.url,
                        images: item.featured_image,
                        date: _vm.getDate(item.start_date, item),
                        title: item.title,
                        "on-click": item.url ? _vm.onClick : null,
                        alt: item.featured_image.alternativeText,
                        caption: item.featured_image.caption,
                      },
                      scopedSlots: _vm._u(
                        [
                          item.pillars || item.event_types
                            ? {
                                key: "tags",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "tags" },
                                      [
                                        item.pillars
                                          ? _vm._l(
                                              item.pillars,
                                              function (pillar, idx) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: `pillar-${idx}`,
                                                    staticClass: "tag",
                                                  },
                                                  [
                                                    _c(
                                                      "Tag",
                                                      {
                                                        attrs: {
                                                          color: pillar.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              pillar.title
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      item.event_types
                        ? _vm._l(item.event_types, function (type, idx) {
                            return _c(
                              "span",
                              { key: `type-${idx}`, staticClass: "tag" },
                              [
                                _c("Tag", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(type.title) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }