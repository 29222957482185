var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-post-content" },
    [
      _c(
        "Section",
        { attrs: { "with-border-top": "", spacing: "lg" } },
        [
          _c(
            "b-grid",
            [
              _c(
                "b-cell",
                { attrs: { xxs: "12", lg: "10", xxl: "8" } },
                [
                  _c(
                    "RichText",
                    _vm._b(
                      { attrs: { content: _vm.content } },
                      "RichText",
                      _vm.rich_text_config,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }