import RichText from '../../components/article/rich-text'
import Section from '../../components/containers/section'

export default {
  components: {
    RichText,
    Section,
  },
  props: {
    pretitle: {
      type: String,
    },
    title: {
      type: String,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    cellSize() {
      return {
        xxs: 12,
        lg: 12 / this.columns.length,
      }
    }
  },
}