var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-pillar-intro" },
    [
      _c("b-clickable", { ref: "clickable", attrs: { href: _vm.href } }, [
        _c("p", { staticClass: "title" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }