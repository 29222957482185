var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "m-page-hero",
      attrs: {
        "data-background": _vm.background,
        "data-half-background": _vm.halfBackground,
        "data-show-arrow": _vm.show_arrow,
        "data-with-icon": _vm.icon ? true : false,
      },
    },
    [
      _c("b-wrapper", [
        _c(
          "div",
          { staticClass: "content-section" },
          [
            _vm.icon
              ? _c("ImageComponent", {
                  staticClass: "icon",
                  attrs: {
                    src: _vm.icon.url,
                    alt: _vm.icon.alternativeText,
                    "lazy-load": true,
                  },
                })
              : _vm.pretitle
              ? _c("p", { staticClass: "pretitle" }, [
                  _vm._v("\n        " + _vm._s(_vm.pretitle) + "\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "info-wrapper" }, [
              _vm.title
                ? _c("h1", { staticClass: "title" }, [
                    _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.subtitle
                ? _c("p", { staticClass: "subtitle" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.subtitle) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.text
                ? _c(
                    "div",
                    { staticClass: "description" },
                    [_c("RichText", { attrs: { content: _vm.text } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasMedia && _vm.halfBackground
                ? _c(
                    "div",
                    { staticClass: "media-content-half" },
                    [
                      _c("div", { staticClass: "overlay" }),
                      _vm._v(" "),
                      _vm.isImage
                        ? _c("ImageComponent", {
                            attrs: { images: _vm.media, "lazy-load": true },
                          })
                        : _c("video", {
                            attrs: {
                              src: _vm.media.url,
                              caption: _vm.media.caption,
                              alt: _vm.media.alternativeText,
                              muted: "true",
                              playsinline: "true",
                              loop: "",
                              autoplay: "",
                            },
                            domProps: { muted: true },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "hero-arrow-down" },
              [
                _c(
                  "ArrowButton",
                  {
                    attrs: {
                      "arrow-type": "vertical",
                      "on-click": () => {
                        _vm.scrollToContent()
                      },
                    },
                  },
                  [_vm._v("\n          Scroll down\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.hasMedia
        ? _c(
            "div",
            { staticClass: "media-content" },
            [
              _vm.isImage
                ? _c("ImageComponent", {
                    attrs: { images: _vm.media, "lazy-load": true },
                  })
                : _c("video", {
                    attrs: {
                      src: _vm.media.url,
                      caption: _vm.media.caption,
                      alt: _vm.media.alternativeText,
                      muted: "true",
                      playsinline: "true",
                      loop: "",
                      autoplay: "",
                    },
                    domProps: { muted: true },
                  }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }