var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-promo" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            date: _vm.date,
            pretitle: _vm.pretitle,
            text: _vm.text,
            "wrapper-type": _vm.wrapper_type,
            "with-border-top": "",
          },
          scopedSlots: _vm._u(
            [
              _vm.pillars
                ? {
                    key: "tags",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "tags" },
                          _vm._l(_vm.pillars, function (pillar, idx) {
                            return _c(
                              "span",
                              { key: idx },
                              [
                                _c("Tag", { attrs: { color: pillar.color } }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(pillar.title) +
                                      "\n          "
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              !!_vm.button
                ? {
                    key: "cta",
                    fn: function () {
                      return [
                        !!_vm.button && _vm.button.type === "icon"
                          ? _c(
                              "ArrowButton",
                              {
                                attrs: {
                                  "arrow-type": "big",
                                  "on-click": _vm.onClick,
                                  href: _vm.button.href,
                                },
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.button.label) +
                                    "\n      "
                                ),
                              ]
                            )
                          : _c(
                              "Button",
                              {
                                attrs: {
                                  "on-click": _vm.onClick,
                                  href: _vm.button.href,
                                },
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.button.label) +
                                    "\n      "
                                ),
                              ]
                            ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("div", { staticClass: "media" }, [
            _vm.media
              ? _c(
                  "div",
                  { staticClass: "media-wrapper" },
                  [
                    _vm.show_overlay
                      ? _c("div", { staticClass: "overlay" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isImage
                      ? _c("img", {
                          attrs: {
                            src: _vm.media.url,
                            alt: _vm.media.alternativeText,
                          },
                        })
                      : _vm.isVideo
                      ? _c("Video", {
                          attrs: {
                            src: _vm.media.url,
                            caption: _vm.media.caption,
                            alt: _vm.media.alternativeText,
                            use_sound_button: _vm.has_sound,
                            aspect_ratio: "16/9",
                            settings: {
                              playsinline: "true",
                              loop: true,
                              autoplay: true,
                              muted: true,
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }