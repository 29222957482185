var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "m-text-slider",
      attrs: {
        "data-limit-height": _vm.allow_show_more_button,
        "data-showing-more": _vm.showingMore,
        "data-layout": _vm.layout,
      },
    },
    [
      _c(
        "Section",
        {
          attrs: {
            pretitle: _vm.pretitle,
            title: _vm.title,
            "data-spacing": "lg",
            "with-border-top": "",
          },
        },
        [
          _c("div", { staticClass: "text-container" }, [
            _c("p", { ref: "text", staticClass: "text" }, [
              _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
            ]),
          ]),
          _vm._v(" "),
          _vm.allow_show_more_button && _vm.useShowMore
            ? _c(
                "div",
                { staticClass: "show-more" },
                [
                  _c(
                    "Button",
                    { attrs: { "on-click": _vm.toggleShowingMore } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.showingMore
                              ? _vm.show_less_text
                              : _vm.show_more_text
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }