import SearchIcon from './assets/search-embedded.svg';

export default {
  components: {
    SearchIcon
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null
    },
    onChange: {
      type: Function
    },
    onInput: {
      type: Function
    }
  }
};
