import Section from "../../components/containers/section";
import ArrowButton from "../../components/cta/arrow-button";
import Pretitle from "../../components/info/pretitle";
import Tag from "../../components/info/tag";
import dateFormat from "../../mixins/date-format";
import Video from "../../components/media/video";
import ImageComponent from "../../components/media/image";

export default {
  mixins: [dateFormat],
  components: {
    Section,
    ArrowButton,
    Pretitle,
    Tag,
    Video,
    ImageComponent
  },
  props: {
    pretitle: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    date: {
      type: String
    },
    has_date: {
      type: Boolean
    },
    description: {
      type: String
    },
    featured_image: {
      type: Object
    },
    locale: {
      type: String
    },
    pillars: {
      type: Array
    },
    href: {
      type: String
    },
    onClick: {
      type: Function
    },
    has_sound: {
      type: Boolean
    }
  },
  computed: {
    isImage() {
      return (
        this.featured_image &&
        this.featured_image.mime &&
        this.featured_image.mime.startsWith("image")
      );
    },
    isVideo() {
      return (
        this.featured_image &&
        this.featured_image.mime &&
        this.featured_image.mime.startsWith("video")
      );
    }
  },
  mounted() {
    if (this.$refs.clickableImage) {
      this.$refs.clickableImage.onClick = this.onClick;
    }
  }
};
