import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ecd4248c = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _4fc80742 = () => interopDefault(import('../pages/editorial.vue' /* webpackChunkName: "pages/editorial" */))
const _22f4175e = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _1080beaf = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _1389d7c2 = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _4fc3b7e4 = () => interopDefault(import('../pages/program/event-types/_slug.vue' /* webpackChunkName: "pages/program/event-types/_slug" */))
const _02a61dd1 = () => interopDefault(import('../pages/program/pillars/_slug.vue' /* webpackChunkName: "pages/program/pillars/_slug" */))
const _3522f166 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _d98549ba = () => interopDefault(import('../pages/past-editions/_slug.vue' /* webpackChunkName: "pages/past-editions/_slug" */))
const _fa4ccd34 = () => interopDefault(import('../pages/posts/_slug.vue' /* webpackChunkName: "pages/posts/_slug" */))
const _21c768a2 = () => interopDefault(import('../pages/editions/_edition/events/_slug.vue' /* webpackChunkName: "pages/editions/_edition/events/_slug" */))
const _6d88afd5 = () => interopDefault(import('../pages/_entity/_slug.vue' /* webpackChunkName: "pages/_entity/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _ecd4248c,
    name: "blog___en"
  }, {
    path: "/editorial",
    component: _4fc80742,
    name: "editorial___en"
  }, {
    path: "/legal",
    component: _22f4175e,
    name: "legal___en"
  }, {
    path: "/privacy-policy",
    component: _1080beaf,
    name: "privacy-policy___en"
  }, {
    path: "/program",
    component: _1389d7c2,
    name: "program___en"
  }, {
    path: "/ar/blog",
    component: _ecd4248c,
    name: "blog___ar"
  }, {
    path: "/ar/editorial",
    component: _4fc80742,
    name: "editorial___ar"
  }, {
    path: "/ar/legal",
    component: _22f4175e,
    name: "legal___ar"
  }, {
    path: "/ar/privacy-policy",
    component: _1080beaf,
    name: "privacy-policy___ar"
  }, {
    path: "/ar/program",
    component: _1389d7c2,
    name: "program___ar"
  }, {
    path: "/ar/program/event-types/:slug?",
    component: _4fc3b7e4,
    name: "program-event-types-slug___ar"
  }, {
    path: "/ar/program/pillars/:slug?",
    component: _02a61dd1,
    name: "program-pillars-slug___ar"
  }, {
    path: "/ar/news/:slug?",
    component: _3522f166,
    name: "news-slug___ar"
  }, {
    path: "/ar/past-editions/:slug?",
    component: _d98549ba,
    name: "past-editions-slug___ar"
  }, {
    path: "/ar/posts/:slug?",
    component: _fa4ccd34,
    name: "posts-slug___ar"
  }, {
    path: "/program/event-types/:slug?",
    component: _4fc3b7e4,
    name: "program-event-types-slug___en"
  }, {
    path: "/program/pillars/:slug?",
    component: _02a61dd1,
    name: "program-pillars-slug___en"
  }, {
    path: "/ar/editions/:edition?/events/:slug?",
    component: _21c768a2,
    name: "editions-edition-events-slug___ar"
  }, {
    path: "/news/:slug?",
    component: _3522f166,
    name: "news-slug___en"
  }, {
    path: "/past-editions/:slug?",
    component: _d98549ba,
    name: "past-editions-slug___en"
  }, {
    path: "/posts/:slug?",
    component: _fa4ccd34,
    name: "posts-slug___en"
  }, {
    path: "/editions/:edition?/events/:slug?",
    component: _21c768a2,
    name: "editions-edition-events-slug___en"
  }, {
    path: "/ar/:entity?/:slug?",
    component: _6d88afd5,
    name: "entity-slug___ar"
  }, {
    path: "/:entity?/:slug?",
    component: _6d88afd5,
    name: "entity-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
