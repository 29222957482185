import DOMPurify from 'isomorphic-dompurify';

export default {
  props: {
    content: {
      type: String,
    },
    allowed_tags: {
      type: Array,
      default: () => [
        'h2',
        'h3',
        'h4',
        'h5',
        'div',
        'span',
        'p',
        'span',
        'ul',
        'ol',
        'li',
        'a',
        'b',
        'strong',
        'blockquote',
        'figure',
        'figcaption',
        'video',
        'img',
        'table',
        'td',
        'tr',
        'tbody',
        'th',
        'iframe',
        'oembed',
        'br'
      ],
    },
    allowed_attributes: {
      type: Array,
      default: () => [
        'href',
        'src',
        'alt',
        'target',
        'style',
        'class',
        'url',
      ],
    },
    type: {
      type: String,
    }
  },
  computed: {
    sanitizedContent() {
      return DOMPurify.sanitize(this.content, {
        ALLOWED_TAGS: this.allowed_tags,
        ALLOWED_ATTR: this.allowed_attributes,
      });
    },
  },
  methods: {
    getYoutubeId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    },

    createEmbbededVideo(videoId, $element) {
      // embedded video
      const $result = document.createElement('iframe');
      $result.setAttribute('src', `https://www.youtube.com/embed/${videoId}`);
      $result.setAttribute('frameborder', "0");
      $result.setAttribute('allowfullscreen', true);

      // wrapper for ratio
      const $iframeWrapper =  document.createElement('div');
      $iframeWrapper.classList.add('iframe-wrapper');
      $iframeWrapper.appendChild($result);

      // replace oembed tag with video wrapper with ifram
      $element.replaceWith($iframeWrapper);
    },

    expandOembeds() {
      if (this.$refs.content) {
        const $oembeds = this.$refs.content.querySelectorAll('oembed');

        Array.from($oembeds).forEach(async ($oembed) => {
          const url = $oembed.getAttribute('url');
          if (url) {
            const id = this.getYoutubeId(url);
            this.createEmbbededVideo(id, $oembed);
          }
        });
      }
    },
  },
  mounted() {
    this.expandOembeds();
  }
};
