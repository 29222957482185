var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-agenda-cover",
      attrs: {
        "data-background": _vm.background,
        "data-with-title": !!_vm.dayTitle,
      },
    },
    [
      _vm.time
        ? _c("p", { staticClass: "time" }, [
            _vm._v("\n    " + _vm._s(_vm.time) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.sessionTitle && _vm.sessionDetails
        ? _c("dl", [
            _c("dt", [_vm._v(_vm._s(_vm.sessionTitle))]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.sessionDetails))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.dayTitle
        ? _c("p", { staticClass: "day-title" }, [
            _vm._v("\n    " + _vm._s(_vm.dayTitle) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }