import Intersection from '../../../containers/intersection';

export default {
  components: {
    Intersection,
  },
  data: () => ({
    isFixed: false,
    targetHeight: undefined,
  }),
  methods: {
    evaluateHeight() {
      this.targetHeight = this.$refs.target.offsetHeight;
    },
    onEnter() {
      this.isFixed = false;
    },
    onLeave(e) {
      if (e[0].boundingClientRect.top < 0) {
        this.isFixed = true;
        this.evaluateHeight();
      }
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.evaluateHeight);
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.evaluateHeight);
    }
  },
};
