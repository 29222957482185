var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-editions-list" },
    [
      _c(
        "Section",
        {
          attrs: {
            pretitle: _vm.pretitle,
            title: _vm.title,
            text: _vm.text,
            "pretitle-color": "black",
            "with-border-top": "",
          },
        },
        [
          _c("div", { ref: "wrapp" }, [
            _c(
              "ul",
              { staticClass: "pillars" },
              _vm._l(_vm.editions, function (edition) {
                return _c(
                  "li",
                  {
                    key: edition.url,
                    staticClass: "pillar",
                    on: {
                      mouseenter: () => _vm.onMouseEnter(edition),
                      mouseleave: _vm.resetPillar,
                    },
                  },
                  [
                    _c("PillarIntro", {
                      ref: "pillar",
                      refInFor: true,
                      attrs: {
                        title: _vm.getTitle(edition),
                        href: edition.url,
                        "on-click": _vm.onClick,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }