export default {
  props: {
    images: {
      type: Object,
      default: () => {},
    },
    src: {
      type: String,
    },
    className: {
      type: String,
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
    },
  },
  computed: {
    defaultUrl() {
      return this.src || this.images?.url;
    },
    formats() {
      return this.images?.formats || {};
    },
    imageAlt() {
      return this.alt || this.images?.alternativeText || "";
    },
  },
};
