import Section from '../../components/containers/section';
import EntityDetails from '../../components/info/entity-details';
import Button from '../../components/cta/button';
import SocialButton from '../../components/cta/social-button';

export default {
  components: {
    Section,
    Button,
    EntityDetails,
    SocialButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
    },
    image: {
      type: String,
    },
    description: {
      type: String,
    },
    primaryAnchor: {
      type: String,
    },
    primaryHref: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  }
};
