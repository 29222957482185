export default {
  data() {
    return {
      statedShowFilters: false,
    };
  },

  props: {
    showFilters: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onToggleClick() {
      this.statedShowFilters = !this.statedShowFilters;
    }
  },

  mounted() {
    this.statedShowFilters = this.showFilters;
  },

  render() {
    return this.$scopedSlots.default({
      statedShowFilters: this.statedShowFilters,
      onToggleClick: this.onToggleClick,
    });
  },
};
