export default {
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    toggleOpened() {
      this.opened = !this.opened;
    },
  },
  render() {
    return this.$scopedSlots.default({
      opened: this.opened,
      toggleOpened: this.toggleOpened,
    });
  },
};
