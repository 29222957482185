var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-icon-button",
      attrs: { "data-clickable": !!_vm.onClick },
    },
    [
      _c(
        "b-clickable",
        { ref: "clickable", attrs: { type: "button", href: _vm.href } },
        [
          _c("span", { staticClass: "content" }, [
            _vm.icon
              ? _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _vm.icon === "filters"
                      ? _c("FiltersIcon", {
                          staticClass: "filters",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.icon === "filtersSmall"
                      ? _c("FiltersIconSmall", {
                          staticClass: "filters",
                          attrs: { "aria-hidden": "true", focusable: "false" },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-label" }, [
              _vm._v("\n        " + _vm._s(_vm.label) + "\n      "),
            ]),
            _vm._v(" "),
            _vm.text
              ? _c("span", { staticClass: "text" }, [
                  _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }