var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-menu" }, [
    _c(
      "ul",
      { staticClass: "links", attrs: { role: "menu" } },
      _vm._l(_vm.links, function (link) {
        return _c(
          "li",
          { key: link.href, staticClass: "link", attrs: { role: "menuitem" } },
          [
            link.drop_down && link.drop_down.length > 0
              ? _c("Dropdown", {
                  attrs: { item: link, "see-all": _vm.seeAll },
                  on: { dropdown: _vm.emitDropdown },
                })
              : _c(
                  "a",
                  {
                    attrs: { href: link.href },
                    on: {
                      click: (e) => {
                        if (_vm.onClick) {
                          e.preventDefault()
                          _vm.onClick(link.href, e)
                        }
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(link.anchor) + "\n      ")]
                ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }