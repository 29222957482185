/**
 * Inject the necessary general settings and maps them into the module correspondant props
 *
 * @param {Object} settings
 * @param {Object} module
 * @returns Object
 */
export function injectSettingsIntoModule(settings, module) {
  if (module === 'competitions') {
    const {
      competitions_literals: {
        participate_button_literal: buttonText,
        finalized_literal: statusFinalized,
      },
    } = settings

    return {
      buttonText,
      statusFinalized,
    }
  }

  if (module === 'pillars-list') {
    return { settings: { filters: { ...settings.filters } } }
  }

  return {}
}
