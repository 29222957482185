export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    onChange: {
      type: Function
    }
  },
};
