import Section from '../../components/containers/section';
import RichText from '../../components/article/rich-text';
import dateFormat from '../../mixins/date-format';

export default {
  mixins: [dateFormat],
  props: {
  	title: {
  	  type: String,
  	},
    date: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  components: {
    Section,
    RichText,
  }
};
