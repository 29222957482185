//
import { applyPolyfills, defineCustomElements } from "../dist/basics/loader";

//
import Intersection from "./containers/intersection";

// components
import Logo from "./components/brand/logo";
import Button from "./components/cta/button";
import LangSwitch from "./components/cta/lang-switch";
import Cookies from "./components/info/cookies";
import Page, { StatePage } from "./components/layout/page";
import Hamburger from "./components/nav/hamburger";
import Menu from "./components/nav/menu";
import Time, { StateTime } from "./components/info/time";

// modules
import Error from "./modules/error";
import Footer from "./modules/footer";
import LongText from "./modules/long-text";
import OngoingEvents from "./modules/ongoing-events";
import EditionSelector from "./modules/edition-selector";
import EditionsList from "./modules/editions-list";
import EventsSlider from "./modules/events-slider";
import FeaturedEvent from "./modules/featured-event";
import PillarsList from "./modules/pillars-list";
import FilteredEvents from "./modules/filtered-events";
import Promo from "./modules/promo";
import TenAnniversary from "./modules/ten-anniversary";
import AboutUs from "./modules/about-us";
import ImageGrid from "./modules/image-grid";
import ImageGridModal from "./modules/image-grid-modal";
import MainHero from "./modules/main-hero";
import EventDetails from "./modules/event-details";
import Agenda from "./modules/agenda";
import Partners from "./modules/partners";
import Organizations from "./modules/organizations";
import EventStats from "./modules/event-stats";
import TextColumns from "./modules/text-columns";
import TextSlider from "./modules/text-slider";
import PageHero from "./modules/page-hero";
import ImagesSlider from "./modules/images-slider";
import EditionNavigation from "./modules/edition-navigation";
import PostsMosaic from "./modules/posts-mosaic";
import PostContent from "./modules/post-content";
import PostsSlider from "./modules/posts-slider";
import FeaturedPost from "./modules/featured-post";
import PostHero from "./modules/post-hero";
import Competitions from "./modules/challenges";
import ContactGrid from "./modules/contact-grid";
import HooksMenu from "./modules/hooks-menu";
import StoriesGrid from "./modules/stories-grid";
import NewsGrid from "./modules/news-grid";
import ResourcesList from "./modules/resources-list";
import VideoGrid from "./modules/video-grid";
import RichText from "./modules/rich-text";
import Columns from "./modules/columns";
import FeaturedItems from './modules/featured-items'

export {
  defineCustomElements,
  applyPolyfills,
  Intersection,
  Logo,
  Button,
  Cookies,
  Page,
  StatePage,
  Time,
  StateTime,
  Hamburger,
  Menu,
  LangSwitch,
  Error,
  Footer,
  LongText,
  PillarsList,
  FilteredEvents,
  OngoingEvents,
  EditionSelector,
  EditionsList,
  EventsSlider,
  FeaturedEvent,
  Promo,
  TenAnniversary,
  AboutUs,
  ImageGrid,
  ImageGridModal,
  MainHero,
  EventDetails,
  Agenda,
  Partners,
  Organizations,
  EventStats,
  TextSlider,
  TextColumns,
  PageHero,
  ImagesSlider,
  EditionNavigation,
  PostsMosaic,
  PostContent,
  PostsSlider,
  PostHero,
  FeaturedPost,
  Competitions,
  ContactGrid,
  HooksMenu,
  StoriesGrid,
  NewsGrid,
  ResourcesList,
  VideoGrid,
  RichText,
  Columns,
  FeaturedItems
};
