var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-about-us", attrs: { "data-background": "dark" } },
    [
      _c(
        "Section",
        {
          attrs: {
            pretitle: _vm.pretitle,
            "pretitle-color": "black",
            "pretitle-align": "center",
            spacing: "equidistant",
          },
        },
        [
          _c("div", { staticClass: "heading" }, [
            _c(
              "div",
              { staticClass: "heading-wrapper" },
              [
                _c(
                  "b-grid",
                  [
                    _c(
                      "b-cell",
                      { attrs: { xxs: "12" } },
                      [
                        _c(
                          _vm.headline,
                          { tag: "component", staticClass: "title" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.title) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "content-wrapper" }, [
              _c("p", { staticClass: "text" }, [
                _vm._v("\n          " + _vm._s(_vm.text) + "\n        "),
              ]),
              _vm._v(" "),
              !!_vm.button
                ? _c(
                    "div",
                    { staticClass: "cta" },
                    [
                      _vm.button.type === "regular"
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                href: _vm.button.href,
                                "on-click": _vm.onClick,
                                color: "transparent",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.button.label) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm.button.type === "icon"
                        ? _c(
                            "ArrowButton",
                            {
                              attrs: {
                                "arrow-type": "rounded",
                                "on-click": _vm.onClick,
                                href: _vm.button.href,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.button.label) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "media-content" },
            [_c("AnimatedGradient")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }