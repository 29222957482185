import Section from '../../components/containers/section';
import ArrowButton from '../../components/cta/arrow-button';
import Tag from '../../components/info/tag';
import Video from '../../components/media/video';
import Button from '../../components/cta/button';
// import dateFormat from '../../mixins/date-format';

export default {
  // mixins: [dateFormat],
  components: {
    Section,
    ArrowButton,
    Button,
    Tag,
    Video,
  },
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    text: {
      type: String,
    },
    media: {
      type: Object
    },
    pillars: {
      type: Array
    },
    button: {
      type: Object
    },
    show_overlay: {
      type: Boolean,
      default: true,
    },
    wrapper_type: {
      type: String,
    },
    onClick: {
      type: Function,
    },
    has_sound: {
      type: Boolean,
    },
  },
  computed: {
    isImage() {
      return this.media && this.media.mime && this.media.mime.startsWith('image');
    },
    isVideo() {
      return this.media && this.media.mime && this.media.mime.startsWith('video');
    },
  },
};
