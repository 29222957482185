import Section from '../../components/containers/section';
import RichText from '../../components/article/rich-text';

export default {
  components: {
    Section,
    RichText,
  },
  props: {
    content: {
      type: String,
    },
    rich_text_config: {
      type: Object,
    }
  },
};
