import EventAction from "../../components/cta/event-action";
import LocationTag from "../../components/info/location-tag";
import Tag from "../../components/info/tag";
import RichText from "../../components/article/rich-text";
import ArrowIcon from "./assets/arrow-embedded.svg";
import dateFormat from "../../mixins/date-format";
import ImageComponent from "../../components/media/image";

export default {
  mixins: [dateFormat],
  components: {
    EventAction,
    LocationTag,
    ArrowIcon,
    RichText,
    Tag,
    ImageComponent
  },
  props: {
    backlink: {
      type: String,
      required: true
    },
    backlinkHref: {
      type: String
    },
    title: {
      type: String
    },
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    description: {
      type: String
    },
    button: {
      type: Object
    },
    actions: {
      type: Array
    },
    featuredImage: {
      type: Object
    },
    location: {
      type: String
    },
    locationDetails: {
      type: String
    },
    map_url: {
      type: String
    },
    pillars: {
      type: Array
    },
    eventTypes: {
      type: Array
    },
    onClick: {
      type: Function
    },
    has_date: {
      type: Boolean
    },
    locale: {
      type: String
    }
  },
  computed: {
    hasLocation() {
      return !!this.location;
    },
    hasDescription() {
      return !!this.description;
    },
    hasMedia() {
      return this.featuredImage;
    },
    hasTags() {
      return this.pillars || this.eventTypes;
    },
    isImage() {
      return this.featuredImage.mime && this.featuredImage.mime.startsWith("image");
    },
    hasButton() {
      return !!this.button;
    },
    hasActions() {
      return !!this.actions && this.actions.length > 0;
    },
    dateString() {
      const { has_date } = this;

      const startDate = this.getDate(this.startDate, { has_date });
      const endDate = this.getDate(this.endDate, { has_date });

      return endDate ? `${startDate} - ${endDate}` : startDate;
    }
  },
  mounted() {
    if (this.$refs.backLink) {
      this.$refs.backLink.onClick = this.onClick;
    }
  }
};
