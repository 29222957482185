var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-youtube",
      attrs: { "data-aspect-ratio": _vm.aspect_ratio },
    },
    [
      _vm.isShowingVideo
        ? _c("iframe", {
            attrs: {
              src: _vm.embededURL,
              title: _vm.title,
              frameborder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "",
            },
          })
        : _c(
            "button",
            { staticClass: "button", on: { click: _vm.showVideo } },
            [
              _c("img", { attrs: { src: _vm.thumbnail, alt: _vm.alt } }),
              _vm._v(" "),
              _c("div", { staticClass: "icon" }, [_c("PlayIcon")], 1),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }