import Button from "../../components/cta/button";
import LocationTag from "../../components/info/location-tag";
import Tag from "../../components/info/tag";
import ArrowIcon from "./assets/arrow-embedded.svg";
import dateFormat from "../../mixins/date-format";
import ArrowButton from "../../components/cta/arrow-button";
import ImageComponent from "../../components/media/image";

export default {
  mixins: [dateFormat],
  data: () => ({
    scrollTo: null
  }),
  components: {
    Button,
    LocationTag,
    ArrowIcon,
    ArrowButton,
    Tag,
    ImageComponent
  },
  props: {
    backlink: {
      type: String,
      required: true
    },
    backlink_href: {
      type: String
    },
    title: {
      type: String
    },
    excerpt: {
      type: String
    },
    published_at: {
      type: String
    },
    featured_image: {
      type: Object
    },
    post_categories: {
      type: Array
    },
    pillars: {
      type: Array
    },
    onClick: {
      type: Function
    }
  },
  computed: {
    renderTags() {
      return (
        (this.post_categories && this.post_categories.length > 0) ||
        (this.pillars && this.pillars.length > 0)
      );
    }
  },
  methods: {
    scrollToContent() {
      if (this.scrollTo !== null) {
        this.scrollTo(0, this.$el.clientHeight);
      }
    }
  },
  mounted() {
    if (this.$refs.backLink) {
      this.$refs.backLink.onClick = this.onClick;
    }
    import("scroll-to").then(res => (this.scrollTo = res.default));
  }
};
