var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-pillars-list" },
    [
      _c(
        "Section",
        { attrs: { pretitle: _vm.pretitle, "with-border-top": "" } },
        [
          _c("div", { ref: "wrapp" }, [
            _c(
              "ul",
              { staticClass: "pillars" },
              _vm._l(_vm.pillars, function (pillar) {
                return _c(
                  "li",
                  {
                    key: pillar.slug,
                    staticClass: "pillar",
                    on: {
                      mouseenter: () => _vm.onMouseEnter(pillar),
                      mouseleave: _vm.resetPillar,
                    },
                  },
                  [
                    _c("PillarIntro", {
                      ref: "pillar",
                      refInFor: true,
                      attrs: {
                        title: pillar.title,
                        href: _vm.getPillarHref(pillar),
                        "on-click": _vm.onClick,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }