var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "m-event-details" }, [
    _c(
      "div",
      { staticClass: "event-contents" },
      [
        _c(
          "b-wrapper",
          [
            _c(
              "b-grid",
              [
                _c(
                  "b-cell",
                  { attrs: { xxs: "12" } },
                  [
                    !!_vm.backlinkHref
                      ? _c(
                          "b-clickable",
                          {
                            ref: "backLink",
                            attrs: {
                              href: _vm.backlinkHref,
                              "on-click": _vm.onClick,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "backlink" },
                              [
                                _c("ArrowIcon", {
                                  staticClass: "arrow",
                                  attrs: {
                                    "aria-hidden": "true",
                                    focusable: "false",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "text" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.backlink) +
                                      "\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-cell", { attrs: { xxs: "12", lg: "6" } }, [
                  _c("div", { staticClass: "event-heading" }, [
                    _vm.dateString
                      ? _c("p", { staticClass: "subtitle" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.dateString) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.title) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.hasLocation
                    ? _c(
                        "div",
                        { staticClass: "event-location" },
                        [
                          _c("LocationTag", {
                            attrs: {
                              location: _vm.location,
                              details: _vm.locationDetails,
                              href: _vm.map_url,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.description
                    ? _c(
                        "div",
                        { staticClass: "event-description" },
                        [
                          _c("RichText", {
                            attrs: { content: _vm.description },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "event-footer" },
      [
        _c(
          "b-wrapper",
          [
            _c(
              "b-grid",
              [
                _c("b-cell", { attrs: { xxs: "12", sm: "9" } }, [
                  _vm.hasTags
                    ? _c(
                        "div",
                        { staticClass: "event-tags" },
                        [
                          !!_vm.pillars
                            ? _vm._l(
                                _vm.pillars,
                                function (pillar, pillarIndex) {
                                  return _c(
                                    "span",
                                    { key: `pillar-${pillarIndex}` },
                                    [
                                      _c(
                                        "Tag",
                                        { attrs: { color: pillar.color } },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(pillar.title) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !!_vm.eventTypes
                            ? _vm._l(
                                _vm.eventTypes,
                                function (eventType, eventIndex) {
                                  return _c(
                                    "span",
                                    { key: `event-${eventIndex}` },
                                    [
                                      _c("Tag", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(eventType.title) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "event-cta" },
                    [
                      _vm.hasActions
                        ? _vm._l(_vm.actions, function (action, actionIndex) {
                            return _c(
                              "div",
                              { key: `action-${actionIndex}` },
                              [
                                _c(
                                  "EventAction",
                                  {
                                    attrs: {
                                      href: action.href,
                                      size: "large",
                                      type:
                                        actionIndex == 0 ? "regular" : "link",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(action.label) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasButton && !_vm.hasActions
                        ? [
                            _c(
                              "EventAction",
                              {
                                attrs: { href: _vm.button.href, size: "large" },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.button.label) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.hasMedia
      ? _c(
          "div",
          { staticClass: "event-media" },
          [
            _c("b-wrapper", { attrs: { type: "desktop-full" } }, [
              _c(
                "div",
                { staticClass: "media-wrapper" },
                [
                  _c("div", { staticClass: "overlay" }),
                  _vm._v(" "),
                  _vm.isImage
                    ? _c("ImageComponent", {
                        attrs: { "lazy-load": true, images: _vm.featuredImage },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }