import Section from '../../components/containers/section';
import Button from '../../components/cta/button';
import Tag from '../../components/info/tag';
import Video from '../../components/media/video';

export default {
  components: {
    Section,
    Button,
    Tag,
    Video,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    pretitle: {
      type: String,
    },
    text: {
      type: String,
    },
    firstTagText: {
      type: String,
    },
    secondTagText: {
      type: String,
    },
    media: {
      type: Object
    },
    pillars: {
      type: Array
    },
    button: {
      type: Object,
    },
    wrapper_type: {
      type: String,
    },
    onClick: {
      type: Function,
    },
    has_sound: {
      type: Boolean,
    },
  },
  computed: {
    isImage() {
      return this.media.mime && this.media.mime.startsWith('image');
    },
    isVideo() {
      return this.media && this.media.mime && this.media.mime.startsWith('video');
    },
  },
};
