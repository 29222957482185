var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-video",
      attrs: { "data-aspect-ratio": _vm.aspect_ratio },
    },
    [
      _c(
        "Intersection",
        {
          attrs: {
            "root-margin": "10% 0px 10% 0px",
            "on-leave": _vm.muteSound,
          },
        },
        [
          _c("div", { staticClass: "video-wrapper" }, [
            _vm.use_sound_button
              ? _c(
                  "div",
                  { staticClass: "mute-icon" },
                  [
                    _c(
                      "b-clickable",
                      {
                        ref: "sound",
                        attrs: { "on-click": _vm.toggleVideoSound },
                      },
                      [_c(_vm.soundIcon, { tag: "component" })],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "video",
              _vm._b(
                {
                  ref: "video",
                  attrs: { src: _vm.src, caption: _vm.caption, alt: _vm.alt },
                  domProps: { muted: _vm.isVideoMuted },
                },
                "video",
                _vm.settings,
                false
              )
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }