var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "m-error" }, [
    _c("div", { staticClass: "error-content" }, [
      _c("h1", { staticClass: "message" }, [
        _vm._v("\n      " + _vm._s(_vm.message) + "\n    "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "subtitle" }, [
        _vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }