import Section from '../../components/containers/section';
import IconMessage from '../../components/cta/icon-message';
import AgendaCover from '../../components/covers/agenda-cover';
import Tag from '../../components/info/tag';
import rightToLeft from '../../mixins/right-to-left';

export default {
  mixins: [rightToLeft],
  components: {
    Section,
    IconMessage,
    AgendaCover,
    Tag,
  },
  props: {
    pretitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    days: {
      type: Array,
    },
    label: {
      type: String,
    }
  },
  methods: {
    defineBackground(position) {
      return position % 2 === 0 ? 'dark' : null
    },

    sessionTime(start, end) {
      if (start && end) {
        return start + ' - ' + end;
      } else if (start || end ) {
        return start || end;
      } else {
        return null;
      }
    },

    // maps props to object
    agendaCoverProps(slot) {
      return {
        'session-title': slot.title,
        'session-details': slot.details,
        'time': this.sessionTime(slot.start_time, slot.end_time)
      }
    }
  },

  computed: {
    agendaSlots() {
      const reducer = (acc, day) => (day.items.length > acc)? day.items.length : acc;
      // this will count the max num of items on one day
      const maxSlots = this.days.reduce(reducer, 0);

      const emptySlot = (id) => {
        return {
          id,
          title: '-',
          details: '-',
        }
      };

      return Array.from(new Array(maxSlots))
        .map(
          (slot, i) => this.days.map(
            day => ( day.items[i] || emptySlot(i) )
          )
        )
    },

    hasDayContents() {
      return this.days.length > 0;
    }
  }
};
