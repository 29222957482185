export default {
  data() {
    return {
      addedListener: false,
    }
  },
  methods: {
    reducedMotion() {
      if (typeof window !== undefined) {

        return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
      }
    },

    isDesktop() {
      if (typeof window !== 'undefined') {
        return window.matchMedia('(min-width: 768px)').matches;
      }

      return false;
    },

    mouseMove(e){
      const root = this.$el;
      const canvas = this.$refs.canvas;
      const rect = root.getBoundingClientRect();

      let x = 100 * e.clientX/rect.width;
      let y = 100 * e.clientY/rect.height;

      canvas.style.setProperty('--x', x + '%');
      canvas.style.setProperty('--y', y + '%');
    }
  },

  mounted() {
    // add only the mouse effect in desktop,
    // also inDestop also controlls if we're in SSR 
    if (!this.reducedMotion() && this.isDesktop()) {
      document.addEventListener('mousemove', this.mouseMove);
      this.addedListener = true;
    }
  },

  beforeDestroy() {
    // if the event is added remove it
    if (this.addedListener && typeof document !== 'undefined') {
      document.removeEventListener('mousemove', this.mouseMove)
    }
  }
};
