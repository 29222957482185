var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-page",
      attrs: {
        "data-is-header-visible": _vm.isHeaderVisible,
        "data-is-nav-visible": _vm.isNavVisible,
        "data-is-header-narrow": _vm.isScrolled,
        "data-has-hero": _vm.hasHero,
        "data-is-header-hovered": _vm.isHeaderHovered,
        "data-background": _vm.navBackground,
        "data-light-header": _vm.lightHeader,
      },
    },
    [
      _c("div", { staticClass: "marquee" }, [_vm._t("marquee")], 2),
      _vm._v(" "),
      _c(
        "header",
        {
          staticClass: "header",
          attrs: { role: "banner" },
          on: { mouseover: _vm.mouseOverHeader, mouseout: _vm.mouseOverHeader },
        },
        [
          _c("b-wrapper", { attrs: { type: "full" } }, [
            _c(
              "div",
              {
                staticClass: "header-wrapper",
                attrs: {
                  "data-background":
                    _vm.isScrolled || _vm.lightHeader ? "light" : "dark",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "skip-to-content c-button",
                    attrs: { href: "#content" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.skipToContentLabel) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "brand" }, [_vm._t("brand")], 2),
                _vm._v(" "),
                _c(
                  "div",
                  { ref: "nav", staticClass: "nav" },
                  [
                    _c("b-wrapper", { attrs: { type: "desktop-full" } }, [
                      _c("div", { staticClass: "menu" }, [
                        _c(
                          "div",
                          { staticClass: "nav-wrapper" },
                          [_vm._t("nav")],
                          2
                        ),
                        _vm._v(" "),
                        _vm.hasDate
                          ? _c(
                              "div",
                              { staticClass: "date" },
                              [_vm._t("date")],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "lang" }, [_vm._t("lang")], 2),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "hamburger",
                    attrs: { "aria-expanded": _vm.isNavVisible.toString() },
                    on: { click: _vm.toggleNav },
                  },
                  [
                    _vm._t("hamburger"),
                    _vm._v(" "),
                    _c("span", { staticClass: "hidden-label" }, [
                      _vm._v(_vm._s(_vm.navLabel)),
                    ]),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasHero
        ? _c("div", { staticClass: "hero" }, [_vm._t("hero")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "loader" },
        [
          _c("Loader", {
            attrs: { label: _vm.loaderLabel, loading: _vm.loading },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("main", { staticClass: "content" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("footer", { staticClass: "footer" }, [_vm._t("footer")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "cookies" }, [_vm._t("cookies")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }