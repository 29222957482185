import Dropdown from "../dropdown/index"

export default {
  components: {
    Dropdown
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
    },
    seeAll: {
      type: String
    }
  },
  methods: {
    emitDropdown(event) {
      this.$emit('dropdown', event)
    }
  },
};
