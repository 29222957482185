var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-columns" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            "with-border-top": "",
          },
        },
        [
          _c(
            "b-grid",
            _vm._l(_vm.columns, function (column, index) {
              return _c(
                "b-cell",
                _vm._b(
                  { key: `column-${index}` },
                  "b-cell",
                  _vm.cellSize,
                  false
                ),
                [_c("RichText", { attrs: { content: column.content } })],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }