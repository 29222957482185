let scrollPosition = 0;


export default {
  data() {
    return {
      isNavVisible: false,
      isScrolled: false,
      isHeaderVisible: true,
      headerThreshold: 200,
      isHeaderHovered: false
    };
  },
  methods: {
    toggleNav() {
      this.isNavVisible = !this.isNavVisible;
    },
    closeNav() {
      this.isNavVisible = false;
    },
    mouseOverHeader() {
      this.isHeaderHovered = !this.isHeaderHovered;
    },
    toggleHeader(top, pageYOffset) {
      const scrolled = top - scrollPosition;

      //
      if (scrolled > 0) {
        if (!this.isHeaderVisible) {
          this.isHeaderVisible = true;
        }
      } else {
        if (
          this.isHeaderVisible &&
          pageYOffset > this.headerThreshold &&
          !this.isNavVisible &&
          !this.isHeaderHovered
        ) {
          this.isHeaderVisible = false;
        }
      }

      //
      if (pageYOffset > 0) {
        if (!this.isScrolled) {
          this.isScrolled = true;
        }
      } else {
        if (this.isScrolled) {
          this.isScrolled = false;
        }
      }

      scrollPosition = top;
    },
  },
  computed: {
  },
  render() {
    return this.$scopedSlots.default({
      isHeaderVisible: this.isHeaderVisible,
      isNavVisible: this.isNavVisible,
      isScrolled: this.isScrolled,
      toggleNav: this.toggleNav,
      closeNav: this.closeNav,
      toggleHeader: this.toggleHeader,
      acceptCookies: this.acceptCookies,
      isHeaderHovered: this.isHeaderHovered,
      mouseOverHeader: this.mouseOverHeader
    });
  }
};
