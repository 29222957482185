var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-user-dropdown", attrs: { "data-opened": _vm.opened } },
    [
      _c(
        "b-clickable",
        {
          ref: "clickable",
          staticClass: "user-name",
          attrs: { type: "button", "aria-expanded": _vm.opened.toString() },
        },
        [
          _c("span", [_vm._v(" " + _vm._s(_vm.userName) + " ")]),
          _vm._v(" "),
          _c("ArrowDown"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown-menu" },
        [
          _c("b-wrapper", { attrs: { type: "desktop-full" } }, [
            _c(
              "div",
              { staticClass: "menu-wrapper" },
              [
                _c(
                  "b-clickable",
                  {
                    ref: "close",
                    staticClass: "close",
                    attrs: { type: "button" },
                  },
                  [_c("ArrowLeft")],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "options" }, [
                  _c(
                    "ul",
                    { attrs: { role: "menu" } },
                    _vm._l(_vm.links, function ({ href, anchor, flagged }) {
                      return _c(
                        "li",
                        {
                          key: href,
                          staticClass: "menu-item",
                          attrs: { "data-flagged": flagged, role: "menuitem" },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: href },
                              on: {
                                click: (e) => {
                                  if (_vm.onClick) {
                                    e.preventDefault()
                                    _vm.onClick(href, e)
                                  }
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(anchor) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }