var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(3, function (index) {
        return _c(
          "div",
          { key: index, staticClass: "marquee-text" },
          _vm._l(_vm.items, function (item, i) {
            return _c(
              "a",
              {
                key: i,
                staticClass: "event-container",
                attrs: { href: item.url || item.href },
              },
              [
                item.featured_image
                  ? _c("div", { staticClass: "img-pill" }, [
                      _c("img", {
                        attrs: {
                          src: item.featured_image.formats?.thumbnail
                            ? item.featured_image.formats.thumbnail.url
                            : item.featured_image.url,
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.start_date
                  ? _c("span", { staticClass: "event-date" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.start_date.replace(new RegExp(/-/, "g"), "/")
                          )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.title
                  ? _c("span", { staticClass: "event-title" }, [
                      _vm._v(" " + _vm._s(item.title) + "."),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.label
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(" " + _vm._s(item.label)),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }