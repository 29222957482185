import StateUserDropdown from './state-manager';
import ArrowLeft from './assets/arrow-left-embedded.svg';
import ArrowDown from './assets/arrow-down-embedded.svg';

export default {
  components: {
    ArrowLeft,
    ArrowDown,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
    },
    onToggle: {
      type: Function,
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onToggle;
    this.$refs.close.onClick = this.onToggle;
  },
};

export { StateUserDropdown };
