export default {
  props: {
    sessionTitle: {
      type: String
    },
    sessionDetails: {
      type: String
    },
    dayTitle: {
      type: String
    },
    time: {
      type: String,
    },
    background: {
      type: String,
    },
  },
};
