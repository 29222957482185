var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-arrow-button",
      attrs: {
        "data-arrow-type": _vm.arrowType,
        "data-arrow-direction": _vm.arrowDirection,
        "data-color": _vm.color,
        "data-clickable": !!(_vm.onClick && _vm.href),
      },
    },
    [
      _c(
        "b-clickable",
        { ref: "clickable", attrs: { href: _vm.href, type: "button" } },
        [
          _c("span", { staticClass: "content" }, [
            _c("span", { staticClass: "label" }, [_vm._t("default")], 2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "arrow-icon" },
              [
                _c(_vm.iconDesktop(), {
                  tag: "component",
                  staticClass: "icon-desktop",
                  attrs: { "aria-hidden": "true", focusable: "false" },
                }),
                _vm._v(" "),
                _c(_vm.iconMobile(), {
                  tag: "component",
                  staticClass: "icon-mobile",
                  attrs: { "aria-hidden": "true", focusable: "false" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }