var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-rich-text" }, [
    _vm.$slots.default
      ? _c(
          "div",
          {
            ref: "content",
            staticClass: "content",
            attrs: { "data-type": _vm.type },
          },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    this.content
      ? _c("div", {
          ref: "content",
          staticClass: "content",
          attrs: { "data-type": _vm.type },
          domProps: { innerHTML: _vm._s(_vm.sanitizedContent) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }