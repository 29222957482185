import StateSelectDropdown from './state-manager';
import Arrow from './assets/arrow-embedded.svg';
import ArrowMobile from './assets/arrow-mobile-embedded.svg';


export default {
  components: {
    Arrow,
    ArrowMobile
  },
  props: {
    options: {
      type: Array,
    },
    onClick: {
      type: Function,
    },
    selectOption: {
      type: Function,
    },
    selected: {
      type: Object,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
    }
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    defaultOption() {
      return this.options[0]
    }
  },
  methods: {
    onToggle() {
      this.opened = !this.opened
    },
    async showOptions() {
      this.opened = true;
      await this.$nextTick();
      this.$refs.button.focus();
    },
    async reset() {
      this.opened = false;
      await this.$nextTick();
      // this.$refs.button.focus();
    },
    handleBlur(e) {
      if (this.$el.contains(e.relatedTarget)) return;
      this.opened = false;
    },
  }
};

export { StateSelectDropdown };
