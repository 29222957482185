// DEPRECATED
//use nuxt/stencil depencency check nuxt.config.js file
import Vue from 'vue'
import { applyPolyfills, defineCustomElements } from '@yoc/ui'

Vue.config.ignoredElements = [/b-\w*/]

applyPolyfills().then(() => {
  Vue.use(defineCustomElements)
})
