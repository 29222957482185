
const toNumber = (str) => {
  if (!str) return undefined;
  return parseFloat(str.replace(/[,\.]/g, ''));
};

export default {
  data() {
    return {
      timerId: null,
      value: 0,
    }
  },
  props: {
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    duration: {
      type: String,
      default: '1000'
    },
    paused: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    prefersReducedMotion() {
      if (typeof window !== undefined) {
        return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
      } else {
        return true;
      }
    },
    animate() {
      const interval = 50;
      const ticks = toNumber(this.duration) / interval;
      const increment = (toNumber(this.to) - toNumber(this.from)) / ticks;

      if (this.timerId) {
        clearInterval(this.timerId);
      }

      // repeat with the interval
      this.timerId = setInterval(
        () => {
          this.value += increment;
          this.value = Math.ceil(this.value);
        }
      , interval);

      // after duration in  miliseconds stop
      setTimeout(() =>  {
        clearInterval(this.timerId)
        this.value = toNumber(this.to);
      }, this.duration);
    },


    play() {
      if (this.prefersReducedMotion()) {
        this.value = toNumber(this.to);
      } else {
        // animate
        this.value = toNumber(this.from);

        this.animate();
      }
    }
  },
  computed:{
    currentValue() {
      return this.value?.toLocaleString();
    }
  },

  mounted() {
    if (this.paused === false) {
      this.play();
    }
  },

  beforeDestroy() {
    clearInterval(this.timerId);
  }
};

