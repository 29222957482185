import PlayIcon from './assets/play-embedded.svg';

export default {
  components: {
    PlayIcon,
  },
  props: {
    src: {
      type: String,
    },
    title: {
      type: String,
      default: 'YouTube video player',
    },
    thumbnail: {
      type: String,
    },
    alt: {
      type: String,
    },
    aspect_ratio: {
      type: String,
      default: '16/9',
    },
    settings: {
      // Can be any of this https://developers.google.com/youtube/player_parameters
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isShowingVideo: false,
  }),
  computed: {
    params() {
      return new URLSearchParams(this.settings).toString();
    },
    embededURL() {
      return `https://www.youtube.com/embed/${this.getYoutubeId(this.src)}/${this.params}?autoplay=1`;
    },
  },
  methods: {
    getYoutubeId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    },
    showVideo() {
      this.isShowingVideo = true;
    }
  },
  beforeMount() {
    if (typeof window !== "undefined") {
      this.isShowingVideo = !this.thumbnail;
    }
  },
};
