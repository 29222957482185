import Section from '../../components/containers/section';
import AnimatedGradient from '../../components/visual/animated-gradient';
import ArrowButton from '../../components/cta/arrow-button';
import Button from '../../components/cta/button';

export default {
  components: {
    Section,
    AnimatedGradient,
    ArrowButton,
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    pretitle: {
      type: String,
    },
    text: {
      type: String,
    },
    button: {
      type: Object
    },
    headlineLevel: {
      type: Number,
      default: 2,
    },
    onClick: {
      type: Function,
    }
  },
  computed: {
    headline() {
      return 'h' + this.headlineLevel;
    }
  },
};
