var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.events && _vm.events.length > 0
    ? _c(
        "div",
        {
          staticClass: "c-featured-items-container",
          on: { touchstart: _vm.touchStart, touchend: _vm.touchEnd },
        },
        [
          _c("div", { ref: "info", staticClass: "info" }, [
            _c("div", { staticClass: "header" }, [
              _c("p", { staticClass: "edition" }, [
                _vm._v("\n        " + _vm._s(_vm.subtitle) + "\n      "),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "module-title" }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "module-content" },
              [
                this.events.length > 1
                  ? _c("div", { staticClass: "arrows" }, [
                      _c(
                        "button",
                        {
                          staticClass: "arrow-button",
                          on: { click: _vm.prevItem },
                        },
                        [_c("LeftArrow")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "arrow-button",
                          on: { click: _vm.nextItem },
                        },
                        [_c("RightArrow")],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
                  _c(
                    "div",
                    {
                      key: _vm.currentItem,
                      staticClass: "module-content-inner",
                    },
                    [
                      _c(
                        "b-clickable",
                        {
                          attrs: {
                            href: _vm.events[_vm.currentItem].url || "",
                          },
                        },
                        [
                          _c("h2", { staticClass: "event-title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.events[_vm.currentItem].title) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "event-info" },
                        [
                          _vm.events[_vm.currentItem].start_date
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.events[
                                        _vm.currentItem
                                      ].start_date.replace(
                                        new RegExp(/-/, "g"),
                                        "/"
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.events[_vm.currentItem].location
                            ? _c("LocationTag", {
                                attrs: {
                                  location:
                                    _vm.events[_vm.currentItem].location
                                      .location,
                                  href: _vm.events[_vm.currentItem].location
                                    .map_url,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "tags" }, [
                        _vm.events[_vm.currentItem].pillars
                          ? _c(
                              "div",
                              { staticClass: "inner-tags" },
                              _vm._l(
                                _vm.events[_vm.currentItem].pillars,
                                function (pillar, idx) {
                                  return _c(
                                    "span",
                                    {
                                      key: `pillar-${idx}`,
                                      staticClass: "tag",
                                    },
                                    [
                                      _c(
                                        "Tag",
                                        {
                                          attrs: {
                                            color: pillar.color,
                                            "no-border": true,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(pillar.title) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.events[_vm.currentItem].event_types
                          ? _c(
                              "div",
                              { staticClass: "inner-tags" },
                              _vm._l(
                                _vm.events[_vm.currentItem].event_types,
                                function (type, idx) {
                                  return _c(
                                    "span",
                                    { key: `type-${idx}`, staticClass: "tag" },
                                    [
                                      _c("Tag", { attrs: { type: true } }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(type.title) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
            _c(
              "div",
              { key: _vm.currentItem, staticClass: "image" },
              [
                _c(
                  "b-clickable",
                  { attrs: { href: _vm.events[_vm.currentItem].url } },
                  [_c("div", { staticClass: "overlay" }, [_c("ZigZag")], 1)]
                ),
                _vm._v(" "),
                _c("ImageComponent", {
                  attrs: {
                    images: _vm.events[_vm.currentItem].featured_image,
                    "class-name": "event-image",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }