var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-sticky-element",
      style: { "--target-height": `${_vm.targetHeight}px` },
      attrs: { "data-fixed": _vm.isFixed },
    },
    [
      _c(
        "Intersection",
        { attrs: { "on-enter": _vm.onEnter, "on-leave": _vm.onLeave } },
        [_c("div", { staticClass: "trigger" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "target", staticClass: "sticky-target" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }