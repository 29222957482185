import DefinitionSlide from '../../components/info/definition-slide';
import Section from '../../components/containers/section';
import rightToLeft from '../../mixins/right-to-left';

export default {
  mixins: [rightToLeft],
  components: {
    DefinitionSlide,
    Section,
  },
  data: () => ({
    activeIndex: 0,
  }),
  props: {
    pretitle: {
      type: String,
    },
    description: {
      type: String,
    },
    slides: {
      type: Array,
    },
  },
  methods: {
    setActiveIndex(index) {
      this.activeIndex = index;
    },
    bpOptions(value) {
      return {
        xxs: value ? 9 : 10,
        sm: value ? 7 : 7,
        md: value ? 5 : 7,
      }
    }
  },
  mounted() {
    this.$refs.slider.onSelect = this.setActiveIndex;
  },
};
