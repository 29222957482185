var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-pretitle",
      attrs: {
        "data-color": _vm.color,
        "data-with-border": _vm.withBorder,
        "data-spaced-out": _vm.spacedOut,
      },
    },
    [
      _c("b-wrapper", [
        !!_vm.href
          ? _c(
              "a",
              { staticClass: "pretitle-content", attrs: { href: _vm.href } },
              [
                _c("ArrowIcon", {
                  staticClass: "arrow",
                  attrs: { "aria-hidden": "true", focusable: "false" },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
                ]),
              ],
              1
            )
          : !!_vm.onClick
          ? _c(
              "div",
              {
                staticClass: "pretitle-content",
                attrs: { "on-click": "onClick" },
              },
              [
                _c("ArrowIcon", {
                  staticClass: "arrow",
                  attrs: { "aria-hidden": "true", focusable: "false" },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
                ]),
              ],
              1
            )
          : _c("p", { staticClass: "text" }, [
              _vm._v("\n      " + _vm._s(_vm.text) + "\n    "),
            ]),
        _vm._v(" "),
        _vm.hasTags
          ? _c("div", { staticClass: "tags" }, [_vm._t("tags")], 2)
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.withBorder
        ? _c("div", { staticClass: "hr" }, [
            _c("span", { staticClass: "border" }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }