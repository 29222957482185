var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-input-search" }, [
    _c("div", { staticClass: "input-wrapper" }, [
      _c("input", {
        staticClass: "input",
        attrs: { type: "text", placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          change: (e) => _vm.onChange && _vm.onChange(e.target.value, e),
          input: (e) => _vm.onInput && _vm.onInput(e.target.value, e),
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        [
          _c("SearchIcon", {
            staticClass: "search",
            attrs: { role: "img", "area-label": "decoration" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }