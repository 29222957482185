var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-input-dropdown",
      attrs: { "data-is-error": _vm.error || !!_vm.errorMessage },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "label", attrs: { for: _vm.id } }, [
            _c("span", { staticClass: "text" }, [
              _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
            ]),
            _vm._v(" "),
            _vm.errorMessage
              ? _c("span", { staticClass: "error-message" }, [
                  _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "select",
        {
          staticClass: "select",
          attrs: { id: _vm.id },
          on: {
            change: (e) => _vm.onChange && _vm.onChange(e.target.value, e),
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c(
            "option",
            {
              key: option.value,
              domProps: {
                value: option.value,
                selected: _vm.value === option.value,
              },
            },
            [_vm._v("\n      " + _vm._s(option.text) + "\n    ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }