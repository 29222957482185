import ArrowButton from '../../components/cta/arrow-button';
import Definition from '../../components/info/definition-slide';
import Section from '../../components/containers/section';

export default {
  components: {
    ArrowButton,
    Definition,
    Section,
  },
  props: {
    previous_edition: {
      type: Object,
    },
    next_edition: {
      type: Object,
    },
    onClick: {
      type: Function,
    },
  },
  mounted() {
    if (this.$refs.next) this.$refs.next.onClick = this.onClick;
    if (this.$refs.previous) this.$refs.previous.onClick = this.onClick;
  }
};
