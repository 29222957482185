import camelCase from 'camelcase'
import injectMeta from './seo'
import { injectSettingsIntoModule } from './settings'
export default {
  injectMeta,
  injectSettingsIntoModule,
  /**
   * getQueryParams
   * @param query
   */
  getQueryParams(query) {
    let params = ''
    Object.keys(query).map((key, i) => {
      if (i === 0) params = '?'
      params = params.concat(key + '=' + query[key])
    })
    return params
  },

  /**
   * Given a $route.params object, returns the slug that will be used against the API
   *
   * @param {*} { pathMatch }
   * @returns
   */
  getSlug({ entity, slug }, locale) {
    let ent = 'pages'
    let sl = 'home'

    if (slug === undefined && entity !== undefined) {
      // it's because it's a page so we shift
      sl = entity
    }

    if (slug !== undefined) {
      // it's not a page.
      sl = slug
      ent = entity
    }

    return { entity: ent, slug: sl }
  },

  getModuleName(orginal) {
    return orginal.split('.')[1]
  },
  isHome(route, locale) {
    return route.path === '/' || route.path === `/${locale}`
  },
}
