var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-item-details" },
    [
      _c(
        "Section",
        { attrs: { spacing: "lg" } },
        [
          _c("EntityDetails", {
            attrs: {
              title: _vm.title,
              subtitle: _vm.subtitle,
              text: _vm.description,
              image: _vm.image,
            },
            scopedSlots: _vm._u(
              [
                _vm.primaryAnchor && _vm.primaryHref
                  ? {
                      key: "cta",
                      fn: function () {
                        return [
                          _c(
                            "Button",
                            {
                              attrs: {
                                "on-click": _vm.onClick,
                                href: _vm.primaryHref,
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.primaryAnchor) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }