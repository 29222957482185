import Section from "../../components/containers/section";
import RichText from "../../components/article/rich-text";

export default {
  components: {
    Section,
    RichText
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    content: {
      type: String,
      required: true,
    }
  }
};
