import Button from '../../components/cta/button';
import ArrowButton from '../../components/cta/arrow-button';
import Logo from '../../components/brand/logo';
import AnimatedGradient from '../../components/visual/animated-gradient';

export default {
  components: {
    Button,
    ArrowButton,
    Logo,
    AnimatedGradient,
  },
  data: () => ({
    scrollTo: null,
    loading: true,
  }),
  props: {
    title: {
      type: String,
    },
    titleContent: {
      type: String,
    },
    date: {
      type: String,
    },
    button: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
    },
  },
  methods: {
    scrollToContent() {
      if (this.scrollTo !== null) {
        this.scrollTo(0, this.$el.clientHeight);
      }
    },
    onLoad() {
      this.loading = false;
    },
    onLoaded() {
      setTimeout(()=> { this.onLoad() }, 300);
    },
  },
  mounted() {
    import('scroll-to').then((res) => this.scrollTo = res.default);

    this.onLoaded();
  }
};
