import ImageComponent from "../../media/image";

export default {
  components: {
    ImageComponent,
  },
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    description: {
      type: String,
    },
    image: {
      type: String,
    },
    images: {
      type: Object,
    },
    alt: {
      type: String,
    },
    caption: {
      type: String,
    },
    href: {
      type: String,
      default: null,
    },
    onClick: {
      type: Function,
    },
    titleLines: {
      type: Number,
    },
    imageRatio: {
      type: String,
      default: "1/1",
    },
  },
  mounted() {
    this.$refs.clickable.onClick = this.onClick;
    this.$refs.image.onClick = this.onClick;
  },
  computed: {
    hasCta() {
      return !!this.$slots.cta;
    },
    hasTags() {
      return !!this.$slots.tags;
    },
    hasLocation() {
      return !!this.$slots.location;
    },
  },
};
