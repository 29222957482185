var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-news-grid" },
    [
      _c(
        "Section",
        {
          attrs: {
            pretitle: _vm.pretitle,
            title: _vm.title,
            "with-border-top": "",
          },
        },
        [
          _c(
            "b-grid",
            _vm._l(_vm.currentNews, function (item, index) {
              return _c(
                "b-cell",
                {
                  key: item.id,
                  attrs: { xxs: "12", sm: "6", md: index <= 1 ? 6 : 4 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "news-cell" },
                    [
                      _c("CardCover", {
                        attrs: {
                          href: item.url,
                          images: item.featured_image && item.featured_image,
                          alt:
                            item.featured_image &&
                            item.featured_image.alternativeText,
                          title: item.title,
                          "on-click": _vm.onClick,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.news && _vm.newsPerPage && _vm.paginationTotal > 1
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("Pagination", {
                    attrs: {
                      "aria-prev": "prev",
                      "aria-next": "next",
                      "current-page": _vm.currentPage + 1,
                      total: _vm.paginationTotal,
                      "on-click": (i) => (this.currentPage = i - 1),
                      "on-prev-click": () =>
                        (this.currentPage = this.currentPage - 1),
                      "on-next-click": () =>
                        (this.currentPage = this.currentPage + 1),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }