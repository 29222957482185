import LocationIcon from './assets/location-icon-embedded.svg';

export default {
  components: {
    LocationIcon
  },
  props: {
    location: {
      type: String,
      required: true,
    },
    details: {
      type: String,
    },
    href: {
      type: String,
    }
  }
};
