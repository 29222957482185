import Button from '../../components/cta/button';
import ChallengeCover from "../../components/covers/challenge-cover";
import Pagination from '../../components/nav/pagination';
import Section from "../../components/containers/section";
import dateFormat from '../../mixins/date-format';

export default {
  mixins: [dateFormat],
  components: {
    Button,
    Section,
    Pagination,
    ChallengeCover,
  },
  data: () => ({
    currentPage: 0,
  }),
  props: {
    pretitle: {
      type: String
    },
    title: {
      type: String
    },
    items: {
      type: Array,
      required: true
    },
    statusFinalized: {
      type: String
    },
    buttonText: {
      type: String
    },
    onClick: {
      type: Function
    },
    paginationLimit: {
      type: Number,
      default: 8,
    }
  },
  computed: {
    currentItems() {
      return this.items.slice(this.paginationLimit * this.currentPage, this.paginationLimit * (this.currentPage + 1));
    },
    paginationTotal() {
      return Math.ceil(this.items.length / this.paginationLimit);
    }
  },
  methods: {
    isFinalized(item) {
      const endDate = this.getDate(item.end_date, { has_date: true })
      const today = new Date().toISOString().split('T')[0].replace(/-/g, '/');

      return today > endDate;
    },
    formatChallengeStatusString(item) {
      const startDate = this.getDate(item.start_date, { has_date: true });
      const endDate = this.getDate(item.end_date, { has_date: true });

      return this.isFinalized(item) ? this.statusFinalized : `${startDate} - ${endDate}`;
    },
  }
};
