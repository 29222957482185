
import Section from '../../components/containers/section';
import Button from '../../components/cta/button';

export default {
  components: {
    Section,
    Button,
  },
  data: () => ({
    useShowMore: false,
    showingMore: false,
    maxLines: {
      xxs: 15,
      lg: 10,
      xxl: 10,
    },
  }),
  props: {
    pretitle: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    show_more_text: {
      type: String,
    },
    show_less_text: {
      type: String,
    },
    allow_show_more_button: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
      default: 'full',
    },
  },
  methods: {
    setCssProperties() {
      Object.keys(this.maxLines).forEach((bp) => {
        this.$el.style.setProperty(`--max-lines-${bp}`, this.maxLines[bp]);
      });
    },
    toggleShowingMore() {
      this.showingMore = !this.showingMore;
    },
    onWindowResize() {
      const { text } = this.$refs;

      if (text) {
        if (window.matchMedia('(min-width: 1480px)').matches) {
          this.useShowMore = text.offsetHeight > this.maxLines.xxl * 32;
        } else if (window.matchMedia('(min-width: 1020px)').matches) {
          this.useShowMore = text.offsetHeight > this.maxLines.lg * 24;
        } else {
          this.useShowMore = text.offsetHeight > this.maxLines.xxs * 24;
        }
      }
    },
  },
  mounted() {
    this.setCssProperties();
    setTimeout(() => this.onWindowResize(), 100);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onWindowResize);
    }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onWindowResize);
    }
  },
};
