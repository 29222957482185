export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    text: {
      type: String,
    },
    image: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  computed: {
    hasCta() {
      return !!this.$slots.cta;
    },
    hasSecondaryCta() {
      return !!this.$slots.secondarycta;
    },
  },
};
