var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-filter" }, [
    _c("input", {
      attrs: {
        type: _vm.type,
        id: _vm.id,
        name: _vm.name || _vm.id,
        disabled: _vm.disabled,
      },
      domProps: { checked: _vm.checked },
      on: { change: (e) => _vm.onChange && _vm.onChange(e.target.checked, e) },
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.id } }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }