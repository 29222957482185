var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-input-checkbox",
      attrs: { "data-is-error": _vm.error || !!_vm.errorMessage },
    },
    [
      _c("input", {
        staticClass: "input",
        attrs: { type: "checkbox", id: _vm.id },
        domProps: { checked: _vm.checked },
        on: {
          change: (e) => _vm.onChange && _vm.onChange(e.target.checked, e),
        },
      }),
      _vm._v(" "),
      _c("label", { staticClass: "label", attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.errorMessage || _vm.label) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }