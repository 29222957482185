var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-modal",
      attrs: {
        "data-show": _vm.show,
        "data-window-size": _vm.windowSize,
        "aria-modal": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          { ref: "scrollable", staticClass: "modal-content" },
          [
            _c("b-wrapper", { attrs: { type: _vm.wrapperType } }, [
              _c("div", { staticClass: "modal" }, [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [
                    _c(
                      "b-clickable",
                      {
                        ref: "close",
                        attrs: { "on-click": _vm.onClose, type: "button" },
                      },
                      [
                        _c("CloseIconMobile", {
                          staticClass: "mobile-icon",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "hidden-label" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.closeLabel) +
                              "\n              "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "header" }, [_vm._t("title")], 2),
                _vm._v(" "),
                _vm.hasContent
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [_vm._t("content")],
                      2
                    )
                  : _c("div", { staticClass: "filters" }, [
                      _vm.hasSeasonList
                        ? _c("div", { staticClass: "seasons" }, [
                            _vm.hasSeasonHeading
                              ? _c(
                                  "div",
                                  { staticClass: "season-heading" },
                                  [_vm._t("season-heading")],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "season-list" },
                              [_vm._t("season-list")],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPillarList
                        ? _c("div", { staticClass: "pillars" }, [
                            _vm.hasPillarHeading
                              ? _c(
                                  "div",
                                  { staticClass: "pillar-heading" },
                                  [_vm._t("pillar-heading")],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "pillars-list" },
                              [_vm._t("pillars-list")],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasTypesList
                        ? _c("div", { staticClass: "types" }, [
                            _vm.hasTypesHeading
                              ? _c(
                                  "div",
                                  { staticClass: "types-heading" },
                                  [_vm._t("types-heading")],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "types-list" },
                              [_vm._t("types-list")],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ]),
                _vm._v(" "),
                _vm.hasFirstCTA || _vm.hasSecondCTA
                  ? _c("div", { staticClass: "buttons" }, [
                      _vm.hasFirstCTA
                        ? _c(
                            "div",
                            { staticClass: "first-cta" },
                            [_vm._t("first-cta")],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasSecondCTA
                        ? _c(
                            "div",
                            { staticClass: "second-cta" },
                            [_vm._t("second-cta")],
                            2
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }