var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-featured-post" },
    [
      _c(
        "Section",
        { attrs: { spacing: "equidistant" } },
        [
          _c(
            "b-grid",
            [
              _c(
                "b-cell",
                { attrs: { xxs: "12", md: "6", xxl: "5" } },
                [
                  _c("p", { staticClass: "title" }, [
                    _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-clickable",
                    {
                      ref: "arrow",
                      attrs: { href: _vm.post.url, "on-click": _vm.onClick },
                    },
                    [
                      _c("div", { staticClass: "arrow" }, [
                        _c(
                          "div",
                          { staticClass: "mobile" },
                          [_c("ArrowMobileIcon")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "desktop" },
                          [_c("ArrowDesktopIcon")],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-cell",
                { attrs: { xxs: "12", md: "6", "offset-xxl": "1" } },
                [
                  _c(
                    "div",
                    { staticClass: "post-cell" },
                    [
                      _c("PostCover", {
                        attrs: {
                          title: _vm.post.title,
                          href: _vm.post.url,
                          image:
                            _vm.post &&
                            _vm.post.featured_image &&
                            _vm.post.featured_image.url,
                          caption:
                            _vm.post &&
                            _vm.post.featured_image &&
                            _vm.post.featured_image.caption,
                          date: _vm.getDate(_vm.post.published_at, {
                            has_date: true,
                          }),
                          alt:
                            _vm.post &&
                            _vm.post.featured_image &&
                            _vm.post.featured_image.alt,
                          "on-click": _vm.onClick,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }