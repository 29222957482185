import Dropdown from "../dropdown/index"

export default {
  components: {
    Dropdown
  },
  props: {
    spacedOut: {
      type: Boolean,
    },
    links: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
    },
  },
};
