import StickyElement from '../sticky-element';

export default {
  components: {
    StickyElement,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    date: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    pretitleColor: {
      type: String,
      default: 'dusk',
    },
    pretitleAlign: {
      type: String,
      default: 'left',
    },
    subtitle: {
      type: String,
    },
    spacing: {
      type: String,
    },
    ctaPosition: {
      type: String,
    },
    wrapperType: {
      type: String,
      default: 'default',
    },
    background: {
      type: String,
    },
    withBorderTop: {
      type: Boolean,
    },
    headlineLevel: {
      type: Number,
      default: 2,
    },
    headingAlign: {
      type: String,
    },
    stickyHeader: {
      type: Boolean,
    }
  },
  computed: {
    hasCta() {
      return !!this.$slots.cta;
    },
    hasDropdown() {
      return !!this.$slots.dropdown;
    },
    headline() {
      return 'h' + this.headlineLevel;
    },
    hasTags() {
      return !!this.$slots.tags;
    },
    shouldRenderHeading() {
      return !!(this.title || this.text || this.hasCta || this.hasDropdown);
    }
  },
};
