var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-icon-message",
      attrs: {
        "data-clickable": _vm.onClick,
        "data-icon-style": _vm.iconComponent && _vm.iconComponent.style,
      },
    },
    [
      _c("p", {
        staticClass: "text",
        domProps: { innerHTML: _vm._s(_vm.text) },
      }),
      _vm._v(" "),
      _c(
        "b-clickable",
        { ref: "button", attrs: { "on-click": _vm.onClick } },
        [
          _c(_vm.iconComponent && _vm.iconComponent.icon, {
            tag: "component",
            staticClass: "icon",
            attrs: { "aria-hidden": "true", focusable: "false" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }