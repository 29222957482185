export default (ctx, inject) => {
  const { app, $areCookiesAccepted } = ctx
  let _fbq
  const cookieConsent = $areCookiesAccepted()

  if (typeof window !== 'undefined') {
    const settings = app.store.getters['settings/settingsByLocale']('en')
    const { facebook_pixel_id } = settings

    if (facebook_pixel_id && cookieConsent) {
      ;((f, b, e, v, n, t, s) => {
        if (f.fbq) return
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = true
        t.defer = true
        t.src = v
        s = b.getElementsByTagName('body')[0]
        s.parentNode.appendChild(t, s)

        _fbq = fbq

        fbq('init', facebook_pixel_id)
        fbq('track', 'PageView')
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )
    }

    // console.log(_fbq)
    // ctx.$fb = _fbq
    // inject('fb', _fbq)
  }
}
