var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("StatePage", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({
          isHeaderVisible,
          isScrolled,
          toggleHeader,
          isHeaderHovered,
          mouseOverHeader,
        }) {
          return [
            _c(
              "Page",
              {
                attrs: {
                  "is-header-visible": isHeaderVisible,
                  "is-nav-visible": _vm.isNavVisible,
                  "is-scrolled": isScrolled,
                  "toggle-nav": _vm.toggleNav,
                  "toggle-header": toggleHeader,
                  "is-header-hovered": isHeaderHovered,
                  "mouse-over-header": mouseOverHeader,
                  "light-header": !_vm.isHome() || _vm.dropdown,
                  loading: _vm.isLoading,
                  "loader-label": _vm.label,
                  "skip-to-content-label": _vm.skipLabel,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "marquee",
                      fn: function () {
                        return [
                          _c("Marquee", { attrs: { items: _vm.marqueeItems } }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "brand",
                      fn: function () {
                        return [
                          _c(
                            "nuxt-link",
                            { attrs: { to: "/home" } },
                            [
                              _c(
                                "Logo",
                                {
                                  attrs: {
                                    size: "md",
                                    highlight: _vm.color,
                                    "highlight-when-narrow": _vm.isHome(),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Years of Culture\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "nav",
                      fn: function () {
                        return [
                          _c("Menu", {
                            attrs: {
                              "on-click": _vm.onMenuClick,
                              links: _vm.settings.top_navigation,
                              "see-all": _vm.settings.see_all,
                            },
                            on: { dropdown: _vm.isDropdownActive },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "lang",
                      fn: function () {
                        return _vm._l(_vm.locales, function (locale, index) {
                          return _c(
                            "nuxt-link",
                            {
                              key: index,
                              attrs: { to: _vm.switchLocalePath(locale.code) },
                            },
                            [
                              _c("LangSwitch", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(locale.name) +
                                    "\n          "
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                      proxy: true,
                    },
                    {
                      key: "hamburger",
                      fn: function () {
                        return [_c("Hamburger")]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c("Footer", {
                            attrs: {
                              copyright: _vm.settings.copyright,
                              "logo-label": _vm.settings.logo_label,
                              "social-buttons": _vm.settings.social_icons,
                              links: _vm.settings.footer_navigation,
                              "on-click": _vm.onMenuClick,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.shouldDisplayCookies
                      ? {
                          key: "cookies",
                          fn: function () {
                            return [
                              _c("Cookies", {
                                attrs: { title: _vm.settings.cookies.message },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cta",
                                      fn: function () {
                                        return [
                                          _c(
                                            "Button",
                                            {
                                              attrs: {
                                                "on-click":
                                                  _vm.onCookiesAccepted,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.settings.cookies
                                                      .button_label
                                                  ) +
                                                  "\n            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _c("div", [_c("Nuxt")], 1),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }