var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-rich-text" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.subtitle,
            "with-border-top": "",
          },
        },
        [
          _c(
            "b-grid",
            [
              _c(
                "b-cell",
                { attrs: { xxs: "12", lg: "10", xxl: "8" } },
                [_c("RichText", { attrs: { content: _vm.content } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }