var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-contact-cover",
      attrs: { "data-clickable": !!_vm.email },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.src
            ? _c(
                "b-clickable",
                {
                  ref: "image",
                  attrs: {
                    href: _vm.email,
                    disabled: !_vm.email,
                    "on-click": _vm.onClick,
                  },
                },
                [
                  _c("div", { staticClass: "contact-image" }, [
                    _c("img", { attrs: { src: _vm.src, alt: _vm.alt } }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("a", { attrs: { href: _vm.email, disabled: !_vm.email } }, [
            _c("p", { staticClass: "name" }, [
              _vm._v("\n        " + _vm._s(_vm.name) + "\n      "),
            ]),
          ]),
          _vm._v(" "),
          _vm.occupation
            ? _c("p", { staticClass: "occupation" }, [
                _vm._v("\n      " + _vm._s(_vm.occupation) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.email
            ? _c("a", { staticClass: "mail", attrs: { href: _vm.email } }, [
                _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }