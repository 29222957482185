import Section from '../../components/containers/section';
import CardCover from '../../components/covers/card-cover';
import EventCardCover from '../../components/covers/event-card-cover';
import Tag from '../../components/info/tag';
import Button from '../../components/cta/button';
import rightToLeft from '../../mixins/right-to-left';
import EmptyMessage from '../../components/info/empty-message';
import dateFormat from '../../mixins/date-format';
import LocationTag from '../../components/info/location-tag'

export default {
  mixins: [rightToLeft, dateFormat],
  components: {
    Section,
    CardCover,
    Tag,
    Button,
    EmptyMessage,
    EventCardCover,
    LocationTag
  },
  data() {
    return {
      items: this.getItems(),
    }
  },
  props: {
    title: {
      type: String,
    },
    pretitle: {
      type: String,
    },
    button: {
      type: Object
    },
    background: {
      type: String,
    },
    events: {
      type: Array,
    },
    empty: {
      type: Object,
    },
    onClick: {
      type: Function,
    },
    manual: {
      type: Object
    }
  },
  methods: {
    getItems() {
      let items = [];
      if(this.manual){
        items = [...items, ...this.manual.events];
      }

      if(this.events){
        items = [...items, ...this.events];
      }

      return items;
    },
  }
};
