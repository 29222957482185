import IndicatorIcon from './assets/indicator-icon-embedded.svg';
import SoundMuted from './assets/sound-muted-embedded.svg';
import SoundActivated from './assets/sound-activated-embedded.svg';

const IconMap = {
  drag: {
    icon: IndicatorIcon,
    style: undefined,
  },
  soundActivated: {
    icon: SoundActivated,
    style: 'stroke',
  },
  soundMuted: {
    icon: SoundMuted,
    style: 'stroke',
  },
};

export default {
  components: {
    IndicatorIcon,
  },
  props: {
    text: {
      type: String,
    },
    onClick: {
      type: Function,
    },
    icon: {
      type: String,
      default: 'drag',
    },
  },
  computed: {
    iconComponent() {
      return IconMap[this.icon];
    },
  },
  mounted() {
    this.$refs.button.onClick = this.onClick
  }
};
