export default {
  data() {
    return {
      currentDate: null,
      currentTime: null,
      interval: null
    };
  },
  created() {
    this.interval = setInterval(this.getNow, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getNow: function() {
      const date = new Date();

      this.currentDate = date.toLocaleDateString(
        undefined,
        {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }
      );

      this.currentTime = date.toLocaleTimeString(
        undefined,
        {
          hour: 'numeric',
          minute: 'numeric',
        }
      );
    }
  },
  render() {
    return this.$scopedSlots.default({
      currentDate: this.currentDate,
      currentTime: this.currentTime
    });
  },
};
