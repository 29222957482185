<template>
  <div>
    <Error
      message="Error!"
      subtitle="Sorry but we found an error. Try again later."
    />
  </div>
</template>
<script>
import { Error } from '@yoc/ui'
export default {
  name: 'error-404',
  components: {
    Error,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
