var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-organizations" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            "pretitle-color": "black",
            pretitle: _vm.pretitle,
            "with-border-top": "",
          },
        },
        [
          _c("div", { staticClass: "section-contents" }, [
            _c("div", { staticClass: "organization-list-container" }, [
              _c(
                "ol",
                {
                  staticClass: "organization-list",
                  attrs: {
                    start: _vm.currentStartCount,
                    "aria-live": "polite",
                  },
                },
                _vm._l(_vm.organizations, function (organization, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCurrentVisible(index),
                          expression: "isCurrentVisible(index)",
                        },
                      ],
                      key: index,
                      staticClass: "organization-item",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(organization.title) +
                          "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalPages() > 1,
                    expression: "totalPages() > 1",
                  },
                ],
                staticClass: "organization-pagination",
              },
              [
                _c("Pagination", {
                  attrs: {
                    "current-page": _vm.currentPage,
                    total: _vm.totalPages(),
                    "aria-prev": _vm.ariaPrev,
                    "aria-next": _vm.ariaNext,
                    "on-prev-click": _vm.onPrevClick,
                    "on-next-click": _vm.onNextClick,
                    condensed: "",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }