var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-contact-grid" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            "with-border-top": "",
            "wrapper-type": _vm.wrapperType,
          },
        },
        [
          _c(
            _vm.cellWrapper,
            _vm._b({ tag: "component" }, "component", _vm.wrapperProps, false),
            _vm._l(_vm.items, function (item) {
              return _c(
                "b-cell",
                _vm._b({ key: item.name }, "b-cell", _vm.cellSize, false),
                [
                  _c("ContactCover", {
                    attrs: {
                      name: item.name,
                      occupation: item.occupation,
                      email: item.email,
                      label: item.label,
                      src: item.image && item.image.url,
                      alt: item.image && item.image.alternativeText,
                      "on-click": _vm.onClick,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }