export default {
  props: {
    label: {
      type: String,
    },
    loading: {
      type: Boolean,
    }
  },
  data: () => ({
    prevLabel: false,
    nextLabel: false,
    changingLabel: false,
  }),
  mounted() {
    this.prevLabel = this.label;
    this.nextLabel = null;
  },
  watch: {
    label() {
      this.nextLabel = this.label;
      this.changingLabel = true;

      setTimeout(() => {
        this.prevLabel = this.label;
      }, 4000);

      setTimeout(() => {
        this.changingLabel = false;
      }, 1200);
    },
  },
}
