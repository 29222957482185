var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-event-card-cover",
      attrs: {
        "data-clickable": !!_vm.href,
        "data-title-lines": _vm.titleLines,
        "data-image-ratio": _vm.imageRatio,
        "data-variant": _vm.variant,
      },
    },
    [
      _c(
        "b-clickable",
        {
          ref: "image",
          attrs: {
            href: _vm.href,
            disabled: !_vm.href,
            "on-click": _vm.onClick,
          },
        },
        [
          _c(
            "div",
            { staticClass: "card-image" },
            [
              _c("ImageComponent", {
                attrs: {
                  images: _vm.images,
                  src: _vm.image,
                  "lazy-load": true,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "overlay" }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "card-content" }, [
              _c("div", [
                _vm.hasCta
                  ? _c("span", { staticClass: "cta" }, [_vm._t("cta")], 2)
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasTags
                  ? _c("span", { staticClass: "tags" }, [_vm._t("tags")], 2)
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "b-clickable",
                    {
                      ref: "clickable",
                      attrs: {
                        href: _vm.href,
                        disabled: !_vm.href,
                        "on-click": _vm.onClick,
                      },
                    },
                    [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.title) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "info" }, [
                    _vm.date
                      ? _c("p", { staticClass: "date" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.date) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasLocation
                      ? _c(
                          "span",
                          { staticClass: "location" },
                          [_vm._t("location")],
                          2
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.description
                    ? _c("p", { staticClass: "description" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.description) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }