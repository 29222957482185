var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "m-images-slider" },
    [
      _c(
        "Section",
        {
          attrs: {
            title: _vm.title,
            pretitle: _vm.pretitle,
            text: _vm.text,
            "wrapper-type": "full",
          },
        },
        [
          _c(
            "b-slider",
            {
              ref: "slider",
              attrs: {
                arrows: "",
                "right-to-left": _vm.rightToLeft,
                "cell-align": _vm.cellAlign,
              },
            },
            _vm._l(_vm.items, function (item, id) {
              return _c(
                "b-cell",
                {
                  key: `${item.title}-${id}`,
                  attrs: { xxs: "11", md: "5", xl: "6" },
                },
                [
                  _c("CardCover", {
                    attrs: {
                      href: item.href,
                      images: item.featured_image,
                      title: item.title,
                      "on-click": item.href ? _vm.onClick : null,
                      alt: item.featured_image.alternativeText,
                      description: item.description,
                      caption: item.featured_image.caption,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }